import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as serviceWorker from 'src/serviceWorker';
import { ErrorFallback } from './components/errorBoundary';
import { DEFAULT_CACHE_STALE_TIME } from './utils/general';
import { LicenseInfo } from '@mui/x-license';
import App from './App';
import { GeneralProvider } from './contexts/GeneralContexts';
import { UIProvider } from './contexts/UIContexts';
import { AppProvider } from './contexts/AppContexts/AppContexts';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);

ReactDOM.render(
  <GeneralProvider
    fallbackComponent={ErrorFallback}
    staleTime={DEFAULT_CACHE_STALE_TIME}
  >
    <UIProvider>
      <AppProvider>
        <App />
      </AppProvider>
    </UIProvider>
  </GeneralProvider>,
  document.getElementById('root')
);

document.getElementById('loading')?.remove();
serviceWorker.unregister();
