import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import Card from 'src/components/display/Card/Card';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { fontWeights } from 'src/components/styles/fonts';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import {
  DocumentEditorParams,
  DocumentSignaturesAttributes,
  PatientDocument
} from 'src/types/documents';
import { AddDocumentForm } from '../actionMenu/AddDocumentForm';
import useDocumentsApi from '../../../hooks/useDocumentsApi';
import dayjs from 'dayjs';
import { getDateFormat, longDateTimeFormat } from 'src/utils/dateAndTIme';
import { getDocumentSignatureStatus } from 'src/modules/documents/utils/editorUtils/getDocumentSignatureStatus';
import { Colors } from '../../../components/styles/colors';
import { styled } from '@mui/material';
import Table from '../../../components/display/Table/Table';
import Chip from 'src/components/data-entry/Chips/Chip';
import DeleteDocumentDialog from './DeleteDocumentDialog';
import { DocumentMenuProps } from './types';
import Menu from 'src/components/display/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDialog, usePopover } from 'src/contexts/UIContexts';

const StyledTable = styled(Table)`
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-row {
    color: ${Colors.emperor};
    padding-inline: 0;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: ${fontWeights.extraBold};
  }
  .MuiDataGrid-cell:not(:first-of-type) {
    font-weight: ${fontWeights.extraBold};
  }
  .MuiPaginationItem-root {
    margin-right: 20px;
  }
`;

export const PatientConsentFormsTable: FC<{
  patientId: string;
  partnerId?: string;
  isPartner?: boolean;
  onDocumentsModalOpen?: () => void;
  onDocumentEditorInfoChange?: (info: DocumentEditorParams) => void;
}> = ({
  patientId,
  partnerId,
  isPartner,
  onDocumentEditorInfoChange,
  onDocumentsModalOpen
}) => {
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { openPopover } = usePopover();
  const { getPatientConsentDocuments } = useDocumentsApi();

  const {
    data: patientDocuments,
    refetch: refetchPatientsDocuments,
    isLoading: isLoadingPatientDocuments,
    isFetching: isFetchingPatientDocuments
  } = getPatientConsentDocuments(patientId);

  const isLoading = isLoadingPatientDocuments || isFetchingPatientDocuments;

  const refetchPartnerDocuments = getPatientConsentDocuments(partnerId, {
    enabled: !!partnerId
  }).refetch;

  const handleMenuClick = ({ event, id, templateId }: DocumentMenuProps) => {
    onDocumentEditorInfoChange({
      patientId,
      templateId: templateId || null,
      documentId: id
    });

    openPopover({
      anchorEl: event.currentTarget,
      hideToolbar: true,
      children: (
        <Menu
          items={[
            {
              label: t('VIEW_DOCUMENT_TITLE'),
              action: () => {
                onDocumentEditorInfoChange({
                  patientId,
                  templateId: templateId || null,
                  documentId: id,
                  isForceSave: false
                });
                onDocumentsModalOpen();
              }
            },
            {
              label: t('DELETE'),
              action: () =>
                openDialog({
                  header: t('DELETE_DOCUMENT_TITLE'),
                  children: (
                    <DeleteDocumentDialog
                      documentId={id}
                      patientId={patientId}
                    />
                  ),
                  fullWidth: true
                })
            }
          ]}
        />
      ),
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'top'
      }
    });
  };

  const columns: GridColDef<PatientDocument>[] = [
    {
      field: 'menu',
      headerName: t('ACTIONS').toLocaleUpperCase(),
      renderHeader: () => null,
      flex: 0.5,
      sortable: false,
      align: 'center',
      filterable: false,
      valueGetter: (_, row) => row.id,
      renderCell: ({ row }) => {
        return (
          <MiniIconButton
            icon={<MoreVertIcon />}
            onClick={(event) => {
              return handleMenuClick({
                event,
                templateId: row.templateId,
                id: row.id,
                url: row.url,
                documentName: row.name
              });
            }}
          />
        );
      }
    },
    {
      field: 'createdAt',
      flex: 2,
      headerName: t('ADDED_DATE').toLocaleUpperCase(),
      type: 'date' as const,
      valueFormatter: (value) => dayjs(value).format(getDateFormat()),
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix()
    },
    {
      field: 'name',
      flex: 5,
      headerName: t('NAME').toLocaleUpperCase(),
      renderCell: ({ row: { name, createdAt } }) => {
        return (
          <Typography>{`${name} ${
            createdAt ? `- ${dayjs(createdAt).format(longDateTimeFormat)}` : ''
          }`}</Typography>
        );
      }
    },
    {
      field: 'documentSignatures',
      flex: 5,
      headerName: t('STATUS').toLocaleUpperCase(),
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: ({ row: { documentSignatures } }) => {
        let signature: DocumentSignaturesAttributes;
        if (documentSignatures?.length > 0) {
          signature = documentSignatures.find(
            (signature) => signature.signerId == patientId
          );
        }
        const { color, status } =
          getDocumentSignatureStatus({
            isDocumentCompleted: signature?.isSigned,
            isInitialVendorEmailSent: false
          }) || {};

        return (
          <Chip
            label={t(status).toUpperCase()}
            backgroundColor={color}
            fontWeight={fontWeights.extraBold}
          />
        );
      }
    }
  ];

  return (
    <Card shadow marginBottom={spacings.x2large}>
      <StyledTable
        columns={columns}
        columnHeaderHeight={50}
        rows={patientDocuments || []}
        loading={isLoading}
        toolbar={
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Typography
              paddingY={spacings.large}
              fontWeight={fontWeights.extraBold}
            >
              {t(
                isPartner ? 'PARTNER_CONSENT_FORMS' : 'CONSENT_FORMS'
              ).toUpperCase()}
            </Typography>
            <MiniIconButton
              icon={<AddIcon />}
              onClick={() => {
                openDialog({
                  header: t('CHOOSE_A_DOCUMENT'),
                  children: (
                    <AddDocumentForm
                      patientId={patientId}
                      onSuccess={(documentId: string) => {
                        refetchPartnerDocuments();
                        refetchPatientsDocuments().then(() => {
                          onDocumentEditorInfoChange({
                            patientId,
                            templateId: null,
                            documentId,
                            isForceSave: true,
                            isDocumentClosable: false
                          });
                          onDocumentsModalOpen();
                        });
                      }}
                    />
                  ),
                  maxWidth: 'md'
                });
              }}
            />
          </Flex>
        }
        autoHeight
        freeHeight
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'createdAt',
                sort: 'desc'
              }
            ]
          }
        }}
      />
    </Card>
  );
};
