import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  Divider,
  Typography,
  css,
  styled
} from '@mui/material';
import { useSnackbar } from 'notistack';

import i18n from 'src/i18n/i18n';
import { spacings } from 'src/components/styles/constants';
import FormBuilder from 'src/components/data-entry/FormBuilder/FormBuilder';
import {
  SchemaFieldType,
  SchemaProps
} from 'src/components/data-entry/FormBuilder/types';
import Flex from 'src/components/layout/Flex';
import axios from 'axios';
import { useAuth } from 'src/contexts/AppContexts/AppContexts';

const ImgWrapper = styled('img')`
  ${({ theme }) => css`
    margin-right: ${theme.spacing(1)};
  `}
`;

const handleNavigation = (url: string) => {
  window.open(url, '_blank');
};

const PrivacyPolicyLink = () => (
  <a
    href="#"
    onClick={() => {
      handleNavigation('https://embieclinic.com/privacy/');
    }}
  >
    {i18n.t('PRIVACY_POLICY')}
  </a>
);

const TermsOfServiceLink = () => (
  <a
    href="#"
    onClick={() => {
      handleNavigation('https://embieclinic.com/staff-terms/');
    }}
  >
    {i18n.t('CLINIC_PORTAL_TERMS_OF_SERVICES')}
  </a>
);

function LoginFirebaseAuth() {
  const { signInWithEmailAndPassword } = useAuth();
  const { t } = useTranslation();
  const methods = useForm();
  const { isSubmitting } = methods.formState;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const getFormSchema = (isDisabled: boolean): SchemaProps[] => [
    {
      key: 'email',
      rules: { required: true },
      type: SchemaFieldType.input,
      errorMessages: {
        required: i18n.t('EMAIL_FIELD_REQUIRED')
      },
      props: {
        name: 'email',
        disabled: isDisabled
      },
      label: i18n.t('EMAIL_FIELD_LABEL'),
      defaultValue: ''
    },
    {
      key: 'password',
      rules: { required: true },
      type: SchemaFieldType.input,
      errorMessages: {
        required: i18n.t('PASSWORD_FIELD_REQUIRED')
      },
      props: {
        name: 'password',
        disabled: isDisabled,
        type: 'password'
      },
      label: i18n.t('PASSWORD_FIELD_LABEL'),
      defaultValue: ''
    }
  ];

  const handleGoogleLogin = async () => {
    try {
      // Grab the google auth URL to redirect to
      // navigate to that google auth URL
      const { data } = await axios.post<{ authLink: string }>(
        `${process.env.REACT_APP_SERVER_BASE_URL}/auth/googleAuthLink`
      );
      window.location.replace(data.authLink);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoggingIn(true);
      await signInWithEmailAndPassword(values.email, values.password);
    } catch (error) {
      setIsLoggingIn(false);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const isLoading = isSubmitting || isLoggingIn;

  return (
    <>
      <FormBuilder
        methods={methods}
        schema={getFormSchema(isLoading)}
        onSave={methods.handleSubmit(() => handleSubmit(methods.getValues()))}
      >
        <Button
          color="primary"
          startIcon={isLoading ? <CircularProgress size="1rem" /> : null}
          disabled={isLoading}
          size="large"
          fullWidth
          type="submit"
          variant="contained"
          onClick={methods.handleSubmit(() =>
            handleSubmit(methods.getValues())
          )}
          id="login-firebase-auth-button"
        >
          {i18n.t('SIGN_IN_TITLE')}
        </Button>
      </FormBuilder>
      <Divider
        sx={{
          my: 4
        }}
      >
        <Typography textAlign="center" color="text.secondary">
          {i18n.t('or')}
        </Typography>
      </Divider>
      <Flex flexDirection="column" gap={spacings.small}>
        <Button
          fullWidth
          onClick={handleGoogleLogin}
          size="large"
          variant="outlined"
        >
          <ImgWrapper
            alt={i18n.t('SIGN_IN_WITH_GOOGLE')}
            src="/static/images/logo/google.svg"
          />
          {i18n.t('SIGN_IN_WITH_GOOGLE')}
        </Button>
      </Flex>
      <Typography
        marginTop={spacings.large}
        textAlign="center"
        color="text.secondary"
      >
        {t('TERMS_OF_USE_AGREEMENT_TEXT')} <PrivacyPolicyLink /> {t('AND')}{' '}
        <TermsOfServiceLink />.{' '}
      </Typography>
    </>
  );
}

export default LoginFirebaseAuth;
