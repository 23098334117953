import { FC, useEffect } from 'react';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  ExamReason,
  SkinExamValues,
  ThyroidExamValues,
  BreastExamValues,
  SpeculumExamValues,
  BimanualPelvicExamValues,
  OtherExaminationTypes,
  ExamReport
} from 'src/types/exam';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import { spacings, spacingsPercentage } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import Flex from 'src/components/layout/Flex/Flex';
import Chips, { ChipsVariants } from 'src/components/data-entry/Chips/Chips';
import Button from 'src/components/display/Button/Button';
import { getFullName } from 'src/utils/general';
import { DoctorChips } from '../common/DoctorChips';
import dayjs from 'dayjs';
import useGeneralApi from '../../../hooks/useGeneralApi';
import Select from 'src/components/data-entry/Select';
import TextArea from 'src/components/data-entry/TextArea';
import { NormalOrAbnormal } from 'src/types/global';
import Typography from 'src/components/display/Typography';
import useExams from 'src/hooks/useExams';
import FormActionsContainer from 'src/components/display/FormActionsContainer/FormActionsContainer';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import { Icd10Chips } from '../common/Icd10Chips';

interface PhysicalExamFemaleForm {
  id?: string;
  patientName: string;
  patientId: string;
  performedById: string;
  requestingPhysicianId: string;
  signedOffById: string;
  dateOfExamination: Date;
  type: OtherExaminationTypes;
  reason: ExamReason;
  generalAppearance: NormalOrAbnormal;
  earsNoseThroat: NormalOrAbnormal;
  eyes: NormalOrAbnormal;
  nodes: NormalOrAbnormal;
  heart: NormalOrAbnormal;
  lungs: NormalOrAbnormal;
  bowelSounds: NormalOrAbnormal;
  abdomen: NormalOrAbnormal;
  legs: NormalOrAbnormal;
  abnormalComments: string;
  notes: string;
  skinExam: SkinExamValues[];
  thyroidExam: ThyroidExamValues[];
  breastExam: BreastExamValues[];
  speculumExam: SpeculumExamValues[];
  bimanualPelvicExam: BimanualPelvicExamValues[];
  icd10Codes: string[];
}

export const AddEditPhysicalExamFemaleForm: FC<{
  patientId: string;
  examinationId?: string;
}> = ({ patientId, examinationId }) => {
  const isEdit = !!examinationId;
  const { t } = useTranslation();
  const { getPatientById } = usePatientsApi();
  const { createExamReport, updateExamReport } = useExams();
  const { getExaminationById } = useGeneralApi();

  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const { data: examination, isLoading: isLoadingExam } =
    getExaminationById(examinationId);

  const { mutate: createReport, isLoading: isCreatingReport } =
    createExamReport();
  const { mutate: updateReport, isLoading: isUpdatingReport } =
    updateExamReport();

  const defaultValues: PhysicalExamFemaleForm = {
    id: null,
    patientName: '',
    patientId,
    performedById: '',
    requestingPhysicianId: '',
    signedOffById: '',
    dateOfExamination: dayjs().toDate(),
    type: OtherExaminationTypes.PHYSICAL_EXAM_FEMALE,
    reason: null,
    generalAppearance: null,
    earsNoseThroat: null,
    eyes: null,
    nodes: null,
    heart: null,
    lungs: null,
    bowelSounds: null,
    abdomen: null,
    legs: null,
    abnormalComments: '',
    notes: '',
    skinExam: [],
    thyroidExam: [],
    breastExam: [],
    speculumExam: [],
    bimanualPelvicExam: [],
    icd10Codes: []
  };

  const { control, formState, handleSubmit, setValue, reset } =
    useForm<PhysicalExamFemaleForm>({
      mode: 'onChange',
      defaultValues
    });

  useEffect(() => {
    if (examination) {
      reset({
        id: examination.id,
        dateOfExamination:
          dayjs(examination.examDate).toDate() || dayjs().toDate(),
        patientId,
        performedById: examination.performedBy,
        requestingPhysicianId: examination.requestedPhysician,
        signedOffById: examination.signedOffBy,
        reason: examination.reason,
        type: examination.type as OtherExaminationTypes,
        notes: examination.notes || '',
        abdomen: examination.physicalExamFemale?.abdomen || null,
        abnormalComments:
          examination.physicalExamFemale?.abnormalComments || '',
        bimanualPelvicExam:
          examination.physicalExamFemale?.bimanualPelvicExam || null,
        bowelSounds: examination.physicalExamFemale?.bowelSounds || null,
        breastExam: examination.physicalExamFemale?.breastExam || null,
        earsNoseThroat: examination.physicalExamFemale?.earsNoseThroat || null,
        eyes: examination.physicalExamFemale?.eyes || null,
        generalAppearance:
          examination.physicalExamFemale?.generalAppearance || null,
        heart: examination.physicalExamFemale?.heart || null,
        legs: examination.physicalExamFemale?.legs || null,
        lungs: examination.physicalExamFemale?.lungs || null,
        nodes: examination.physicalExamFemale?.nodes || null,
        skinExam: examination.physicalExamFemale?.skinExam || [],
        speculumExam: examination.physicalExamFemale?.speculumExam || [],
        thyroidExam: examination.physicalExamFemale?.thyroidExam || [],
        icd10Codes: examination.icd10Codes
      });
    }

    if (patient) {
      setValue('patientName', getFullName(patient?.personalInfo));
    }
  }, [patient, examination]);

  const { errors } = formState;

  const onSubmit = async ({
    id,
    requestingPhysicianId,
    type,
    performedById,
    signedOffById,
    reason,
    patientId,
    patientName,
    dateOfExamination,
    notes,
    icd10Codes,
    ...report
  }: PhysicalExamFemaleForm) => {
    const examToSend: ExamReport = {
      id,
      requestingPhysicianId,
      type,
      performedById,
      signedOffById,
      reason,
      patientId,
      patientName,
      dateOfExamination,
      notes,
      icd10Codes,
      physicalExamFemale: report
    };

    if (isEdit) {
      await updateReport(examToSend);
    } else {
      await createReport(examToSend);
    }
  };

  if (isLoadingPatient || isLoadingExam) return <Loader />;

  const formActions = [
    <Button
      key="action-save"
      width={spacingsPercentage.large}
      disabled={isCreatingReport || isUpdatingReport}
      onClick={handleSubmit(onSubmit)}
    >
      {t('SAVE')}
    </Button>
  ];

  return (
    <Box marginTop={spacings.large}>
      <form noValidate>
        <Flex>
          <Flex flex={1} gap={spacings.large} marginBottom={spacings.medium}>
            <Box flex={1}>
              <Controller
                name="requestingPhysicianId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-physical-exam-female-requesting-physician"
                    showSelectedValue
                    label={t('REQUESTING_PROVIDER').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="performedById"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-physical-exam-female-performed-by"
                    showSelectedValue
                    label={t('PERFORMED_BY').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="signedOffById"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-physical-exam-female-signed-off-by"
                    showSelectedValue
                    label={t('SIGNED_OFF_BY').toUpperCase()}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
          </Flex>
          <Box flex={1} />
        </Flex>
        <Flex marginBottom={spacings.x2large} gap={spacings.large}>
          <Flex gap={spacings.x2large} flex={1} flexDirection="column">
            <Controller
              name="patientName"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  disabled
                  inputRef={ref}
                  label={t('PATIENT_NAME')}
                  placeholder={t('PATIENT_NAME')}
                  error={!!errors.patientName}
                  helperText={errors?.patientName?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              name="dateOfExamination"
              control={control}
              rules={{
                required: t('DATE_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  label={t('DATE_OF_EXAMINATION')}
                  inputRef={ref}
                  error={!!errors?.dateOfExamination}
                  helperText={errors.dateOfExamination?.message}
                  fullWidth
                />
              )}
            />
          </Flex>
          <Flex gap={spacings.x2large} flex={1} flexDirection="column">
            <Box flex={1} height="100%">
              <Icd10Chips
                control={control}
                name="icd10Codes"
                error={!!errors.icd10Codes}
                helperText={errors?.icd10Codes?.message}
                label={t('ICD_10_CODES_OR_OBSERVATIONS').toUpperCase()}
                flattenValue
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="skinExam"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    variant={ChipsVariants.EXPANDED}
                    value={value}
                    options={Object.values(SkinExamValues).map((value) => ({
                      label: t(value),
                      value,
                      labelText: t(value)
                    }))}
                    title={t('SKIN_EXAM').toUpperCase()}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValue) => {
                      const updatedValues = value.filter(
                        (finding) => finding !== chipValue
                      );
                      onChange(updatedValues);
                    }}
                    chipsInputSx={{ height: '100%' }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
        </Flex>
        <Flex gap={spacings.large} flexDirection="column">
          <Box flex={1}>
            <Typography variant="h2">{t('GENERAL_PHYSICAL_EXAM')}</Typography>
          </Box>
          <Flex gap={spacings.large} marginBottom={spacings.large}>
            <Flex flex={1} gap={spacings.large} flexDirection="column">
              <Flex flex={1} gap={spacings.large} marginBottom={spacings.large}>
                <Box flex={1}>
                  <Controller
                    name="generalAppearance"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('GENERAL_APPEARANCE')}
                        inputRef={ref}
                        error={!!errors?.generalAppearance}
                        helperText={errors?.generalAppearance?.message}
                        defaultOption={t('GENERAL_APPEARANCE')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name="earsNoseThroat"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('EAR_NOSE_THROAT')}
                        inputRef={ref}
                        error={!!errors?.earsNoseThroat}
                        helperText={errors?.earsNoseThroat?.message}
                        defaultOption={t('EAR_NOSE_THROAT')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name="eyes"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('EYES')}
                        inputRef={ref}
                        error={!!errors?.eyes}
                        helperText={errors?.eyes?.message}
                        defaultOption={t('EYES')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
              </Flex>
              <Flex gap={spacings.large}>
                <Box flex={1}>
                  <Controller
                    name="nodes"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('NODES')}
                        inputRef={ref}
                        error={!!errors?.nodes}
                        helperText={errors?.nodes?.message}
                        defaultOption={t('NODES')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name="heart"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('HEART')}
                        inputRef={ref}
                        error={!!errors?.heart}
                        helperText={errors?.heart?.message}
                        defaultOption={t('HEART')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name="lungs"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('LUNGS')}
                        inputRef={ref}
                        error={!!errors?.lungs}
                        helperText={errors?.lungs?.message}
                        defaultOption={t('LUNGS')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
              </Flex>
            </Flex>
            <Box flex={1}>
              <Controller
                name="thyroidExam"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    variant={ChipsVariants.EXPANDED}
                    value={value}
                    options={Object.values(ThyroidExamValues).map((value) => ({
                      label: t(value),
                      value,
                      labelText: t(value)
                    }))}
                    title={t('THYROID_EXAM').toUpperCase()}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValue) => {
                      const updatedValues = value.filter(
                        (finding) => finding !== chipValue
                      );
                      onChange(updatedValues);
                    }}
                    chipsInputSx={{ height: '100%' }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex gap={spacings.large} marginBottom={spacings.large}>
            <Flex flex={1} gap={spacings.large} flexDirection="column">
              <Flex gap={spacings.large} flex={1}>
                <Box flex={1}>
                  <Controller
                    name="bowelSounds"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('BOWEL_SOUNDS')}
                        inputRef={ref}
                        error={!!errors?.bowelSounds}
                        helperText={errors?.bowelSounds?.message}
                        defaultOption={t('BOWEL_SOUNDS')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name="abdomen"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('ABDOMEN')}
                        inputRef={ref}
                        error={!!errors?.abdomen}
                        helperText={errors?.abdomen?.message}
                        defaultOption={t('ABDOMEN')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
                <Box flex={1}>
                  <Controller
                    name="legs"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        label={t('LEGS')}
                        inputRef={ref}
                        error={!!errors?.legs}
                        helperText={errors?.legs?.message}
                        defaultOption={t('LEGS')}
                        options={Object.entries(NormalOrAbnormal).map(
                          ([key, value]) => ({
                            label: t(key),
                            value
                          })
                        )}
                      />
                    )}
                  />
                </Box>
              </Flex>
              <br />
              <br />
            </Flex>
            <Box flex={1}>
              <Controller
                name="breastExam"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    variant={ChipsVariants.EXPANDED}
                    value={value}
                    options={Object.values(BreastExamValues).map((value) => ({
                      label: t(value),
                      value,
                      labelText: t(value)
                    }))}
                    title={t('BREAST_EXAM').toUpperCase()}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValue) => {
                      const updatedValues = value.filter(
                        (finding) => finding !== chipValue
                      );
                      onChange(updatedValues);
                    }}
                    chipsInputSx={{ height: '100%' }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex gap={spacings.large} marginBottom={spacings.large}>
            <Box flex={1}>
              <Controller
                name="abnormalComments"
                control={control}
                render={({ field: { ...field } }) => (
                  <TextArea
                    {...field}
                    label={t(
                      'IF_ANY_ABNORMAL_FINDINGS_PLEASE_WRITE_MORE_ABOUT_IT'
                    ).toUpperCase()}
                    placeholder={t('ENTER_COMMENT')}
                    error={!!errors?.abnormalComments}
                    helperText={errors?.abnormalComments?.message}
                    required
                    fullWidth
                    minRows={6}
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="speculumExam"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    variant={ChipsVariants.EXPANDED}
                    value={value}
                    options={Object.values(SpeculumExamValues).map((value) => ({
                      label: t(value),
                      value,
                      labelText: t(value)
                    }))}
                    title={t('SPECULUM_EXAMINATION').toUpperCase()}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValue) => {
                      const updatedValues = value.filter(
                        (finding) => finding !== chipValue
                      );
                      onChange(updatedValues);
                    }}
                    chipsInputSx={{ height: '100%' }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex flex={1} gap={spacings.large}>
            <Box flex={1}>
              <Controller
                name="notes"
                control={control}
                render={({ field: { ...field } }) => (
                  <TextArea
                    {...field}
                    label={t('PROCEDURE_NOTES').toUpperCase()}
                    placeholder={t('ENTER_COMMENT')}
                    error={!!errors?.notes}
                    helperText={errors?.notes?.message}
                    required
                    fullWidth
                    minRows={6}
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                name="bimanualPelvicExam"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    variant={ChipsVariants.EXPANDED}
                    value={value}
                    options={Object.values(BimanualPelvicExamValues).map(
                      (value) => ({
                        label: t(value),
                        value,
                        labelText: t(value)
                      })
                    )}
                    title={t('BIMANUAL_PELVIC_EXAMINATION').toUpperCase()}
                    onAddChip={(newChip) => onChange([...value, newChip])}
                    onRemoveChip={(chipValue) => {
                      const updatedValues = value.filter(
                        (finding) => finding !== chipValue
                      );
                      onChange(updatedValues);
                    }}
                    chipsInputSx={{ height: '100%' }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
          </Flex>
        </Flex>
        <FormActionsContainer actions={formActions} />
      </form>
    </Box>
  );
};
