import { PropsWithChildren, useMemo } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  Popper,
  styled
} from '@mui/material';
import { Colors } from 'src/components/styles/colors';
import {
  iconSizes,
  radii,
  shadows,
  spacings
} from 'src/components/styles/constants';
import { makeShouldForwardProps } from 'src/components/utils';
import Loader from 'src/components/display/Loader';
import Card from 'src/components/display/Card';
import Center from 'src/components/layout/Center';

const StyledPopper = styled(Popper)<{ width?: string | number }>`
  width: ${({ width }) => width};
  .MuiPaper-root {
    box-shadow: ${shadows.default};
  }
`;

const shouldForwardProp = makeShouldForwardProps(['shouldSortOptions']);
const StyledAutocomplete = styled(MuiAutocomplete, { shouldForwardProp })`
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: ${spacings.medium};
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    padding: 0;
  }
  .MuiAutocomplete-input {
    padding: ${spacings.medium};
  }
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator {
    background-color: transparent;
    color: ${Colors.emperor};
  }
  .MuiTextField-root {
    margin: 0;

    .MuiInput-root {
      color: ${Colors.emperor};
      padding-inline: ${spacings.medium};
      &:before {
        border-color: ${Colors.alto};
      }
      &:after {
        border-color: ${Colors.halfBaked};
      }
    }

    .MuiInputAdornment-root {
      height: unset;
    }
  }
`;

type OptionsSortProps<T> = {
  shouldSortOptions: true;
  getOptionLabel: (option: T) => string;
};

type OptionsBaseProps<T> = {
  shouldSortOptions?: false;
  getOptionLabel?: (option: T) => string;
};

interface AutocompleteBaseProps<T>
  extends MuiAutocompleteProps<T, boolean, boolean, boolean> {
  freeListWidth?: boolean;
  isLoading?: boolean;
}

type AutocompleteProps<T> = AutocompleteBaseProps<T> &
  (OptionsSortProps<T> | OptionsBaseProps<T>);

function Autocomplete<T>({
  freeListWidth,
  isLoading,
  ...props
}: PropsWithChildren<AutocompleteProps<T>>) {
  const sortedOptions = useMemo(
    () =>
      props.shouldSortOptions
        ? props.options
            .map((option) => {
              return props.getOptionLabel(option);
            })
            .sort((a, b) => a?.localeCompare(b))
        : props.options,
    [props]
  );

  return (
    <StyledAutocomplete
      options={sortedOptions}
      PopperComponent={(defaultPopperProps) => {
        return (
          <StyledPopper
            {...defaultPopperProps}
            width={
              freeListWidth ? 'fit-content' : defaultPopperProps.style?.width
            }
          >
            {isLoading ? (
              <Card padding={spacings.large} shadow borderRadius={radii.medium}>
                <Center>
                  <Loader size={iconSizes.small} />
                </Center>
              </Card>
            ) : (
              defaultPopperProps.children
            )}
          </StyledPopper>
        );
      }}
      {...props}
    />
  );
}

export default Autocomplete;
