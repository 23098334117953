import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { iconSizes, spacings } from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import { Prescription, UpdatePrescriptionDosage } from 'src/types/prescription';
import usePrescription from 'src/hooks/usePrescription';
import Loader from 'src/components/display/Loader';
import Typography from '../../../components/display/Typography';
import { fontWeights } from '../../../components/styles/fonts';
import { Colors } from '../../../components/styles/colors';
import { useDialog, usePopover } from 'src/contexts/UIContexts';

interface ConfirmDeletePrescriptionProps {
  onConfirm: () => void;
}

export const ConfirmDeletePrescription: FC<ConfirmDeletePrescriptionProps> = ({
  onConfirm
}) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  return (
    <Box>
      <Flex
        paddingTop={spacings.large}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2" textAlign="center">
          {t('CONFIRM_STOP_MEDICATION_MESSAGE')}
        </Typography>
        <Box marginY={spacings.medium}>
          <Typography fontWeight={fontWeights.black} variant="h3">
            {t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED')}
          </Typography>
        </Box>
      </Flex>
      <Flex gap={spacings.large} justifyContent="center">
        <Button onClick={onConfirm}>{t('YES_STOP_THE_MEDICATION')}</Button>
        <Button
          onClick={closeDialog}
          bgColor="cupid"
          sx={{ color: Colors.mineShaft }}
        >
          {t('NO_I_DONT_WANT_TO_PROCEED')}
        </Button>
      </Flex>
    </Box>
  );
};

export const checkIsMedicationStoppingCompletely = (
  details: UpdatePrescriptionDosage,
  prescription: Prescription
) => {
  return (
    +details.dosage === 0 &&
    details.startDate <= prescription.startDate &&
    (!details.endDate || details.endDate >= prescription.endDate)
  );
};

export const TreatmentEditMedicationForm: FC<{
  id: string;
  date?: Date;
  dosage?: number;
}> = ({ id, date, dosage }) => {
  const { t } = useTranslation();
  const { closeAllPopovers } = usePopover();
  const { openDialog, closeDialog } = useDialog();
  const { getPrescriptionById, updatePrescriptionDosage } = usePrescription();
  const { data: prescription, isLoading: isLoadingPrescription } =
    getPrescriptionById(id);

  const {
    mutate: handleUpdatePrescriptionDosage,
    isLoading: isUpdatingPrescription
  } = updatePrescriptionDosage();

  const { control, formState, handleSubmit, reset } =
    useForm<UpdatePrescriptionDosage>({
      mode: 'onChange',
      defaultValues: {
        startDate: date,
        endDate: null,
        dosage: dosage,
        patientId: null,
        cycleId: null,
        prescriptionId: null
      }
    });
  const { errors } = formState;

  useEffect(() => {
    if (prescription) {
      reset({
        startDate: date || prescription.startDate,
        endDate: prescription.endDate,
        dosage: dosage || prescription.dosage,
        patientId: prescription.patientId,
        cycleId: prescription.cycleId,
        prescriptionId: prescription.id
      });
    }
  }, [prescription]);

  const onSubmit = async (details: UpdatePrescriptionDosage) => {
    const isPrescriptionStoppingCompletely =
      checkIsMedicationStoppingCompletely(details, prescription);

    if (isPrescriptionStoppingCompletely) {
      openDialog({
        header: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
        children: (
          <ConfirmDeletePrescription
            onConfirm={async () => {
              await handleUpdatePrescriptionDosage(details, {
                onSuccess: () => {
                  closeAllPopovers();
                  closeDialog();
                }
              });
            }}
          />
        )
      });
      return;
    }

    await handleUpdatePrescriptionDosage(details, {
      onSuccess: () => closeAllPopovers()
    });
  };

  if (isLoadingPrescription) {
    return (
      <Flex justifyContent="center" minWidth={250}>
        <Loader />
      </Flex>
    );
  }

  return (
    <Box marginTop={spacings.xlarge} maxWidth={530}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex alignItems="flex-start" gap={spacings.large}>
          <Box flex={1}>
            <Controller
              name="dosage"
              control={control}
              rules={{
                required: t('DOSAGE_REQUIRED')
              }}
              render={({ field: { ref, value, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  value={value}
                  label={t('DOSAGE_LABEL')}
                  error={!!errors.dosage}
                  helperText={errors?.dosage?.message}
                  type="number"
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: t('ADJUST_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('ADJUST_LABEL')}
                  error={!!errors.startDate}
                  helperText={errors?.startDate?.message}
                />
              )}
            />
          </Box>
          <Box flex={1}>
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: t('END_DATE_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label={t('END_DATE_LABEL')}
                  error={!!errors.endDate}
                  helperText={errors?.endDate?.message}
                />
              )}
            />
          </Box>
        </Flex>

        <Flex
          alignItems="center"
          marginTop={spacings.xlarge}
          gap={spacings.x2large}
        />
        <Flex justifyContent="end">
          <Box width={100}>
            <Button disabled={isUpdatingPrescription} fullWidth type="submit">
              {isUpdatingPrescription ? (
                <Loader size={iconSizes.xsmall} />
              ) : (
                t('SAVE')
              )}
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
