import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { GridColDef } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

import { radii, spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import { getDateFormat } from 'src/utils/dateAndTIme';
import Table from 'src/components/display/Table/Table';
import Card from 'src/components/display/Card/Card';
import Flex from 'src/components/layout/Flex/Flex';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import i18n from 'src/i18n/i18n';
import { getExaminationDate } from 'src/utils/examination';
import { usePopover, useDialog } from 'src/contexts/UIContexts';
import {
  Examination,
  OtherExaminationTypes,
  otherExaminationTypesEntries,
  ultrasoundExaminationTypesEntries
} from 'src/types/exam';
import useExams from 'src/hooks/useExams';
import { AddNewReportMenu } from '../reportForms/AddNewReportMenu';
import { AddEditEggRetrievalReportForm } from '../reportForms/AddEditEggRetrievalReportForm';
import { AddEditSemenAnalysisReportForm } from '../reportForms/AddEditSemenAnalysisReportForm';
import { AddEditFollicularExamForm } from './AddEditFollicularForm';
import { AddEditUltrasoundReportForm } from './AddEditUltrasoundReportForm';
import { AddEditPhysicalExamFemaleForm } from './AddEditPhysicalExamFemaleForm';
import { AddEditTransferReportForm } from '../reportForms/AddEditTransferReportForm';
import { AddEditIUIReportForm } from '../reportForms/AddEditIUIReportForm';
import { GenerateDocumentButton } from './GenerateDocumentButton';

const StyledTable = styled(Table)`
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-row {
    color: ${Colors.emperor};
    padding-inline: 0;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: ${fontWeights.extraBold};
  }
  .MuiDataGrid-cell:not(:first-of-type) {
    font-weight: ${fontWeights.extraBold};
    text-overflow: ellipsis;
  }
  .MuiPaginationItem-root {
    margin-right: 20px;
  }

  .MuiDataGrid-virtualScroller {
    overflow-x: hidden;
  }
`;

const examinationTypesTranslations = [
  ...otherExaminationTypesEntries,
  ...ultrasoundExaminationTypesEntries
]
  .map(([key, value]) => ({
    [value]: i18n.t(key)
  }))
  .reduce((result, currentObject) => {
    const key = Object.keys(currentObject)[0];
    const value = currentObject[key];

    return { ...result, [key]: value };
  }, {});

export const PatientExaminationsTable: FC<{
  patientId: string;
  isPartner?: boolean;
}> = ({ patientId, isPartner }) => {
  const { t } = useTranslation();
  const { openPopover } = usePopover();
  const { openDialog } = useDialog();
  const { getExaminationsByPatientId } = useExams();

  const {
    data: examinations,
    isLoading: isLoadingExaminations,
    isFetching: isFetchingExaminations
  } = getExaminationsByPatientId(patientId);

  const openEditForm = (examination: Examination) => {
    switch (examination.type) {
      case OtherExaminationTypes.EGG_RETRIEVAL:
        openDialog({
          header: t('EDIT_EGG_RETRIEVAL_REPORT'),
          children: (
            <AddEditEggRetrievalReportForm
              examId={examination.id}
              patientId={patientId}
            />
          ),
          fullWidth: true,
          maxWidth: 'lg'
        });
        break;
      case OtherExaminationTypes.SPERM_ANALYSIS:
      case OtherExaminationTypes.SPERM_ANALYSIS_DIAGNOSTIC:
      case OtherExaminationTypes.SPERM_ANALYSIS_IN_CYCLE:
        openDialog({
          header: t('EDIT_SEMEN_ANALYSIS_REPORT'),
          children: (
            <AddEditSemenAnalysisReportForm
              patientId={patientId}
              examinationId={examination.id}
            />
          ),
          fullWidth: true,
          maxWidth: 'lg'
        });
        break;
      case OtherExaminationTypes.FOLLICULAR_SCAN:
        openDialog({
          header: t('EDIT_FOLLICULAR_SCAN'),
          children: (
            <AddEditFollicularExamForm
              patientId={examination.patientId}
              examinationId={examination.id}
            />
          ),
          maxWidth: 'lg',
          fullWidth: true,
          headerActions: [
            <GenerateDocumentButton
              key="generateDocument"
              examId={examination.id}
              patientId={examination.patientId}
            />
          ]
        });
        break;
      case OtherExaminationTypes.PHYSICAL_EXAM_FEMALE:
        openDialog({
          header: t('EDIT_PHYSICAL_EXAMINATION_FEMALE'),
          children: (
            <AddEditPhysicalExamFemaleForm
              patientId={examination.patientId}
              examinationId={examination.id}
            />
          ),
          maxWidth: 'lg',
          fullWidth: true,
          headerActions: [
            <GenerateDocumentButton
              key="generateDocument"
              examId={examination.id}
              patientId={examination.patientId}
            />
          ]
        });
        break;
      case OtherExaminationTypes.EMBRYO_TRANSFER:
        openDialog({
          header: t('EDIT_TRANSFER_REPORT'),
          children: (
            <AddEditTransferReportForm
              patientId={examination.patientId}
              examId={examination.id}
            />
          ),
          maxWidth: 'lg',
          fullWidth: true
        });
        break;
      case OtherExaminationTypes.IUI:
        openDialog({
          header: t('EDIT_IUI_REPORT'),
          children: (
            <AddEditIUIReportForm
              patientId={examination.patientId}
              examId={examination.id}
            />
          ),
          maxWidth: 'lg',
          fullWidth: true
        });
        break;
      default:
        openDialog({
          header: t('EDIT_ULTRASOUND_EXAMINATION'),
          children: (
            <AddEditUltrasoundReportForm
              patientId={examination.patientId}
              examinationId={examination.id}
            />
          ),
          maxWidth: 'lg',
          fullWidth: true,
          headerActions: [
            <GenerateDocumentButton
              key="generateDocument"
              examId={examination.id}
              patientId={examination.patientId}
            />
          ]
        });
        break;
    }
  };

  const columns: GridColDef<Examination>[] = [
    {
      field: 'edit',
      headerName: t('EDIT'),
      renderHeader: () => null,
      align: 'center',
      flex: 1,
      filterable: false,
      valueGetter: (_, row) => row.id,
      renderCell: (params) => (
        <MiniIconButton
          icon={<EditIcon style={{ fill: 'black' }} />}
          onClick={() => openEditForm(params.row)}
        />
      )
    },
    {
      field: 'date',
      flex: 3,
      headerName: t('DATE'),
      type: 'date' as const,
      valueGetter: (_, row) => getExaminationDate(row),
      renderCell: ({ value: examDate }) => {
        return (
          <Typography>
            {examDate
              ? dayjs(examDate).format(getDateFormat({ isShort: true }))
              : '-'}
          </Typography>
        );
      }
    },
    {
      field: 'test',
      flex: 7,
      headerName: t('TEST'),
      valueGetter: (_, row) => examinationTypesTranslations[row.type],
      renderCell: ({ value }) => (
        <Typography fontWeight={fontWeights.extraBold}>{value}</Typography>
      )
    },
    {
      field: 'notes',
      flex: 7,
      headerName: t('NOTES'),
      valueGetter: (value) => value,
      renderCell: ({ value }) => (
        <Typography noWrap fontWeight={fontWeights.extraBold}>
          {value}
        </Typography>
      )
    }
  ];

  const isLoading = isLoadingExaminations || isFetchingExaminations;

  return (
    <Card shadow marginBottom={spacings.x2large} borderRadius={radii.xlarge}>
      <StyledTable
        columns={columns}
        freeHeight
        autoHeight
        columnHeaderHeight={50}
        rows={examinations || []}
        loading={isLoading}
        initialState={{
          sorting: { sortModel: [{ field: 'date', sort: 'desc' }] }
        }}
        paginationModel={{
          pageSize: 10,
          page: 0
        }}
        toolbar={
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Typography
              paddingY={spacings.large}
              fontWeight={fontWeights.extraBold}
            >
              {isPartner ? t('PARTNER_EXAMINATIONS') : t('EXAMINATIONS')}
            </Typography>
            <MiniIconButton
              icon={<AddIcon />}
              onClick={(ev) =>
                openPopover({
                  anchorEl: ev.currentTarget,
                  hideToolbar: true,
                  children: (
                    <AddNewReportMenu examsOnly patientId={patientId} />
                  ),
                  anchorOrigin: {
                    horizontal: 'center',
                    vertical: 'bottom'
                  },
                  transformOrigin: {
                    horizontal: 'center',
                    vertical: 'top'
                  }
                })
              }
            />
          </Flex>
        }
      />
    </Card>
  );
};
