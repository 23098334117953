import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import IconButton from 'src/components/display/IconButton';
import Loader from 'src/components/display/Loader';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { iconSizes, radii, spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import Card from 'src/components/display/Card/Card';
import MiniIconButton from 'src/components/display/MiniIconButton';
import { fontWeights } from 'src/components/styles/fonts';
import Chip from 'src/components/data-entry/Chips/Chip';
import useEncounters from 'src/hooks/useEncounters';
import { Encounter } from 'src/types/encounter';
import { getDocumentSignatureStatus } from 'src/modules/documents/utils/editorUtils/getDocumentSignatureStatus';
import { useDocumentEditor } from 'src/contexts/AppContexts/AppContexts';

export const Documents: FC<{ patientId: string; encounter: Encounter }> = ({
  patientId,
  encounter
}) => {
  const { t } = useTranslation();

  const { generateEncounterDocument, getPatientMedicalNotesDocuments } =
    useEncounters();

  const {
    mutate: generatedEncounterDocument,
    isLoading: isGeneratingEncounterDocument
  } = generateEncounterDocument({
    patientId,
    encounterId: encounter?.id
  });

  const {
    data: encounterDocuments,
    isLoading: isLoadingEncounterDocuments,
    isFetching: isFetchingEncounterDocuments
  } = getPatientMedicalNotesDocuments({
    patientId,
    encounterId: encounter?.id
  });

  const { onDocumentEditorInfoChange, onDocumentsModalOpen } =
    useDocumentEditor();

  const isDisabled = useMemo(() => {
    if (encounter.isLocked) {
      if (encounterDocuments?.length) {
        const latestDocument = encounterDocuments.reduce((acc, current) =>
          new Date(current.updatedAt) > new Date(acc.updatedAt) ? current : acc
        );

        const documentUpdatedAt = dayjs(latestDocument.updatedAt).toDate();
        const encounterUnlockedAt = dayjs(encounter.lockedAt).toDate();
        const isDocumentUpdatedAfterLocked =
          documentUpdatedAt > encounterUnlockedAt;

        if (isDocumentUpdatedAfterLocked) {
          return true;
        }

        return false;
      }

      return false;
    }

    return true;
  }, [encounter, encounterDocuments]);

  const handleEditClick = ({
    documentId,
    templateId
  }: {
    documentId: string;
    templateId: string;
  }) => {
    onDocumentEditorInfoChange({
      patientId,
      templateId,
      documentId
    });

    onDocumentsModalOpen(true);
  };

  if (isLoadingEncounterDocuments || isFetchingEncounterDocuments) {
    <Loader size={iconSizes.small} />;
  }

  return (
    <Box flex={1} maxWidth={550}>
      <Flex justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('DOCUMENTS')}</Typography>
        <IconButton
          disabled={isDisabled}
          icon={
            isGeneratingEncounterDocument ? (
              <Loader size={iconSizes.small} />
            ) : (
              <RefreshIcon />
            )
          }
          bgColor="black"
          iconColor="white"
          iconSize="xsmall"
          onClick={() => {
            generatedEncounterDocument({
              encounterId: encounter?.id,
              patientId
            });
          }}
        />
      </Flex>
      <Flex
        flexDirection="column"
        gap={spacings.medium}
        marginTop={spacings.large}
      >
        {encounterDocuments?.length === 0 ? (
          <Typography variant="body1" color={Colors.emperor}>
            {t('NO_DOCUMENTS')}
          </Typography>
        ) : (
          encounterDocuments?.map((item, index) => {
            const { color, status } =
              getDocumentSignatureStatus({
                isDocumentCompleted: item?.isCompleted,
                isInitialVendorEmailSent: item?.isInitialVendorEmailSent
              }) || {};

            return (
              <Card
                key={index}
                shadow
                borderRadius={radii.large}
                padding={spacings.xlarge}
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={spacings.xlarge}
                >
                  <Flex gap={spacings.large}>
                    <MiniIconButton
                      icon={
                        <EditIcon
                          fill={Colors.black}
                          onClick={() => {
                            return handleEditClick({
                              templateId: item.templateId.toString(),
                              documentId: item.id
                            });
                          }}
                        />
                      }
                    />
                    <Typography variant="h4" color={Colors.emperor}>
                      {item.name}
                    </Typography>
                  </Flex>
                  <Chip
                    label={t(status).toUpperCase()}
                    backgroundColor={color}
                    fontWeight={fontWeights.extraBold}
                  />
                </Flex>
              </Card>
            );
          })
        )}
      </Flex>
    </Box>
  );
};
