import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import Center from 'src/components/layout/Center/Center';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { orderTemplateId } from 'src/types/documents';
import { prescriptionTemplateId } from 'src/utils/general';
import { useDocumentEditor } from 'src/contexts/AppContexts/AppContexts';

const SignOrdersButton = styled(Button)`
  margin-left: ${spacings.xlarge};
  margin-right: ${spacings.xlarge};
`;

interface OpenNewOrdersPrescriptionsDocumentEditorProps {
  patientId: string;
  prescriptionsDocumentId?: string;
  ordersDocumentIds?: string[];
  handleSubmit: () => void;
}

const OpenNewOrdersPrescriptionsDocumentEditor: FC<
  OpenNewOrdersPrescriptionsDocumentEditorProps
> = ({
  patientId,
  ordersDocumentIds,
  prescriptionsDocumentId,
  handleSubmit
}) => {
  const { t } = useTranslation();

  const { onDocumentEditorInfoChange, onDocumentsModalOpen } =
    useDocumentEditor();

  return (
    <Center marginTop={spacings.x2large} gap={spacings.xlarge} maxWidth={700}>
      <Flex flexDirection="column" width="100%">
        <Center
          marginTop={spacings.xlarge}
          gap={spacings.xlarge}
          flexWrap="wrap"
        >
          {ordersDocumentIds?.length
            ? ordersDocumentIds.map((documentId, index) => {
                return (
                  <SignOrdersButton
                    key={documentId}
                    onClick={() => {
                      onDocumentEditorInfoChange({
                        patientId,
                        templateId: orderTemplateId,
                        documentId: documentId
                      });
                      onDocumentsModalOpen(true);
                    }}
                  >
                    {`${t('SIGN_ORDERS_DOCUMENT')} #${index + 1}`}
                  </SignOrdersButton>
                );
              })
            : !orderTemplateId.length &&
              !prescriptionsDocumentId && (
                <Typography>{t('NO_ORDERS_DOCUMENTS_TO_DISPLAY')}</Typography>
              )}
        </Center>
        {prescriptionsDocumentId ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            gap={spacings.xlarge}
            flexDirection="column"
          >
            <Typography>
              {t('TO_DIGITALLY_SING_THE_PRESCRIPTION_CLICK')}
            </Typography>
            <Center>
              <Button
                onClick={() => {
                  onDocumentEditorInfoChange({
                    patientId,
                    templateId: prescriptionTemplateId,
                    documentId: prescriptionsDocumentId
                  });
                  onDocumentsModalOpen(true);
                }}
              >
                {t('SIGN_NOW')}
              </Button>
            </Center>
          </Flex>
        ) : (
          <Center marginBottom={spacings.xlarge}>
            <Typography>{t('NO_PRESCRIPTION_DOCUMENT_TO_DISPLAY')}</Typography>
          </Center>
        )}

        <Button bgColor="gray" onClick={handleSubmit}>
          {t('FINISH')}
        </Button>
      </Flex>
    </Center>
  );
};

export default OpenNewOrdersPrescriptionsDocumentEditor;
