import {
  Document,
  PatientDocument,
  PatientDocumentServer
} from 'src/types/documents';
import { convertNumberIdToString } from 'src/utils/general';

export const convertDocumentFileServerToClient = ({
  id,
  ...restDocument
}: Document): Document => ({
  ...restDocument,
  id: `${id}`
});

export const convertPatientDocumentServerToClient = ({
  createdByStaff,
  templateId,
  ...restDocument
}: PatientDocumentServer): PatientDocument => ({
  ...restDocument,
  createdByStaff: convertNumberIdToString(createdByStaff),
  templateId: convertNumberIdToString(templateId)
});
