import { AppError } from './../types/global';
import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import {
  CreatePatientDocumentPayload,
  CreatePatientDocumentPayloadServer,
  Document,
  DocumentTypes,
  EditDocumentPayload,
  PatientDocument,
  TemplateFile,
} from 'src/types/documents';
import { ToastType } from 'src/components/display/Toast/Toast';
import {
  createDocumentTemplateRequest,
  createPatientDocumentRequest,
  deleteDocumentRequest,
  getDocumentByExternalIdRequest,
  getDocumentByIdRequest,
  getDocumentTemplatesRequest,
  getPatientDocuments,
  getTemplateByIdRequest,
  updatePatientDocumentRequest,
  updateTemplateDocumentRequest,
  createPatientDocumentFromTemplateRequest,
  updateDocumentNameRequest
} from '../api/documents.api';
import { queryKeys, querySubKeys } from './queryKeys';
import { useToast } from 'src/contexts/UIContexts';

function useDocumentsApi() {
  const { t } = useTranslation();
  const { openToast, handleQueryResultToast } = useToast();
  const queryClient = useQueryClient();

  return {
    getDocumentsTemplates: (options?: UseQueryOptions<Document[], AppError>) =>
      useQuery<Document[], AppError>(
        [queryKeys.TEMPLATES],
        getDocumentTemplatesRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_DOCUMENT_TEMPLATES')
            }),
          ...options
        }
      ),
    createDocumentTemplate: () =>
      useMutation<TemplateFile, AppError, TemplateFile>(
        (template: TemplateFile) => createDocumentTemplateRequest(template),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_DOCUMENT_TEMPLATE')
            }),
          onSuccess: (_) => {
            openToast({
              title: t('CREATE_TEMPLATES_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.TEMPLATES]);
          }
        }
      ),
    createPatientDocument: () =>
      useMutation<
        CreatePatientDocumentPayloadServer,
        AppError,
        CreatePatientDocumentPayload
      >(
        (document: CreatePatientDocumentPayload) =>
          createPatientDocumentRequest(document),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_PATIENT_DOCUMENT')
            }),
          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('CREATE_PATIENT_DOCUMENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].DOCUMENTS
            ]);
          }
        }
      ),
    createPatientDocumentFromTemplate: () =>
      useMutation<string, AppError, { patientId: string; templateId: string }>(
        ({ patientId, templateId }) =>
          createPatientDocumentFromTemplateRequest({ patientId, templateId }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_PATIENT_DOCUMENT')
            }),
          onSuccess: (_) => {
            openToast({
              title: t('SAVED_PATIENT_DOCUMENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    deleteDocument: () =>
      useMutation<void, AppError, { documentId: string; patientId: string }>(
        ({ documentId, patientId }) => {
          return deleteDocumentRequest(documentId, patientId);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_DELETE_DOCUMENT')
            }),
          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('DOCUMENT_DELETED_SUCCESSFULLY'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].DOCUMENTS
            ]);
            queryClient.invalidateQueries([queryKeys.DOCUMENTS]);
          }
        }
      ),
    getPatientConsentDocuments: (
      patientId: string,
      options?: UseQueryOptions<PatientDocument[], AppError>
    ) =>
      useQuery<PatientDocument[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].DOCUMENTS,
          querySubKeys[queryKeys.DOCUMENTS][DocumentTypes.CONSENT]
        ],
        () => getPatientDocuments(patientId, DocumentTypes.CONSENT),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_DOCUMENTS')
            }),
          refetchOnWindowFocus: true,
          ...options
        }
      ),
    getPatientGeneralDocuments: (
      patientId: string,
      options?: UseQueryOptions<PatientDocument[], AppError>
    ) =>
      useQuery<PatientDocument[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].DOCUMENTS,
          querySubKeys[queryKeys.DOCUMENTS][DocumentTypes.GENERAL]
        ],
        () => getPatientDocuments(patientId, DocumentTypes.GENERAL),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_DOCUMENTS')
            }),
          refetchOnWindowFocus: true,
          ...options
        }
      ),

    getDocumentByExternalId: (
      patientId: string,
      labOrderExternalId: string,
      options?: UseQueryOptions<PatientDocument, AppError>
    ) =>
      useQuery<PatientDocument, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].DOCUMENTS,
          labOrderExternalId
        ],
        () => getDocumentByExternalIdRequest(labOrderExternalId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_DOCUMENT')
            }),
          refetchOnWindowFocus: true,
          ...options
        }
      ),
    getDocumentById: (
      patientId: string,
      documentId: string,
      options?: UseQueryOptions<PatientDocument, AppError>
    ) =>
      useQuery<PatientDocument, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].DOCUMENTS,
          documentId
        ],
        () => getDocumentByIdRequest(documentId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_DOCUMENT')
            }),
          ...options
        }
      ),
    getTemplateById: (
      templateId: string,
      options?: UseQueryOptions<Document, AppError>
    ) =>
      useQuery<Document, AppError>(
        [queryKeys.TEMPLATES, templateId],
        () => getTemplateByIdRequest(templateId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_DOCUMENT_TEMPLATE')
            }),
          ...options
        }
      ),
    updatePatientDocument: () =>
      useMutation<
        string,
        AppError,
        { documentId: string; document: CreatePatientDocumentPayload }
      >(
        ({ documentId, document }) =>
          updatePatientDocumentRequest(document, documentId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_DOCUMENT')
            }),
          onSuccess: (_, { document: { patientId, externalId } }) => {
            openToast({
              title: t('SAVED_PATIENT_DOCUMENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].LAB_ORDER,
              externalId
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].DOCUMENTS
            ]);
          }
        }
      ),
    updateDocumentName: () =>
      useMutation<
        string,
        AppError,
        { documentId: string; document: EditDocumentPayload }
      >(
        ({ documentId, document }) =>
          updateDocumentNameRequest(document, documentId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_DOCUMENT')
            }),
          onSuccess: (_, { document: { patientId, externalId } }) => {
            openToast({
              title: t('SAVED_PATIENT_DOCUMENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].LAB_ORDER,
              externalId
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].DOCUMENTS
            ]);
          }
        }
      ),
    updateTemplateDocument: () =>
      useMutation<
        string,
        AppError,
        { templateId: string; template: TemplateFile }
      >(
        ({ templateId, template }) =>
          updateTemplateDocumentRequest(template, templateId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_DOCUMENT_TEMPLATE')
            }),
          onSuccess: (_) => {
            openToast({
              title: t('SAVED_PATIENT_DOCUMENT_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      )
  };
}

export default useDocumentsApi;
