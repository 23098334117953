import dayjs from 'dayjs';

import {
  AppointmentTypeCategory,
  Appointment,
  AppointmentTypes,
  InCycleAppointmentTypes,
  DiagnosticAppointmentTypes,
  ConsultationAppointmentTypes,
  PregnancyAppointmentTypes,
  ThirdPartyScreeningAppointmentTypes,
  MaleProcedureAppointmentTypes,
  FinancialAppointmentTypes,
  SurgeryAppointmentTypes
} from 'src/types/appointment';
import { Colors } from 'src/components/styles/colors';
import { SummaryCategory } from './DashboardSummary';

export const getAppointmentCategory = (
  type: AppointmentTypes
): AppointmentTypeCategory => {
  switch (type) {
    case InCycleAppointmentTypes.EGG_RETRIEVAL:
      return AppointmentTypeCategory.EGG_RETRIEVAL;
    case InCycleAppointmentTypes.FRESH_EMBRYO_TRANSFER:
    case InCycleAppointmentTypes.FROZEN_EMBRYO_TRANSFER:
      return AppointmentTypeCategory.EMBRYO_TRANSFER;
    case InCycleAppointmentTypes.IUI:
      return AppointmentTypeCategory.IUI;
    case InCycleAppointmentTypes.BASELINE_IVF:
    case InCycleAppointmentTypes.BASELINE_IUI:
    case InCycleAppointmentTypes.BASELINE_TI:
    case InCycleAppointmentTypes.BASELINE_FET:
    case InCycleAppointmentTypes.MONITORING_IVF:
    case InCycleAppointmentTypes.MONITORING_IUI:
    case InCycleAppointmentTypes.MONITORING_TI:
    case InCycleAppointmentTypes.MONITORING_FET:
    case InCycleAppointmentTypes.LUTEAL_LABS:
    case InCycleAppointmentTypes.OUTSIDE_MONITORING:
    case InCycleAppointmentTypes.DOWN_REGULATION_FET:
    case InCycleAppointmentTypes.DOWN_REGULATION_IVF:
    case InCycleAppointmentTypes.NURSE_INJECTION:
    case InCycleAppointmentTypes.MONITORING_POST_TRIGGER:
      return AppointmentTypeCategory.MONITORING;
    case InCycleAppointmentTypes.MONITORING_EXTERNAL:
      return AppointmentTypeCategory.EXTERNAL_MONITORING;
    case DiagnosticAppointmentTypes.BLOOD_DRAW_ONLY:
    case DiagnosticAppointmentTypes.HSG_HYCOSY_HYFOSY:
    case DiagnosticAppointmentTypes.SALINE_SONOHYSTEROGRAM:
    case DiagnosticAppointmentTypes.CYST_CHECK:
    case DiagnosticAppointmentTypes.HYSTEROSCOPY:
    case DiagnosticAppointmentTypes.WOMENS_EXAM:
    case DiagnosticAppointmentTypes.URINALYSIS:
    case DiagnosticAppointmentTypes.ENDOMETRIAL_BIOPSY:
    case DiagnosticAppointmentTypes.SEMEN_ANALYSIS:
    case DiagnosticAppointmentTypes.ANTRAL_FOLLICLE_COUNT:
    case DiagnosticAppointmentTypes.TRIAL_EMBRYO_TRANSFER:
    case DiagnosticAppointmentTypes.TRIAL_EMBRYO_TRANSFER:
      return AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT;
    case ConsultationAppointmentTypes.DONOR_SCREENING:
    case ConsultationAppointmentTypes.GESTATIONAL_CARRIER_CONSULTATION:
    case ThirdPartyScreeningAppointmentTypes.FRESH_DONOR_CONSULTATION:
    case ThirdPartyScreeningAppointmentTypes.GESTATIONAL_CARRIER_CONSULTATION:
    case ConsultationAppointmentTypes.GESTATIONAL_CARRIER_CONSULTATION:
      return AppointmentTypeCategory.THIRD_PARTY;
    case ConsultationAppointmentTypes.RN_HEALTHCARE_PROFESSIONAL_CONSULT:
    case ConsultationAppointmentTypes.SURGICAL_CONSULT:
    case ConsultationAppointmentTypes.NEW_PATIENT_CONSULTATION:
    case ConsultationAppointmentTypes.NEW_PATIENT_EGG_FREEZING:
    case ConsultationAppointmentTypes.FOLLOW_UP_VISIT:
    case ConsultationAppointmentTypes.FOLLOW_UP_VISIT_IVF_PREP:
    case ConsultationAppointmentTypes.FOLLOW_UP_VISIT_PGTA_RESULTS:
    case ConsultationAppointmentTypes.FOLLOW_UP_VISIT_PREGNANCY_TEST:
    case ConsultationAppointmentTypes.FOLLOW_UP_VISIT_POST_OP:
    case ConsultationAppointmentTypes.PHONE_CONSULT:
    case ConsultationAppointmentTypes.CONSENTS:
    case ConsultationAppointmentTypes.INJECTION_LESSON_IVF:
      return AppointmentTypeCategory.CONSULTATION;
    case PregnancyAppointmentTypes.PREGNANCY_TEST_BLOODWORK:
    case PregnancyAppointmentTypes.OB_ULTRASOUND_TRANSVAGINAL:
      return AppointmentTypeCategory.PREGNANCY;
    case InCycleAppointmentTypes.EMBRYOLOGY:
      return AppointmentTypeCategory.EMBRYOLOGY;
    case InCycleAppointmentTypes.SPERM_PREP_IUI:
    case InCycleAppointmentTypes.SPERM_PREP_IUI_THAW:
    case InCycleAppointmentTypes.SPERM_PREP_IVF:
      return AppointmentTypeCategory.SPERM_PREP;
    case MaleProcedureAppointmentTypes.MICRO_TESE:
    case MaleProcedureAppointmentTypes.PESA:
    case MaleProcedureAppointmentTypes.SPERM_CRYO_FREEZE:
    case MaleProcedureAppointmentTypes.MALE_FDA_SPERM_FREEZE:
      return AppointmentTypeCategory.MALE_PROCEDURES;
    case SurgeryAppointmentTypes.SURGERY:
      return AppointmentTypeCategory.SURGERY;
    case FinancialAppointmentTypes.FINANCIAL_CONSULTATION:
    case FinancialAppointmentTypes.CC_PROCESSING_FEE:
    case FinancialAppointmentTypes.BILLING_CHARGES:
      return AppointmentTypeCategory.FINANCIAL;
  }
};

export const getAppointmentTypeCellBgColor = (
  type: AppointmentTypes
): string => {
  const appointmentTypeCategory = getAppointmentCategory(type);
  switch (appointmentTypeCategory) {
    case AppointmentTypeCategory.EGG_RETRIEVAL:
    case AppointmentTypeCategory.TRANSFER:
    case AppointmentTypeCategory.IUI:
      return Colors.viola;
    case AppointmentTypeCategory.EXTERNAL_MONITORING:
    case AppointmentTypeCategory.MONITORING:
      return Colors.halfBaked;
    case AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT:
    case AppointmentTypeCategory.ULTRASOUND:
      return Colors.cupid;
    case AppointmentTypeCategory.FOLLOWUP:
    case AppointmentTypeCategory.INITIAL_CONSULTATION:
      return Colors.brandy;
    case AppointmentTypeCategory.ALL_COUNSELING_EDUCATION:
    case AppointmentTypeCategory.THIRD_PARTY:
      return Colors.mercury;
    case AppointmentTypeCategory.URGENT_CONSULT:
      return Colors.mauvelous;
    case AppointmentTypeCategory.CONSULTATION:
      return Colors.brandy;
    case AppointmentTypeCategory.SPERM_PREP:
    case AppointmentTypeCategory.MALE_PROCEDURES:
      return Colors.white;
    case AppointmentTypeCategory.SURGERY:
      return Colors.poloBlue;
    case AppointmentTypeCategory.FINANCIAL:
      return Colors.riptide;

    default:
      return Colors.viola;
  }
};

export const getAppointmentTypeSummaryCategory = (
  type: AppointmentTypes
): SummaryCategory => {
  const appointmentTypeCategory = getAppointmentCategory(type);
  switch (appointmentTypeCategory) {
    case AppointmentTypeCategory.EGG_RETRIEVAL:
      return SummaryCategory.RETRIEVAL;
    case AppointmentTypeCategory.IUI:
      return SummaryCategory.IUI;
    case AppointmentTypeCategory.TRANSFER:
      return SummaryCategory.TRANSFER;
    case AppointmentTypeCategory.MONITORING:
      return SummaryCategory.MONITORING;
    case AppointmentTypeCategory.ULTRASOUND:
      return SummaryCategory.ULTRASOUND;
    case AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT:
      return SummaryCategory.DIAGNOSTIC;
    default:
      return SummaryCategory.CONSULTATION;
  }
};

export const getCycleCurrentDay = (
  cycleStartDate: Date,
  appointmentDate: Date
) => {
  if (!cycleStartDate || !appointmentDate) return 'N/A';
  const result = dayjs(appointmentDate).diff(cycleStartDate, 'day');
  return result >= 0 ? result + 1 : result;
};

export const filterAppointmentsByType = (
  appointments: Appointment[],
  filteringType: SummaryCategory | string
): Appointment[] => {
  // check if it's the default value
  if (!Object.values<string>(SummaryCategory).includes(filteringType)) {
    return appointments;
  }

  return appointments.filter(
    (appointment) =>
      filteringType ===
      getAppointmentTypeSummaryCategory(appointment.appointmentType)
  );
};
