import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../components/display/Button';
import useCycle from '../../../hooks/useCycle';
import { CycleWizardVariant, CycleStatus } from '../../../types/cycle';
import Loader from '../../../components/display/Loader';
import { useCycleWizard } from 'src/contexts/AppContexts/AppContexts';

interface CreateActivateCycleButtonProps {
  patientId: string;
}

export const CreateActivateCycleButton: FC<CreateActivateCycleButtonProps> = ({
  patientId
}) => {
  const { t } = useTranslation();
  const { openWizard } = useCycleWizard();
  const { getCurrentCycle } = useCycle();

  const { data: cycle, isLoading: isLoadingCycle } = getCurrentCycle(patientId);

  const isCurrentCyclePending = cycle?.status === CycleStatus.PENDING;
  const showAddActivateCycleButton = !cycle || isCurrentCyclePending;

  if (!showAddActivateCycleButton) return null;

  return (
    <>
      <Button
        startIcon={isLoadingCycle || isCurrentCyclePending ? null : <AddIcon />}
        fullWidth
        onClick={() => {
          openWizard({
            variant: isCurrentCyclePending
              ? CycleWizardVariant.ACTIVATE_CYCLE
              : CycleWizardVariant.INTENDED_CYCLE,
            patientId,
            cycleId: cycle?.id
          });
        }}
      >
        {isLoadingCycle ? (
          <Loader />
        ) : isCurrentCyclePending ? (
          t('ACTIVATE_CYCLE')
        ) : (
          t('INTENDED_TREATMENT')
        )}
      </Button>
    </>
  );
};
