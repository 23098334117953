import { FC, useCallback } from 'react';
import { Dot, Rectangle } from 'recharts';
import { Colors } from 'src/components/styles/colors';
import { fontWeights } from 'src/components/styles/fonts';
import { CyclePhases, GraphDataPhase } from 'src/types/cycle';
import { useParams } from 'react-router-dom';
import Box from 'src/components/layout/Box/Box';
import { useTranslation } from 'react-i18next';
import { spacings } from 'src/components/styles/constants';
import { TransferReportSummary } from './TransferReportSummary';
import {
  GameteThawTable,
  GameteThawTableVariant
} from '../gameteThawTable/GameteThawTable';
import { AddEditTransferReportForm } from '../reportForms/AddEditTransferReportForm';

import { ReactComponent as EmbryoIcon } from '../../../assets/icons/embryo.svg';
import { ReactComponent as EmbryoTransformIcon } from '../../../assets/icons/transfe-uterus.svg';
import { ReactComponent as SpermIcon } from '../../../assets/icons/sperm.svg';
import { AddEditEggRetrievalReportForm } from '../reportForms/AddEditEggRetrievalReportForm';
import { ReactComponent as UterusIcon } from '../../../assets/icons/transfe-uterus.svg';
import { AddEditIUIReportForm } from '../reportForms/AddEditIUIReportForm';
import { IUIReportSummary } from './IUIReportSummary';
import { AddEditSemenAnalysisReportForm } from '../reportForms/AddEditSemenAnalysisReportForm';
import { useDialog, usePopover } from 'src/contexts/UIContexts';

interface TimelinePhaseProps {
  x: number;
  y: number;
  width: number;
  phase: GraphDataPhase;
  label: string;
}

interface PhaseIcon {
  Icon: FC<React.SVGProps<SVGSVGElement>>;
  onClick: (ev: React.MouseEvent<SVGAElement>) => void;
}

const getPhaseBackgroundColor = (phase: CyclePhases) => {
  switch (phase) {
    case CyclePhases.TRIGGER:
      return Colors.riptide;
    case CyclePhases.EGGS_COLLECTED:
      return Colors.viola;
    case CyclePhases.EMBRYO_TRANSFER:
      return Colors.brandy;
    case CyclePhases.OUTCOME_NEGATIVE:
    case CyclePhases.OUTCOME_POSITIVE:
      return Colors.cupid;
    case CyclePhases.IUI:
    case CyclePhases.THAW:
      return Colors.poloBlue;
    default:
      return Colors.alto;
  }
};

export const TimelinePhase: FC<TimelinePhaseProps> = ({
  width,
  x,
  y,
  phase,
  label
}) => {
  const { cycleId, patientId } = useParams();
  const { openPopover, closePopover } = usePopover();
  const { openDialog } = useDialog();
  const { t } = useTranslation();

  const rectangleHeight = 30;
  const labelLeftPadding = 10;
  const dotRadius = 10;
  const iconsSize = 15;

  const getPhaseIcons = useCallback(
    (phase: GraphDataPhase): PhaseIcon[] => {
      const iconsToReturn: PhaseIcon[] = [];

      switch (phase.name) {
        case CyclePhases.THAW:
          iconsToReturn.push({
            Icon: EmbryoIcon,
            onClick: (ev) => {
              openPopover({
                hideToolbar: true,
                children: (
                  <Box width={350}>
                    <GameteThawTable
                      cycleId={cycleId}
                      patientId={patientId}
                      variant={GameteThawTableVariant.SHORT}
                    />
                  </Box>
                ),

                anchorEl: ev.currentTarget,
                transformOrigin: {
                  horizontal: 'right',
                  vertical: 'top'
                },
                anchorOrigin: {
                  horizontal: 'right',
                  vertical: 'bottom'
                }
              });
            }
          });
          break;
        case CyclePhases.EMBRYO_TRANSFER:
          iconsToReturn.push({
            Icon: EmbryoTransformIcon,
            onClick: (ev) => {
              openPopover({
                header: t('TRANSFER_REPORT').toUpperCase(),
                paperPadding: spacings.large,
                children: (
                  <Box>
                    <TransferReportSummary examId={phase.transferExamId} />
                  </Box>
                ),
                anchorEl: ev.currentTarget,
                transformOrigin: {
                  horizontal: 'center',
                  vertical: 'top'
                },
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'bottom'
                },
                onEditClick: () => {
                  closePopover();
                  openDialog({
                    header: t('EDIT_TRANSFER_REPORT'),
                    children: (
                      <AddEditTransferReportForm
                        examId={phase.transferExamId}
                      />
                    ),
                    fullWidth: true,
                    maxWidth: 'lg'
                  });
                }
              });
            }
          });
          break;
        case CyclePhases.IUI:
          iconsToReturn.push({
            Icon: UterusIcon,
            onClick: (ev) => {
              openPopover({
                header: t('IUI_REPORT').toUpperCase(),
                paperPadding: spacings.large,
                children: (
                  <Box>
                    <IUIReportSummary examId={phase.iuiExamId} />
                  </Box>
                ),
                anchorEl: ev.currentTarget,
                transformOrigin: {
                  horizontal: 'center',
                  vertical: 'top'
                },
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'bottom'
                },
                onEditClick: () => {
                  closePopover();
                  openDialog({
                    header: t('EDIT_IUI_REPORT'),
                    children: (
                      <AddEditIUIReportForm
                        examId={phase.iuiExamId}
                        patientId={patientId}
                      />
                    ),
                    fullWidth: true,
                    maxWidth: 'md'
                  });
                }
              });
            }
          });
          break;
      }

      if (phase.semenAnalysisId) {
        iconsToReturn.push({
          Icon: SpermIcon,
          onClick: () => {
            openDialog({
              header: t('SEMEN_ANALYSIS_REPORT'),
              children: (
                <AddEditSemenAnalysisReportForm
                  examinationId={phase.semenAnalysisId}
                  patientId={patientId}
                  cycleId={cycleId}
                />
              ),
              fullWidth: true,
              maxWidth: 'xl'
            });
          }
        });
      }
      if (phase.eggRetrievalId) {
        iconsToReturn.push({
          Icon: EmbryoIcon,
          onClick: () => {
            openDialog({
              header: t('EDIT_EGG_RETRIEVAL_REPORT'),
              children: (
                <AddEditEggRetrievalReportForm
                  examId={phase.eggRetrievalId}
                  patientId={patientId}
                />
              ),
              fullWidth: true,
              maxWidth: 'xl'
            });
          }
        });
      }

      return iconsToReturn;
    },
    [phase]
  );
  const icons = getPhaseIcons(phase);

  return (
    <g>
      <Rectangle
        x={x}
        y={y}
        height={rectangleHeight}
        radius={30}
        width={width}
        fill={getPhaseBackgroundColor(phase.name)}
      />
      <text
        x={x + labelLeftPadding}
        y={y + rectangleHeight / 4}
        color={Colors.mineShaft}
        fontWeight={fontWeights.extraBold}
        alignmentBaseline="before-edge"
        fontSize={12}
      >
        {label.toUpperCase()}
      </text>
      {icons.map(({ Icon, onClick }, index) => {
        const iconPadding = index ? 5 : 0;

        return (
          <g key={`${phase}-icon-${index}`} onClick={onClick} cursor="pointer">
            <Dot
              cx={
                x +
                width -
                labelLeftPadding -
                dotRadius -
                index * dotRadius * 2 -
                iconPadding
              }
              cy={y + rectangleHeight / 2}
              r={dotRadius}
              fill={Colors.white}
            />
            <Icon
              x={
                x +
                width -
                labelLeftPadding -
                dotRadius -
                iconsSize / 2 -
                index * dotRadius * 2 -
                iconPadding
              }
              y={y + rectangleHeight / 4}
              color={Colors.white}
              width={iconsSize}
              height={iconsSize}
            />
          </g>
        );
      })}
    </g>
  );
};
