import { FC, ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  ConfigCatProvider,
  createConsoleLogger,
  LogLevel
} from 'configcat-react';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ThemeProvider from '../theme/ThemeProvider';

type Props = {
  children: ReactNode;
  fallbackComponent: React.ComponentType<any>;
  staleTime: number;
};

export const GeneralProvider: FC<Props> = ({
  children,
  fallbackComponent: F,
  staleTime
}) => {
  const logger = createConsoleLogger(LogLevel.Info);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryOnMount: false,
        retry: false,
        staleTime,
        keepPreviousData: false,
        refetchOnWindowFocus: false
      }
    }
  });
  return (
    <ErrorBoundary FallbackComponent={F}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ConfigCatProvider
            sdkKey={process.env.REACT_APP_CONFIGCAT_KEY}
            options={{ logger }}
          >
            <BrowserRouter>
              <ThemeProvider>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {children}
                </LocalizationProvider>
              </ThemeProvider>
            </BrowserRouter>
          </ConfigCatProvider>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
