import { ChangeEvent, FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@mui/material';

import InputField from 'src/components/data-entry/InputField/InputField';
import Select from 'src/components/data-entry/Select/Select';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import TimePicker from 'src/components/data-entry/TimePicker/TimePicker';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import TextArea from 'src/components/data-entry/TextArea/TextArea';
import Chips from 'src/components/data-entry/Chips/Chips';
import {
  SemenAnalysisReportForm,
  spermPrepMethodEntries,
  spermPrepSuitabilityEntries
} from 'src/types/exam';
import {
  calcMotileSpermCount,
  calcProgressiveMotileSpermCount,
  calcTotalMotile,
  calcProgressiveMotile,
  calcTotalSpermCount
} from './calcSpermMotility';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Icd10Chips } from '../common/Icd10Chips';

export const SpermPrep: FC<{
  control: Control<SemenAnalysisReportForm, any, SemenAnalysisReportForm>;
  errors: FieldErrors<SemenAnalysisReportForm>;
  getValues: UseFormGetValues<SemenAnalysisReportForm>;
  setValue: UseFormSetValue<SemenAnalysisReportForm>;
}> = ({ control, errors, getValues, setValue }) => {
  const { t } = useTranslation();

  const spermPrepSuitabilityOptions = spermPrepSuitabilityEntries.map(
    ([key, value]) => ({
      label: t(key),
      value
    })
  );

  const spermPrepMethodOptions = spermPrepMethodEntries.map(([key, value]) => ({
    label: t(key),
    value,
    labelText: t(key)
  }));

  const onChangeSpermPrepConcentration = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(Number(ev.target.value));
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
    calcTotalSpermCount({ getValues, setValue });
    calcProgressiveMotile({ getValues, setValue });
  };

  const onChangeSpermPrepVolume = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(Number(ev.target.value));
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
    calcTotalSpermCount({ getValues, setValue });
  };

  const onChangeSpermPrepProgressiveMotileA = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(Number(ev.target.value));
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
    calcTotalMotile({ getValues, setValue });
    calcProgressiveMotile({ getValues, setValue });
  };

  const onChangeSpermPrepProgressiveMotileB = (ev, onChange) => {
    onChange(Number(ev.target.value));
    calcMotileSpermCount({ getValues, setValue });
    calcProgressiveMotileSpermCount({ getValues, setValue });
    calcTotalMotile({ getValues, setValue });
    calcProgressiveMotile({ getValues, setValue });
  };

  const onChangeSpermPrepProgressiveMotileC = (ev, onChange) => {
    onChange(Number(ev.target.value));
    calcMotileSpermCount({ getValues, setValue });
    calcTotalMotile({ getValues, setValue });
  };

  return (
    <>
      <Box marginBottom={spacings.medium}>
        <Typography
          marginBottom={spacings.medium}
          variant="h2"
          fontWeight={fontWeights.extraBold}
        >
          {t('SPERM_PREP')}
        </Typography>
      </Box>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="spermPrepStartTime"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TimePicker
                {...field}
                label={t('SPERM_PREP_START_TIME').toUpperCase()}
                inputRef={ref}
                error={!!errors?.timeReceived}
                helperText={errors.timeReceived?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepFinishTime"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TimePicker
                {...field}
                label={t('SPERM_PREP_FINISH_TIME').toUpperCase()}
                inputRef={ref}
                error={!!errors?.timeReceived}
                helperText={errors.timeReceived?.message}
                fullWidth
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepMethod"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Chips
                helperText={errors?.spermPrepMethod?.message}
                value={value}
                options={spermPrepMethodOptions}
                renderSelectedOptionsOutside
                title={t('SPERM_PREP_METHOD').toUpperCase()}
                onAddChip={(newChip) => onChange([...value, newChip])}
                onRemoveChip={(chipValueToDelete) => {
                  const updatedMethods = value.filter(
                    (embryo) => embryo !== chipValueToDelete
                  );
                  onChange(updatedMethods);
                }}
                shouldSortOptions
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepSuitability"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <Select
                {...field}
                label={t('SPERM_PREP_SUITABILITY')}
                inputRef={ref}
                error={!!errors?.spermPrepSuitability}
                helperText={errors?.spermPrepSuitability?.message}
                defaultOption={t('SPERM_PREP_SUITABILITY')}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
                options={spermPrepSuitabilityOptions}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="spermPrepVolume"
            control={control}
            rules={{
              min: { value: 0, message: t('MAR_TEST_VALIDATION_MSG') }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('VOLUME').toUpperCase()} (${t('ML')})`}
                placeholder={t('VOLUME')}
                error={!!errors?.spermPrepVolume}
                helperText={errors?.spermPrepVolume?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{t('ML')}</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
                onChange={(ev) => onChangeSpermPrepVolume(ev, onChange)}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepConcentration"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('CONCENTRATION_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) => onChangeSpermPrepConcentration(ev, onChange)}
                label={`${t('CONCENTRATION').toUpperCase()} (${t('M/ML')})`}
                placeholder={t('CONCENTRATION')}
                error={!!errors?.spermPrepConcentration}
                helperText={errors?.spermPrepConcentration?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepTotalSpermCount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('TOTAL_SPERM_COUNT').toUpperCase()} (${t(
                  'M/EJAC'
                )})`}
                placeholder={t('TOTAL_SPERM_COUNT')}
                error={!!errors?.spermPrepTotalSpermCount}
                helperText={errors?.spermPrepTotalSpermCount?.message}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepNormalMorphology"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('NORMAL_MORPHOLOGY_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('NORMAL_MORPHOLOGY').toUpperCase()}`}
                placeholder={t('NORMAL_MORPHOLOGY')}
                error={!!errors?.spermPrepNormalMorphology}
                helperText={errors?.spermPrepNormalMorphology?.message}
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1} />
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="spermPrepProgressiveMotileA"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('A_PROGRESSIVE_MOTILE_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) =>
                  onChangeSpermPrepProgressiveMotileA(ev, onChange)
                }
                label={`${t('A_PROGRESSIVE_MOTILE').toUpperCase()}`}
                placeholder={t('A_PROGRESSIVE_MOTILE')}
                error={!!errors?.spermPrepProgressiveMotileA}
                helperText={errors?.spermPrepProgressiveMotileA?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepProgressiveMotileB"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t('B_NON_PROGRESSIVE_MOTILE_VALIDATION_MSG')
              }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) =>
                  onChangeSpermPrepProgressiveMotileB(ev, onChange)
                }
                label={`${t('B_NON_PROGRESSIVE_MOTILE').toUpperCase()}`}
                placeholder={t('B_NON_PROGRESSIVE_MOTILE')}
                error={!!errors?.spermPrepProgressiveMotileB}
                helperText={errors?.spermPrepProgressiveMotileB?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepProgressiveMotileC"
            control={control}
            rules={{
              min: { value: 0, message: t('C_NON_MOTILE_VALIDATION_MSG') }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) =>
                  onChangeSpermPrepProgressiveMotileC(ev, onChange)
                }
                label={`${t('C_NON_MOTILE').toUpperCase()}`}
                placeholder={t('C_NON_MOTILE')}
                error={!!errors?.spermPrepProgressiveMotileC}
                helperText={errors?.spermPrepProgressiveMotileC?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepImmotileD"
            control={control}
            rules={{
              min: { value: 0, message: t('D_IMMOTILE_VALIDATION_MSG') }
            }}
            render={({ field: { ref, onChange, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                onChange={(ev) => {
                  onChange(ev);
                }}
                label={`${t('D_IMMOTILE').toUpperCase()}`}
                placeholder={t('D_IMMOTILE')}
                error={!!errors?.spermPrepImmotileD}
                helperText={errors?.spermPrepImmotileD?.message}
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  inputProps: { min: 0 }
                }}
              />
            )}
          />
        </Box>
      </Flex>
      <Flex gap={spacings.large} marginBottom={spacings.large}>
        <Box flex={1}>
          <Controller
            name="spermPrepProgressiveMotileSpermCount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PROGRESSIVE_MOTILE_SPERM_COUNT').toUpperCase()}
                placeholder={t('PROGRESSIVE_MOTILE_SPERM_COUNT')}
                error={!!errors?.spermPrepProgressiveMotileSpermCount}
                helperText={
                  errors?.spermPrepProgressiveMotileSpermCount?.message
                }
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepMotileSpermCount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={`${t('MOTILE_SPERM_COUNT').toUpperCase()}`}
                placeholder={t('MOTILE_SPERM_COUNT')}
                error={!!errors?.spermPrepMotileSpermCount}
                helperText={errors?.spermPrepMotileSpermCount?.message}
                fullWidth
                InputProps={{
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>

        <Box flex={1}>
          <Controller
            name="spermPrepTotalMotile"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('TOTAL_MOTILITY').toUpperCase()}
                placeholder={t('TOTAL_MOTILITY')}
                error={!!errors?.spermPrepTotalMotile}
                helperText={errors?.spermPrepTotalMotile?.message}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            name="spermPrepProgressiveMotility"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                label={t('PROGRESSIVE_MOTILITY').toUpperCase()}
                placeholder={t('PROGRESSIVE_MOTILITY')}
                error={!!errors?.spermPrepProgressiveMotility}
                helperText={errors?.spermPrepProgressiveMotility?.message}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                  readOnly: true
                }}
                type="number"
              />
            )}
          />
        </Box>
      </Flex>
      <Box flex={1}>
        <Controller
          name="spermPrepComments"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              label={t('GENERAL_COMMENTS').toUpperCase()}
              placeholder={t('GENERAL_COMMENTS')}
              error={!!errors.spermPrepComments}
              helperText={errors?.spermPrepComments?.message}
              minRows={3}
              maxRows={3}
              resize="auto"
              fullWidth
            />
          )}
        />
      </Box>
      <Flex marginTop={spacings.xlarge}>
        <Box flex={1}>
          <Icd10Chips
            control={control}
            name="icd10Codes"
            error={!!errors.icd10Codes}
            helperText={errors?.icd10Codes?.message}
            label={t('ICD_10_CODES_OR_OBSERVATIONS').toUpperCase()}
            flattenValue
          />
        </Box>
        <Box flex={1} />
      </Flex>
    </>
  );
};
