import { FC } from 'react';

import { iconSizes } from 'src/components/styles/constants';
import IconButton from 'src/components/display/IconButton/IconButton';
import Loader from 'src/components/display/Loader';
import useExams from 'src/hooks/useExams';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document.svg';

export const GenerateDocumentButton: FC<{
  examId: string;
  patientId: string;
}> = ({ examId, patientId }) => {
  const { generateExamDocument } = useExams();

  const { mutate: generateDocument, isLoading: isGeneratingDocument } =
    generateExamDocument({
      patientId
    });

  return (
    <IconButton
      disabled={isGeneratingDocument}
      bgColor="white"
      icon={
        isGeneratingDocument ? (
          <Loader size={iconSizes.small} />
        ) : (
          <DocumentIcon />
        )
      }
      onClick={() => generateDocument({ examId, patientId })}
    />
  );
};
