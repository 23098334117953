import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-premium';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card';
import MiniIconButton from 'src/components/display/MiniIconButton';
import Table, { TableProps } from 'src/components/display/Table/Table';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { radii, spacings } from 'src/components/styles/constants';
import { paginationDefaultParams } from 'src/constants';
import useFeed from 'src/hooks/useFeed';
import useStaffMembers from 'src/hooks/useStaffMembers';
import useGetFeedColumns from 'src/modules/feed/useGetFeedColumns';
import { handleCellClick } from 'src/modules/feed/utils';
import {
  FeedActivityEventType,
  cycleCommentActivityFeed,
  embryoNoteActivityFeed
} from 'src/types/feed';
import AddIcon from '@mui/icons-material/Add';
import { AddProgressNote } from 'src/modules/feed/AddProgressNote';
import { usePopover } from 'src/contexts/UIContexts';

interface Props extends Omit<TableProps, 'columns' | 'loading'> {
  patientId: string;
  standalone?: boolean;
}

const PatientNotesTable: FC<Props> = ({
  patientId,
  standalone = false,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { openPopover, closePopover } = usePopover();

  const { getFeedActivities } = useFeed();
  const filterModel: GridFilterModel = {
    items: [
      {
        field: 'name',
        operator: 'equals',
        value: 'Encounter Locked'
      },
      {
        field: 'eventType',
        operator: 'equals',
        value: FeedActivityEventType.PROGRESS_NOTE
      },
      {
        field: 'name',
        operator: 'equals',
        value: cycleCommentActivityFeed
      },
      {
        field: 'name',
        operator: 'equals',
        value: embryoNoteActivityFeed
      }
    ],
    logicOperator: GridLogicOperator.Or
  };
  const [paginationModel, setPaginationModel] = useState(
    paginationDefaultParams
  );
  const { getStaffMembersNames } = useStaffMembers();

  const { data: doctors } = getStaffMembersNames();

  const getColumns = useGetFeedColumns(doctors);
  const columns = getColumns();
  const {
    data,
    isLoading: isFeedActivitiesLoading,
    isFetching: isFeedActivitiesFetching
  } = getFeedActivities({
    patientId,
    params: {
      pagination: paginationDefaultParams
    },
    filters: filterModel
  });
  const rows = data?.rows ?? [];
  const totalRowCount = data?.count ?? 0;
  const isLoading = isFeedActivitiesLoading || isFeedActivitiesFetching;

  return (
    <Card
      overflow="hidden"
      borderRadius={standalone ? radii.none : radii.xlarge}
      paddingTop={standalone ? spacings.none : spacings.large}
      paddingX={standalone ? spacings.none : spacings.large}
    >
      <Table
        toolbar={
          <Flex gap={spacings.large} alignItems="center">
            <Typography variant="h2">{t('PATIENT_NOTES')}</Typography>
            <MiniIconButton
              id="add-notes-button"
              icon={<AddIcon />}
              onClick={(ev) =>
                openPopover({
                  children: (
                    <AddProgressNote
                      patientId={patientId}
                      onClose={closePopover}
                    />
                  ),
                  anchorEl: ev.currentTarget,
                  header: t('ADD_PROGRESS_NOTE'),
                  paperPadding: spacings.large,
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  transformOrigin: {
                    horizontal: 'left',
                    vertical: 'top'
                  }
                })
              }
            />
          </Flex>
        }
        hideFilterToolbar
        filterMode="server"
        loading={isLoading}
        columns={columns}
        columnHeaderHeight={50}
        rows={rows}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        rowCount={totalRowCount}
        getRowClassName={(params) => (params.row.isTampered ? 'tampered' : '')}
        onCellClick={(params, event) => {
          handleCellClick({
            params,
            event,
            t,
            openPopover
          });
        }}
        {...props}
      />
    </Card>
  );
};
export default PatientNotesTable;
