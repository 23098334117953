import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  css,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useTranslation } from 'react-i18next';

import Avatar from 'src/components/display/Avatar';
import useMeApi from 'src/hooks/useMeApi';
import { UserTypes } from 'src/types/user';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { ClinicSettingsTabs } from 'src/modules/clinic/ClinicSettings';
import { useAuth } from 'src/contexts/AppContexts/AppContexts';

const appVersion = process.env.REACT_APP_VERSION || '0.0.0';

const UserBoxButton = styled(Button)`
  ${({ theme }) => css`
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  `}
`;

const ExpandMoreContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const MenuUserBox = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
  `}
`;

function HeaderUserbox() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isScreenLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { getMe } = useMeApi();
  const { getDefaultAvatar } = useGetDefaultAvatar();

  const { data: me, isFetching } = getMe();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleClickSettings = () => {
    navigate(`/app/clinic-settings/${ClinicSettingsTabs.STAFF}`);
    handleClose();
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const { staffAvatar, userAvatar, userTitle, userSubtitle } = useMemo(() => {
    const staffAvatar = getDefaultAvatar({
      userId: me?.user.id,
      userType: UserTypes.staff,
      pictureId: me?.user.pictureId
    });
    const userAvatar = getDefaultAvatar({
      userId: me?.user.id,
      userType: me?.userType,
      pictureId: me?.user.pictureId
    });
    const userTitle = me?.user.displayName || me?.user.email;
    const userSubtitle = me?.user.jobTitle;

    return { staffAvatar, userAvatar, userTitle, userSubtitle };
  }, [me]);

  return (
    <>
      <UserBoxButton
        id="user-box-button"
        color="inherit"
        ref={ref}
        onClick={handleOpen}
      >
        {!isFetching && (
          <Avatar
            image={staffAvatar}
            title={isScreenLarge ? userTitle : undefined}
            subtitle={isScreenLarge ? userSubtitle : undefined}
          />
        )}
        <ExpandMoreContainer component="span">
          <ExpandMoreTwoToneIcon
            sx={{
              ml: 1
            }}
          />
        </ExpandMoreContainer>
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            width: 210,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Avatar
            image={userAvatar}
            title={me?.user.displayName || me?.user.email}
            subtitle={me?.user.jobTitle}
          />
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />

        <Box m={1}>
          <Button color="inherit" fullWidth onClick={handleClickSettings}>
            {t('CLINIC_SETTINGS')}
          </Button>
        </Box>

        <Box m={1}>
          <Button
            id="logout-button"
            color="inherit"
            fullWidth
            onClick={handleLogout}
          >
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('SIGN_OUT')}
          </Button>
          {appVersion && (
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'center',
                marginTop: spacings.small
              }}
            >
              <Typography
                sx={{
                  fontSize: '9px'
                }}
                variant="caption"
              >
                Version {appVersion}
              </Typography>
            </Flex>
          )}
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
