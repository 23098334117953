import { FC } from 'react';
import Loader from 'src/components/display/Loader';
import Flex from 'src/components/layout/Flex';
import { PageSizes } from 'src/components/styles/constants';
import useCodes from 'src/hooks/useCodes';
import Card from 'src/components/display/Card';
import { GridColDef } from '@mui/x-data-grid-premium';
import Typography from 'src/components/display/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import { BillingServiceCode } from 'src/types/codes';
import { useTranslation } from 'react-i18next';
import { StyledTable } from '../patients/gameteThawTable/GameteThawTable';

export const BillingServices: FC = () => {
  const { getBillingServices } = useCodes();
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = getBillingServices({
    withVendors: true
  });
  const totalRowCount = data?.length ?? 0;
  const columns: GridColDef<BillingServiceCode>[] = [
    {
      flex: 2,
      field: 'id',
      headerName: t('ID'),
      renderCell: ({ row }) => row?.id
    },
    {
      flex: 1,
      field: 'serviceType',
      headerName: t('SERVICE_TYPE'),
      renderCell: ({ row }) => row?.serviceType
    },
    {
      flex: 1,
      field: 'billingCode',
      headerName: t('BILLING_CODE'),
      renderCell: ({ row }) => row?.billingCode
    },
    {
      flex: 1,
      field: 'billingCodeType',
      headerName: t('BILLING_CODE_TYPE'),
      renderCell: ({ row }) => row?.billingCodeType
    },
    {
      flex: 1,
      field: 'orderCode',
      headerName: t('ORDER_CODE'),
      renderCell: ({ row }) => row?.orderCode
    },
    {
      flex: 1,
      field: 'orderCodeType',
      headerName: t('ORDER_CODE_TYPE'),
      renderCell: ({ row }) => row?.orderCodeType
    },
    {
      flex: 1,
      field: 'vendors',
      headerName: t('VENDORS'),
      renderCell: ({ row }) =>
        row?.labsVendors?.map(
          (entry) =>
            `${entry.vendorKey}: ${entry.vendorSpecificOrderCode || 'No Code'} `
        )
    },
    {
      flex: 2,
      field: 'serviceDescription',
      headerName: t('DESCRIPTION'),
      renderCell: ({ row }) => row?.serviceDescription
    }
  ];

  const displayedColumns: GridColDef<BillingServiceCode>[] = columns.map(
    (column) => ({
      ...column,
      renderHeader: () => (
        <Typography fontWeight={fontWeights.extraBold}>
          {column.headerName}
        </Typography>
      ),
      filterable: true,
      hideable: false
    })
  );

  return (
    <Flex flexDirection="column">
      <Card overflow="hidden">
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <StyledTable
            autoHeight
            paginationModel={{ page: 0, pageSize: PageSizes.MEDIUM }}
            loading={false}
            columns={displayedColumns}
            rows={data}
            rowCount={totalRowCount}
          />
        )}
      </Card>
    </Flex>
  );
};
