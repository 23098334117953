import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from 'src/components/display/Tabs';
import { AddEditAppointmentTabs } from 'src/types/appointment';
import ConsentDialogTemplate from '../ConsentDialogTemplate';
import CodingTab from './appointmentTabs/CodingTab';
import EncounterNotesTab from './appointmentTabs/EncounterNotesTab';
import ScheduleAndOrdersTab from './appointmentTabs/ScheduleAndOrdersTab';
import VitalsTab from './appointmentTabs/VitalsTab';
import { TabContentsProps } from 'src/components/display/Tabs/Tabs';
import { useDialog } from 'src/contexts/UIContexts';

const getTabOptions = (
  patientId: string,
  appointmentId: string
): AddEditAppointmentTabs[] => {
  const isEditMode = patientId && appointmentId;
  if (isEditMode) {
    return [
      AddEditAppointmentTabs.SCHEDULE_OR_ORDERS,
      AddEditAppointmentTabs.ENCOUNTER_NOTE,
      AddEditAppointmentTabs.CODING
    ];
  } else {
    return [AddEditAppointmentTabs.SCHEDULE_OR_ORDERS];
  }
};

const AddEditAppointment: FC<{
  appointmentId?: string;
  patientId?: string;
  cycleId?: string;
  defaultTab?: AddEditAppointmentTabs;
}> = ({
  appointmentId,
  patientId,
  cycleId,
  defaultTab = AddEditAppointmentTabs.SCHEDULE_OR_ORDERS
}) => {
  const [selectedTab, setSelectedTab] =
    useState<AddEditAppointmentTabs>(defaultTab);
  const [isCurrentFormDirty, setIsCurrentFormDirty] = useState<boolean>(false);
  const { openDialog, closeDialog } = useDialog();
  const { t } = useTranslation();

  const tabOptions = useMemo(
    () => getTabOptions(patientId, appointmentId),
    [patientId, appointmentId]
  );

  const handleTabChange = (value: AddEditAppointmentTabs) => {
    if (isCurrentFormDirty) {
      openDialog({
        header: t('UNSAVED_CHANGES_HEADER'),
        children: (
          <ConsentDialogTemplate
            declineContent={t('CANCEL')}
            acceptContent={t('DISCARD_CHANGES')}
            content={t('UNSAVED_CHANGES_CONTENT')}
            onAccept={() => {
              setIsCurrentFormDirty(false);
              setSelectedTab(value);
              closeDialog();
            }}
            onDecline={closeDialog}
          />
        )
      });
    } else {
      setSelectedTab(value);
    }
  };

  const tabContents: TabContentsProps<AddEditAppointmentTabs>[] = [];
  if (tabOptions.includes(AddEditAppointmentTabs.SCHEDULE_OR_ORDERS)) {
    tabContents.push({
      value: AddEditAppointmentTabs.SCHEDULE_OR_ORDERS,
      label: t('SCHEDULE_AND_ORDERS'),
      children: (
        <ScheduleAndOrdersTab
          onDirtyFormChange={setIsCurrentFormDirty}
          appointmentId={appointmentId}
          patientId={patientId}
          cycleId={cycleId}
        />
      )
    });
  }
  if (tabOptions.includes(AddEditAppointmentTabs.VITALS)) {
    tabContents.push({
      value: AddEditAppointmentTabs.VITALS,
      label: t('VITALS'),
      children: (
        <VitalsTab
          onDirtyFormChange={setIsCurrentFormDirty}
          appointmentId={appointmentId}
          patientId={patientId}
        />
      )
    });
  }
  if (tabOptions.includes(AddEditAppointmentTabs.ENCOUNTER_NOTE)) {
    tabContents.push({
      value: AddEditAppointmentTabs.ENCOUNTER_NOTE,
      label: t('ENCOUNTER_NOTE'),
      children: (
        <EncounterNotesTab
          onDirtyFormChange={setIsCurrentFormDirty}
          appointmentId={appointmentId}
          patientId={patientId}
        />
      )
    });
  }
  if (tabOptions.includes(AddEditAppointmentTabs.CODING)) {
    tabContents.push({
      value: AddEditAppointmentTabs.CODING,
      label: t('CODING'),
      children: (
        <CodingTab
          onDirtyFormChange={setIsCurrentFormDirty}
          appointmentId={appointmentId}
          patientId={patientId}
        />
      )
    });
  }
  return (
    <Tabs<AddEditAppointmentTabs>
      onTabChange={handleTabChange}
      selectedTab={selectedTab}
      defaultTab={AddEditAppointmentTabs.SCHEDULE_OR_ORDERS}
      tabContents={tabContents}
    />
  );
};

export default AddEditAppointment;
