import { FC, ReactElement } from 'react';
import Box from '../../layout/Box';
import { SvgIcon, css, styled } from '@mui/material';
import Flex, { FlexProps } from '../../layout/Flex';
import { Colors } from '../../styles/colors';
import { spacings, zIndices } from '../../styles/constants';
import IconButton from '../IconButton';
import Typography from '../Typography';
import Loader from '../Loader';
import { PopoverProps } from '../Popover/Popover';
import { makeShouldForwardProps } from 'src/components/utils';
import { usePopover } from 'src/contexts/UIContexts';

export interface MenuItemProps extends FlexProps {
  label: string;
  action?: () => void;
  disabled?: boolean;
  icon?: ReactElement;
  subMenu?: Partial<PopoverProps>;
  menuTheme?: MenuTheme;
}

export enum MenuTheme {
  Light = 'LIGHT',
  Dark = 'DARK'
}

interface MenuProps {
  items: MenuItemProps[];
  loading?: boolean;
  menuTheme?: MenuTheme;
}

interface StyledFlexContainerProps extends FlexProps {
  disabled?: boolean;
  menuTheme?: MenuTheme;
}

const shouldForwardPropContainer = makeShouldForwardProps(['menuTheme']);
const StyledFlexContainer = styled(Flex, {
  shouldForwardProp: shouldForwardPropContainer
})<StyledFlexContainerProps>`
  z-index: ${zIndices.medium};
  align-items: center;
  height: 35px;
  padding-inline: ${spacings.medium};
  padding-block: ${spacings.xsmall};
  gap: ${spacings.medium};
  &:hover {
    cursor: pointer;
  }
  &:first-of-type {
    padding-top: ${spacings.small};
  }
  &:last-of-type {
    padding-bottom: ${spacings.small};
  }

  ${({ disabled, menuTheme }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          &:hover {
            background-color: ${menuTheme === MenuTheme.Dark
              ? Colors.gray
              : Colors.alto};
            cursor: pointer;
          }
        `}
`;

const shouldForwardPropMenu = makeShouldForwardProps(['menuTheme']);
const StyledMenuContainer = styled(Box, {
  shouldForwardProp: shouldForwardPropMenu
})<{ menuTheme?: MenuTheme }>`
  ${({ menuTheme }) =>
    menuTheme === MenuTheme.Dark &&
    css`
      background-color: ${Colors.emperor};
      span,
      svg {
        color: ${Colors.white};
      }
      path {
        stroke: ${Colors.white};
      }
    `}
`;

const MenuItem: FC<MenuItemProps> = ({
  label,
  action,
  disabled,
  icon,
  subMenu,
  menuTheme,
  ...flexProps
}) => {
  const { closePopover, openPopover } = usePopover();

  const selectMenuItem = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (action) {
      action?.();
      closePopover();
    }

    if (subMenu) {
      openPopover({
        ...subMenu,
        anchorEl: ev.currentTarget,
        hideToolbar: true
      });
    }
  };

  return (
    <StyledFlexContainer
      disabled={disabled}
      onClick={(ev) => selectMenuItem(ev)}
      menuTheme={menuTheme}
      {...flexProps}
    >
      {icon && (
        <IconButton bgColor="transparent" icon={<SvgIcon>{icon}</SvgIcon>} />
      )}
      <Typography>{label}</Typography>
    </StyledFlexContainer>
  );
};

const Menu: FC<MenuProps> = ({
  items,
  loading,
  menuTheme = MenuTheme.Light
}) => {
  return loading ? (
    <Flex
      alignItems="center"
      justifyContent="center"
      minWidth="160px"
      minHeight="250px"
    >
      <Loader />
    </Flex>
  ) : (
    <StyledMenuContainer minWidth="160px" menuTheme={menuTheme}>
      {items.map((item, index) => {
        return <MenuItem key={index} menuTheme={menuTheme} {...item} />;
      })}
    </StyledMenuContainer>
  );
};

export default Menu;
