import { GridColDef } from '@mui/x-data-grid-premium';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'src/components/display/Card';
import Table from 'src/components/display/Table';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import Flex from 'src/components/layout/Flex';
import { spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import useEggsEmbryos from 'src/hooks/useEggsEmbryos';
import { Cycle } from 'src/types/cycle';

import {
  LabratoryProtocol,
  EggAndEmbryoNotes,
  Fate,
  InseminationMethods,
  Maturity,
  Day1,
  PGTTissueType,
  GeneticStatus,
  EmbryoGender
} from 'src/types/eggAndEmbryo';
import NotesWrapper from '../../NotesWrapper';
import dayjs from 'dayjs';
import {
  dashSeparatedDateFormat,
  getDateFormat,
  getTimeFormat
} from 'src/utils/dateAndTIme';
import { DoctorChips } from 'src/modules/patients/common/DoctorChips';
import { MultiSelectOption } from 'src/components/data-entry/MultiSelect/MultiSelect';
import TableMultiSelect from './TableMultiSelect';
import { YesOrNo } from 'src/types/global';
import { ToastType } from 'src/components/display/Toast/Toast';
import { useToast } from 'src/contexts/UIContexts';

interface Props {
  cycleToDisplay: Cycle;
  patientId: string;
}

enum TableRowType {
  INSEMINATION_TIME = 'inseminationTime',
  NO = 'no',
  ID = 'id',
  TLID = 'tlid',
  MATURE = 'mature',
  INSEMINATION_METHOD = 'inseminationMethod',
  ADD_ONS = 'addOns',
  DAY1 = 'day1',
  DAY2 = 'day2',
  DAY3 = 'day3',
  DAY4 = 'day4',
  DAY5 = 'day5',
  DAY6 = 'day6',
  DAY7 = 'day7',
  AI_SCORE = 'aiScore',
  NOTES = 'notes',
  BIOPSY_TECH = 'biopsyTech',
  BIOPSY_WITNESS = 'biopsyWitness',
  BIOPSY_TIME = 'biopsyTime',
  TUBE_TECH = 'tubeTech',
  TUBE_WITNESS = 'tubeWitness',
  TUBE_TIME = 'tubeTime',
  LOCATION_CANISTER = 'locationCanister',
  CULTURE_DISH_ID = 'cultureDishId',
  GROUP_CULTURE = 'groupCulture',
  EMBRYO_CULTURE_DROP_WELL = 'embryoCultureDrop/Well',
  FREEZE_DATE = 'freezeDate',
  TANK = 'tank',
  BEACON = 'beacon',
  POSITION = 'position',
  CHROMOSOMES_AFFECTED = 'chromosmesAffected',
  GENDER = 'gender',
  SHIPPED_DATE = 'shippedDate',
  IMPORTED_DATE = 'importedDate'
}
interface TableRow {
  no: number;
  id: string;
  tlid?: string;
  mature?: Maturity;
  inseminationTime?: Date;
  fate?: Fate;
  inseminationMethod?: InseminationMethods;
  labratoryProtocols?: LabratoryProtocol[];
  day1?: string;
  day2?: string;
  day3?: string;
  day4?: string;
  day5?: string;
  day6?: string;
  day7?: string;
  aiScore?: string;
  notes?: EggAndEmbryoNotes[];
  biopsyTech?: string;
  biopsyWitness?: string;
  biopsyTime?: Date;
  tubeTech?: string;
  tubeWitness?: string;
  tubeTime?: Date;
  locationCanister?: string;
  cultureDishId?: string;
  groupCulture?: YesOrNo;
  embryoCultureDropWell?: string;
  pgtTissueType?: PGTTissueType[];
  freezeDate?: Date;
  tank?: string;
  beacon?: string;
  chromosmesAffected?: string;
  gender?: EmbryoGender;
  shippedDate?: Date;
  importedDate?: Date;
}

const fateOptions = Object.values(Fate);
const labratoryProtocolsOptions: MultiSelectOption[] = Object.values(
  LabratoryProtocol
).map((option) => ({
  value: option,
  label: option
}));

const pgtTissueTypeOptions: MultiSelectOption[] = Object.values(
  PGTTissueType
).map((option) => ({
  value: option,
  label: option
}));

const genderOptions = Object.values(EmbryoGender);
const maturityOptions = Object.values(Maturity);
const geneticStatusOptions = Object.values(GeneticStatus);
const inseminationMethodOptions = Object.values(InseminationMethods);
const day1Options = Object.values(Day1);

const EmbryologyTable: FC<Props> = ({ cycleToDisplay, patientId }: Props) => {
  const { t } = useTranslation();
  const { getEggsAndEmbryosReport, updateEgg } = useEggsEmbryos();
  const { mutateAsync: handleUpdateEgg } = updateEgg();

  const [editingCell, setEditingCell] = useState<{ type: string; id: string }>(
    null
  );

  const {
    data: eggAndEmbryoReport,
    isLoading: isEggAndEmbryoReportLoading,
    isFetching: isEggAndEmbryoReportFetching
  } = getEggsAndEmbryosReport(patientId, cycleToDisplay.id, {
    enabled: !!cycleToDisplay.id && !!patientId
  });

  const isLoading = isEggAndEmbryoReportLoading || isEggAndEmbryoReportFetching;

  const { upsertNotes } = useEggsEmbryos();

  const { mutateAsync: handleUpsertNotes } = upsertNotes();
  const { openToast } = useToast();

  const handleEditCell = async (
    rowType: TableRowType,
    newValue: string | number,
    eggId?: string
  ): Promise<void> => {
    if (
      [
        TableRowType.INSEMINATION_TIME,
        TableRowType.BIOPSY_TIME,
        TableRowType.TUBE_TIME
      ].includes(rowType)
    ) {
      // check if insemination time is in the future
      if (dayjs(newValue).isAfter(dayjs())) {
        openToast({
          title: t('TIME_CANNOT_BE_IN_THE_FUTURE'),
          type: ToastType.ERROR
        });
        return;
      }
    }
    const eggIdToUpdate = eggId || editingCell.id;
    await handleUpdateEgg({
      patientId,
      cycleId: cycleToDisplay.id,
      eggId: eggIdToUpdate,
      egg: { [rowType]: newValue }
    });
  };
  const [columns, rows] = useMemo(() => {
    const columns: GridColDef<TableRow>[] = [
      {
        field: 'no',
        headerName: t('NUMBER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false
      },
      {
        field: 'displayId',
        headerName: t('ID'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        valueGetter: (value: string) => value.toUpperCase()
      },
      {
        field: 'mature',
        headerName: t('MATURITY'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: maturityOptions
      },
      {
        field: 'cultureDishId',
        headerName: t('CULTURE_DISH_ID'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'groupCulture',
        headerName: t('GROUP_CULTURE'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: [
          { value: YesOrNo.YES, label: t('YES') },
          { value: YesOrNo.NO, label: t('NO') }
        ]
      },
      {
        field: 'embryoCultureDrop/Well',
        headerName: t('EMBRYO_CULTURE_DROP_WELL'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'inseminationMethod',
        headerName: t('INSEMINATION_METHOD'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: inseminationMethodOptions
      },
      {
        field: 'inseminationTime',
        headerName: t('INSEMINATION_TIME'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) =>
          value
            ? new Date(
                dayjs(value).format(`${getDateFormat()} ${getTimeFormat({})}`)
              )
            : null
      },
      {
        field: 'labratoryProtocols',
        headerName: t('LABRATORY_PROTOCOLS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: labratoryProtocolsOptions,
        width: 200,
        renderEditCell: ({ value, field, id }) => (
          <TableMultiSelect
            gridId={id}
            value={value || []}
            field={field}
            options={labratoryProtocolsOptions}
          />
        ),
        renderCell: ({ row }) => row.labratoryProtocols.join(', ')
      },
      {
        field: 'day1',
        headerName: t('DAY1'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: day1Options
      },
      {
        field: 'day2',
        headerName: t('DAY2'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'day3',
        headerName: t('DAY3'),
        align: 'center',
        sortable: false,
        editable: true
      },
      { field: 'day4', headerName: t('DAY4'), align: 'center', editable: true },
      { field: 'day5', headerName: t('DAY5'), align: 'center', editable: true },
      { field: 'day6', headerName: t('DAY6'), align: 'center', editable: true },
      { field: 'day7', headerName: t('DAY7'), align: 'center', editable: true },
      { field: 'aiScore', headerName: t('AI_SCORE'), editable: true },
      {
        field: 'biopsyTech',
        headerName: t('BIOPSY_TECH'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="biopsy-done-by-doctor-chips"
            value={[row.biopsyTech]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(
                TableRowType.BIOPSY_TECH,
                selectedDoctorId,
                row.id
              );
            }}
          />
        )
      },
      {
        field: 'biopsyWitness',
        headerName: t('BIOPSY_WITNESS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="biopsy-witness-done-by-doctor-chips"
            value={[row.biopsyWitness]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(
                TableRowType.BIOPSY_WITNESS,
                selectedDoctorId,
                row.id
              );
            }}
          />
        )
      },
      {
        field: 'biopsyTime',
        headerName: t('BIOPSY_TIME'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) =>
          value
            ? new Date(
                dayjs(value).format(`${getDateFormat()} ${getTimeFormat({})}`)
              )
            : null
      },
      {
        field: 'tubeTech',
        headerName: t('TUBING_TECH'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="tube-done-by-doctor-chips"
            value={[row.tubeTech]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(
                TableRowType.TUBE_TECH,
                selectedDoctorId,
                row.id
              );
            }}
          />
        )
      },
      {
        field: 'tubeWitness',
        headerName: t('TUBING_WITNESS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        renderCell: ({ row }) => (
          <DoctorChips
            id="tube-witness-done-by-doctor-chips"
            value={[row.tubeWitness]}
            showSelectedValue
            onAddChip={async (selectedDoctorId) => {
              await handleEditCell(
                TableRowType.TUBE_WITNESS,
                selectedDoctorId,
                row.id
              );
            }}
          />
        )
      },
      {
        field: 'tubeTime',
        headerName: t('TUBING_TIME'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) =>
          value
            ? new Date(
                dayjs(value).format(`${getDateFormat()} ${getTimeFormat({})}`)
              )
            : null
      },
      {
        field: 'pgtTubeId',
        headerName: t('PGT_TUBE_ID'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'pgtTissueType',
        headerName: t('PGT_TISSUE_TYPE'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: pgtTissueTypeOptions,
        width: 200,
        renderEditCell: ({ value, field, id }) => (
          <TableMultiSelect
            gridId={id}
            value={value || []}
            field={field}
            options={pgtTissueTypeOptions}
          />
        ),
        renderCell: ({ row }) => row.pgtTissueType.join(', ')
      },
      {
        field: 'fate',
        headerName: t('FATE'),
        editable: true,
        type: 'singleSelect',
        valueOptions: fateOptions
      },
      {
        field: 'freezeDate',
        headerName: t('FREEZE_DATE'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) =>
          value
            ? new Date(
                dayjs(value).format(`${getDateFormat()} ${getTimeFormat({})}`)
              )
            : null
      },
      {
        field: 'tank',
        headerName: t('TANK'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },

      {
        field: 'locationCanister',
        headerName: t('LOCATION_CANISTER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'beacon',
        headerName: t('BEACON'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'position',
        headerName: t('POSITION'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'straw',
        headerName: t('STRAW'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'geneticStatus',
        headerName: t('GENETIC_STATUS'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'singleSelect',
        valueOptions: geneticStatusOptions
      },
      {
        field: 'chromosmesAffected',
        headerName: t('CHROMOSOMES_AFFECTED'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'gender',
        headerName: t('GENDER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: 'singleSelect',
        editable: true,
        valueOptions: genderOptions
      },
      {
        field: 'notes',
        headerName: t('NOTES'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: false,
        renderCell: ({ row }) => {
          return (
            <Flex justifyContent="center" alignItems="center">
              <NotesWrapper
                date={dayjs().format(dashSeparatedDateFormat)}
                initialNotes={row.notes}
                onUpsertNotes={({
                  date,
                  cycleId,
                  patientId,
                  notesToCreate,
                  notesToDelete
                }) =>
                  handleUpsertNotes({
                    date,
                    cycleId,
                    patientId,
                    notesToCreate,
                    notesToDelete,
                    embryoId: row.id
                  })
                }
              />
            </Flex>
          );
        }
      },
      {
        field: 'tlid',
        headerName: t('TLID'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true
      },
      {
        field: 'wellNumber',
        headerName: t('WELL_NUMBER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'number'
      },
      {
        field: 'dishNumber',
        headerName: t('DISH_NUMBER'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'number'
      },
      {
        field: 'instrument',
        headerName: t('INSTRUMENT'),
        align: 'center',
        headerAlign: 'center',
        type: 'number',
        sortable: false,
        editable: true
      },
      {
        field: 'shippedDate',
        headerName: t('SHIPPED_DATE'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) =>
          value
            ? new Date(
                dayjs(value).format(`${getDateFormat()} ${getTimeFormat({})}`)
              )
            : null
      },
      {
        field: 'importedDate',
        headerName: t('IMPORTED_DATE'),
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        editable: true,
        type: 'dateTime',
        valueGetter: (value) =>
          value
            ? new Date(
                dayjs(value).format(`${getDateFormat()} ${getTimeFormat({})}`)
              )
            : null
      }
    ];
    const rows =
      eggAndEmbryoReport?.eggs?.map((egg, index) => {
        return {
          ...egg,
          biopsy: egg.biopsyTech || '',
          biopsyWitness: egg.biopsyWitness || '',
          no: index + 1,
          labratoryProtocols: egg.labratoryProtocols || [],
          tubeTech: egg.tubeTech || '',
          tubeWitness: egg.tubeWitness || '',
          straw: egg.strawNum,
          pgtTissueType: egg.pgtTissueType || []
        };
      }) || [];
    return [columns, rows];
  }, [eggAndEmbryoReport]);

  return (
    <Card sx={{ padding: spacings.x2large }}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        marginBottom={spacings.medium}
      >
        <Typography fontWeight={fontWeights.black}>
          {`${t(
            'CURRENT_TREATMENT'
          )} #${cycleToDisplay?.displayId?.toUpperCase()} ${t('LAB CHART')}`}
        </Typography>
      </Flex>
      <Box>
        <Table
          processRowUpdate={async (newRow, oldRow) => {
            if (newRow[editingCell.type] === oldRow[editingCell.type])
              return newRow;

            await handleEditCell(
              editingCell.type as TableRowType,
              newRow[editingCell.type]
            );
            return newRow;
          }}
          onCellEditStart={(props) => {
            setEditingCell({ id: `${props.id}`, type: props.field });
          }}
          editMode="cell"
          columns={columns}
          rows={rows || []}
          loading={isLoading}
          hideFooter
          disableColumnMenu
          paginationModel={{ page: 0, pageSize: 50 }}
        />
      </Box>
    </Card>
  );
};

export default EmbryologyTable;
