import { FC, useEffect, useMemo, useState } from 'react';
import { styled, css } from '@mui/material';

import {
  AppointmentStatusType,
  AppointmentTypeCategory,
  Appointment,
  AppointmentTypes,
  ThirdPartyAppointmentStatusType,
  CancelledAppointmentStatusType,
  EggAndEmbryoTransferAppointmentStatus,
  CycleMonitoringAppointmentStatus,
  DiagnosticAppointmentAppointmentStatus,
  FollowupAppointmentStatus,
  InitialConsultationAppointmentStatus,
  AnyUltrasoundAppointmentStatus,
  AllCounselingEducationAppointmentStatus,
  UrgentConsultAppointmentStatus,
  PregnancyStatus,
  ConsultationStatus,
  IUIAppointmentStatus,
  EggAndEmbryoTransferAppointmentStatusKeys,
  CycleMonitoringAppointmentStatusKeys,
  DiagnosticAppointmentAppointmentStatusKeys,
  FollowupAppointmentStatusKeys,
  InitialConsultationAppointmentStatusKeys,
  AnyUltrasoundAppointmentStatusKeys,
  AllCounselingEducationAppointmentStatusKeys,
  ThirdPartyAppointmentStatusTypeEntries,
  UrgentConsultAppointmentStatusKeys,
  ConsultationStatusKeys,
  PregnancyStatusKeys,
  IUIAppointmentStatusKeys,
  MaleProceduresAppointmentStatus,
  MaleProceduresAppointmentStatusKeys,
  FinancialAppointmentStatus,
  FinancialAppointmentStatusKeys,
  SpermPrepAppointmentStatus,
  SpermPrepAppointmentStatusKeys,
  SurgeryAppointmentStatus,
  SurgeryAppointmentStatusKeys
} from 'src/types/appointment';
import i18n from 'src/i18n/i18n';
import useAppointments from 'src/hooks/useAppointments';
import { usePopover } from 'src/contexts/UIContexts';
import Flex, { FlexProps } from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import { Colors } from 'src/components/styles/colors';
import { fontWeights } from 'src/components/styles/fonts';
import { makeShouldForwardProps } from 'src/components/utils';
import Menu, {
  MenuItemProps,
  MenuTheme
} from '../../../components/display/Menu/Menu';
import { getAppointmentCategory } from './utils';

interface StyledAppointmentStatusFlexProps extends FlexProps {
  status?: AppointmentStatusType;
  isLoading?: boolean;
}

const shouldForwardProp = makeShouldForwardProps(['status', 'isLoading']);

const StyledAppointmentStatusFlex: FC<StyledAppointmentStatusFlexProps> = styled(
  Flex,
  { shouldForwardProp }
)<StyledAppointmentStatusFlexProps>`
  ${({ status }) => {
    if (status === ThirdPartyAppointmentStatusType.RESULTS_AVAILABLE) {
      return `
      background-color: ${Colors.mauvelous};
      color: ${Colors.emperor};`;
    }
  }}

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
    `}

  ${({ status }) =>
    status !== CancelledAppointmentStatusType.CANCELLED &&
    css`
      cursor: pointer;
    `}
`;

const eggRetrievalOptions = EggAndEmbryoTransferAppointmentStatusKeys.map(
  (key) => ({
    label: i18n.t(key),
    value: EggAndEmbryoTransferAppointmentStatus[key]
  })
);

const monitoringOptions = CycleMonitoringAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: CycleMonitoringAppointmentStatus[key]
}));

const diagnosticOptions = DiagnosticAppointmentAppointmentStatusKeys.map(
  (key) => ({
    label: i18n.t(key),
    value: DiagnosticAppointmentAppointmentStatus[key]
  })
);

const followupOptions = FollowupAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: FollowupAppointmentStatus[key]
}));

const initialConsultationOptions = InitialConsultationAppointmentStatusKeys.map(
  (key) => ({
    label: i18n.t(key),
    value: InitialConsultationAppointmentStatus[key]
  })
);

const anyUltrasoundOptions = AnyUltrasoundAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: AnyUltrasoundAppointmentStatus[key]
}));

const allCounselingEducationOptions =
  AllCounselingEducationAppointmentStatusKeys.map((key) => ({
    label: i18n.t(key),
    value: AllCounselingEducationAppointmentStatus[key]
  }));

const thirdPartyOptions = ThirdPartyAppointmentStatusTypeEntries.map(
  ([label, value]) => ({
    label: i18n.t(label),
    value
  })
);

const urgentConsultOptions = UrgentConsultAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: UrgentConsultAppointmentStatus[key]
}));

const embryoTransferOptions = EggAndEmbryoTransferAppointmentStatusKeys.map(
  (key) => ({
    label: i18n.t(key),
    value: EggAndEmbryoTransferAppointmentStatus[key]
  })
);

const consultationOptions = ConsultationStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: ConsultationStatus[key]
}));

const pregnancyOptions = PregnancyStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: PregnancyStatus[key]
}));

const iuiOptions = IUIAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: IUIAppointmentStatus[key]
}));

const spermPerpOptions = SpermPrepAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: SpermPrepAppointmentStatus[key]
}));

const maleProceduresOptions = MaleProceduresAppointmentStatusKeys.map(
  (key) => ({
    label: i18n.t(key),
    value: MaleProceduresAppointmentStatus[key]
  })
);
const surgeryOptions = SurgeryAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: SurgeryAppointmentStatus[key]
}));
const financialOptions = FinancialAppointmentStatusKeys.map((key) => ({
  label: i18n.t(key),
  value: FinancialAppointmentStatus[key]
}));

const appointmentStatusOptionsMap = {
  [AppointmentTypeCategory.EGG_RETRIEVAL]: eggRetrievalOptions,
  [AppointmentTypeCategory.MONITORING]: monitoringOptions,
  [AppointmentTypeCategory.EXTERNAL_MONITORING]: monitoringOptions,
  [AppointmentTypeCategory.DIAGNOSTIC_APPOINTMENT]: diagnosticOptions,
  [AppointmentTypeCategory.FOLLOWUP]: followupOptions,
  [AppointmentTypeCategory.INITIAL_CONSULTATION]: initialConsultationOptions,
  [AppointmentTypeCategory.ULTRASOUND]: anyUltrasoundOptions,
  [AppointmentTypeCategory.ALL_COUNSELING_EDUCATION]:
    allCounselingEducationOptions,
  [AppointmentTypeCategory.THIRD_PARTY]: thirdPartyOptions,
  [AppointmentTypeCategory.URGENT_CONSULT]: urgentConsultOptions,
  [AppointmentTypeCategory.TRANSFER]: embryoTransferOptions,
  [AppointmentTypeCategory.CONSULTATION]: consultationOptions,
  [AppointmentTypeCategory.PREGNANCY]: pregnancyOptions,
  [AppointmentTypeCategory.IUI]: iuiOptions,
  [AppointmentTypeCategory.EMBRYO_TRANSFER]: embryoTransferOptions,
  [AppointmentTypeCategory.SPERM_PREP]: spermPerpOptions,
  [AppointmentTypeCategory.MALE_PROCEDURES]: maleProceduresOptions,
  [AppointmentTypeCategory.SURGERY]: surgeryOptions,
  [AppointmentTypeCategory.FINANCIAL]: financialOptions
};

const getStatusesOptions = (
  type: AppointmentTypes
): { label: string; value: AppointmentStatusType }[] => {
  const appointmentTypeCategory = getAppointmentCategory(type);
  return appointmentStatusOptionsMap[appointmentTypeCategory] || [];
};

const AppointmentStatusMenu: FC<{
  statuses: { label: string; value: AppointmentStatusType }[];
  onSelectStatus: (status: AppointmentStatusType) => void;
}> = ({ statuses, onSelectStatus }) => {
  const menuItems = useMemo<MenuItemProps[]>(
    () =>
      statuses.map(({ label, value }) => ({
        label,
        action: () => onSelectStatus(value),
        justifyContent: 'center'
      })),
    [statuses, onSelectStatus]
  );
  return <Menu items={menuItems} menuTheme={MenuTheme.Dark} />;
};

export const AppointmentStatusSelect: FC<{ appointment: Appointment }> = ({
  appointment
}) => {
  const { openPopover, closePopover } = usePopover();
  const [selectedStatus, setSelectedStatus] =
    useState<AppointmentStatusType | null>(appointment.status);
  const { updateAppointmentStatus } = useAppointments();
  const {
    mutate: updateStatus,
    isLoading: isLoadingUpdateStatus,
    isError
  } = updateAppointmentStatus();

  const handleUpdateStatusClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
    selectedStatus: AppointmentStatusType
  ) => {
    if (selectedStatus === CancelledAppointmentStatusType.CANCELLED) {
      return;
    }

    openPopover({
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      transformOrigin: {
        horizontal: 'left',
        vertical: 'top'
      },
      children: (
        <AppointmentStatusMenu
          statuses={getStatusesOptions(appointment.appointmentType)}
          onSelectStatus={async (statusToUpdate) => {
            if (statusToUpdate !== selectedStatus) {
              setSelectedStatus(statusToUpdate);
              await updateStatus({
                appointmentId: appointment.id,
                updatedAppointment: {
                  ...appointment,
                  status: statusToUpdate,
                  patient: appointment?.patient
                }
              });
            }
            closePopover();
          }}
        />
      ),
      anchorEl: ev.currentTarget,
      hideToolbar: true
    });
  };

  useEffect(() => {
    if (isError) {
      setSelectedStatus(appointment.status);
    }
  }, [isError]);

  return (
    <StyledAppointmentStatusFlex
      isLoading={isLoadingUpdateStatus}
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      bgcolor={Colors.emperor}
      color={Colors.white}
      status={selectedStatus}
      onClick={(ev) => handleUpdateStatusClick(ev, selectedStatus)}
    >
      <Typography color={Colors.white} fontWeight={fontWeights.extraBold}>
        {selectedStatus}
      </Typography>
    </StyledAppointmentStatusFlex>
  );
};
