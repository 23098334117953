import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import { Colors } from 'src/components/styles/colors';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import { EditIdentificationInfo } from './EditIdentificationInfo';
import { styled } from '@mui/material';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { YesOrNo } from 'src/types/global';
import { useDialog } from 'src/contexts/UIContexts';

const StyledEditIcon = styled(EditIcon)`
  fill: ${Colors.black};
`;

const RowContainer: FC<{
  title: string;
  value: string | boolean;
  valueElementId?: string;
}> = ({ title, value, valueElementId }) => {
  const valueTypographyProps: { id?: string } = {};

  if (valueElementId) {
    valueTypographyProps.id = valueElementId;
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Typography>{title}</Typography>
      <Typography {...valueTypographyProps}>{value}</Typography>
    </Flex>
  );
};

export const PatientIdentificationCard: FC<{
  patientId: string;
}> = ({ patientId }) => {
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const { getPatientById } = usePatientsApi();

  const { data: patient } = getPatientById(patientId);

  const { idNumber, idType, countryOfBirth, isIdConfirmed } =
    patient?.personalInfo || {};

  return (
    <Flex flexDirection="column">
      <Flex flex={1} justifyContent="space-between" alignItems="center">
        <Typography fontWeight={fontWeights.extraBold}>
          {t('IDENTIFICATION').toUpperCase()}
        </Typography>
        <MiniIconButton
          icon={
            <StyledEditIcon
              onClick={() =>
                openDialog({
                  header: t('EDIT_IDENTIFICATION_INFO'),
                  children: (
                    <EditIdentificationInfo
                      patientId={patient?.personalInfo?.id}
                    />
                  )
                })
              }
            />
          }
        />
      </Flex>
      <Box
        borderBottom={`1px solid ${Colors.alto2}`}
        width="100%"
        marginY={spacings.medium}
      />
      <Flex flexDirection="column" gap={spacings.small}>
        <RowContainer
          valueElementId={`patient-identification-id-number-${idNumber}`}
          title={t('ID_NUMBER')}
          value={idNumber}
        />
        <RowContainer title={t('ID_TYPE')} value={idType} />
        <RowContainer title={t('BIRTH_COUNTRY')} value={countryOfBirth} />
        <RowContainer
          title={t('ID_CONFIRMED_BY_CLINIC_STAFF')}
          value={isIdConfirmed ? t(YesOrNo.YES) : t(YesOrNo.NO)}
        />
      </Flex>
    </Flex>
  );
};
