import { AppError } from './../types/global';
import { UseQueryOptions, useQuery } from 'react-query';
import {
  getConstValuesRequest,
  getExaminationIdRequest,
  getOrdersVendors,
  getPatientData
} from '../api/general.api';
import { useTranslation } from 'react-i18next';
import { Examination } from 'src/types/exam';
import { OrderVendor } from 'src/types/hl7messages';
import { queryKeys, querySubKeys } from './queryKeys';
import { useToast } from 'src/contexts/UIContexts';

function useGeneralApi() {
  const { t } = useTranslation();
  const { handleQueryResultToast } = useToast();

  return {
    getConstValues: (
      options?: UseQueryOptions<Record<string, any>, AppError>
    ) =>
      useQuery<Record<string, any>, AppError>(
        [queryKeys.CONSTANTS],
        () => getConstValuesRequest(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CONST_VALUES')
            }),
          ...options
        }
      ),
    getExaminationById: (
      examinationId: string,
      options?: UseQueryOptions<Examination, AppError>
    ) =>
      useQuery<Examination, AppError>(
        [queryKeys.EXAMINATIONS, examinationId],
        () => getExaminationIdRequest(examinationId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_EXAMINATION')
            }),
          enabled: !!examinationId,
          ...options
        }
      ),
    getOrdersVendors: (options?: UseQueryOptions<OrderVendor[], AppError>) =>
      useQuery<OrderVendor[], AppError>(
        [queryKeys.ORDER_VENDORS],
        () => getOrdersVendors(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_HL7_VENDORS')
            }),
          ...options
        }
      ),
    getPatientData: (
      patientId: string | undefined,
      options?: UseQueryOptions<Blob, AppError>
    ) =>
      useQuery<Blob, AppError>(
        [
          queryKeys.CLINICS,
          querySubKeys[queryKeys.CLINICS].EXPORT_PATIENT_DATA,
          patientId
        ],
        () => getPatientData(patientId),
        {
          enabled: false, // Prevents the query from running automatically as it is triggered by the user
          ...options
        }
      )
  };
}

export default useGeneralApi;
