import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from 'react-query';
import { useTranslation } from 'react-i18next';

import { ToastType } from '../components/display/Toast/Toast';
import { AppError } from '../types/global';
import { InsurancePayer, PatientInsurance } from '../types/patientInsurance';
import {
  createPatientInsuranceRequest,
  getInsurancesByPatientIdRequest,
  updatePatientInsuranceRequest,
  getInsuranceRequest,
  updatePatientIsUninsuredRequest,
  getInsurancePayersRequest
} from '../api/patientsInsurances.api';
import { queryKeys, querySubKeys } from './queryKeys';
import { Patient } from '../types/patient';
import { useToast } from 'src/contexts/UIContexts';

function usePatientsInsurancesApi() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openToast, handleQueryResultToast } = useToast();

  return {
    getInsurancePayers: (
      patientId: string,
      options?: UseQueryOptions<InsurancePayer[], AppError>
    ) =>
      useQuery<InsurancePayer[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES,
          querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES_INSURANCE_PAYERS
        ],
        () => getInsurancePayersRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_INSURANCE_PAYERS')
            }),
          ...options
        }
      ),
    getPatientInsurances: (
      patientId: string,
      options?: UseQueryOptions<PatientInsurance[], AppError>
    ) =>
      useQuery<PatientInsurance[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES
        ],
        () => getInsurancesByPatientIdRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_INSURANCES')
            }),
          enabled: !!patientId,
          ...options
        }
      ),
    getPatientInsurance: (
      patientId: string,
      id?: string,
      options?: UseQueryOptions<PatientInsurance, AppError>
    ) =>
      useQuery<PatientInsurance, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES,
          id
        ],
        () => getInsuranceRequest(patientId, id),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_INSURANCE')
            }),
          enabled: !!patientId && !!id,
          ...options
        }
      ),
    createPatientInsurance: () =>
      useMutation<string, AppError, PatientInsurance>(
        createPatientInsuranceRequest,
        {
          onSettled: (data, error) => {
            return handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_PATIENT_INSURANCE')
            });
          },
          onSuccess: (patientId) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES
            ]);
            openToast({
              title: t('CREATE_INSURANCE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    updatePatientInsurance: () =>
      useMutation<string, AppError, Partial<PatientInsurance>>(
        updatePatientInsuranceRequest,
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_INSURANCE')
            }),

          onSuccess: (_, { patientId, id }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES,
              id
            ]);
            openToast({
              title: t('UPDATE_INSURANCE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      ),
    updatePatientIsUninsured: () =>
      useMutation<
        Patient,
        AppError,
        { patientId: string; isUninsured: boolean }
      >(
        ({ patientId, isUninsured }) => {
          return updatePatientIsUninsuredRequest({ patientId, isUninsured });
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_PATIENT_INSURANCE')
            }),

          onSuccess: (_, { patientId }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES,
              querySubKeys[queryKeys.PATIENTS].PATIENT_INSURANCES_MARK_UNINSURED
            ]);
            openToast({
              title: t('UPDATE_INSURANCE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
          }
        }
      )
  };
}

export default usePatientsInsurancesApi;
