import { FC, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import { spacings } from 'src/components/styles/constants';
import { DashboardScheduleCard } from './DashboardScheduleCard';
import { DashboardPatientsList } from './DashboardPatientsList';
import { AppointmentsSummary } from './DashboardSummary';
import dayjs from 'dayjs';
import Card from '../../../components/display/Card';
import { TasksSummary } from './TasksSummary';
import Typography from '../../../components/display/Typography';
import { fontWeights } from '../../../components/styles/fonts';
import usePatientsApi from 'src/hooks/usePatientsApi';

export const Dashboard: FC = () => {
  const [date, setDate] = useState(dayjs());
  const [startOfWeek, endOfWeek] = useMemo(
    () => [date.startOf('week'), date.endOf('week')],
    [date]
  );

  const { t } = useTranslation();
  const { getDashboardSummary } = usePatientsApi();

  const {
    data: summary,
    isLoading,
    isFetching: _isFetching // Explicitly unused
  } = getDashboardSummary(
    {
      minDate: startOfWeek,
      maxDate: endOfWeek
    },
    { refetchInterval: 10000 }
  );

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD_PAGE_TITLE')}</title>
      </Helmet>
      <Box marginBottom={spacings.large}>
        <Typography
          fontWeight={fontWeights.extraBold}
          variant="h1"
          marginBottom={spacings.large}
        >
          {t('DASHBOARD')}
        </Typography>
      </Box>
      <Flex
        sx={{
          flexDirection: {
            lg: 'row',
            sm: 'column'
          }
        }}
        gap={spacings.xlarge}
      >
        <Card
          sx={{
            display: {
              xs: 'block',
              lg: 'none'
            }
          }}
          shadow
          padding={spacings.xlarge}
        >
          <TasksSummary summary={summary} isLoading={isLoading} />
        </Card>
        <Card padding={spacings.xlarge} flex={2}>
          <AppointmentsSummary
            summary={summary}
            isLoading={isLoading}
            date={date}
            setDate={setDate}
          />
          <DashboardPatientsList date={date} />
        </Card>
        <Box flex={1}>
          <Card
            sx={{
              display: {
                xs: 'none',
                lg: 'block'
              }
            }}
            shadow
            padding={spacings.xlarge}
            marginBottom={spacings.large}
          >
            <TasksSummary summary={summary} isLoading={isLoading} />
          </Card>
          <DashboardScheduleCard date={date} setDate={setDate} />
        </Box>
      </Flex>
    </>
  );
};
