import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { spacings } from 'src/components/styles/constants';
import IconButton from 'src/components/display/IconButton/IconButton';
import { Colors } from 'src/components/styles/colors';
import Typography from 'src/components/display/Typography/Typography';
import { fontWeights } from 'src/components/styles/fonts';
import NumberPreview from 'src/components/display/NumberPreview/NumberPreview';
import { NumberPreviewSize } from 'src/components/display/NumberPreview/types';
import { DashboardSummary } from 'src/types/patient';
import { dashSeparatedDateFormat, getDateFormat } from 'src/utils/dateAndTIme';
import { AppointmentTypes } from '../../../types/appointment';

export enum SummaryCategory {
  RETRIEVAL = 'Retrieval',
  IUI = 'IUI',
  MONITORING = 'Monitoring',
  TRANSFER = 'Transfer',
  DIAGNOSTIC = 'Diagnostic',
  CONSULTATION = 'Consultation',
  ULTRASOUND = 'Ultrasound'
}

export enum SummaryCategoryDefault {
  SHOW_ALL_APPOINTMENTS = 'Show all appointments'
}

const defaultSummary = {
  [SummaryCategory.CONSULTATION]: 0,
  [SummaryCategory.DIAGNOSTIC]: 0,
  [SummaryCategory.RETRIEVAL]: 0,
  [SummaryCategory.IUI]: 0,
  [SummaryCategory.MONITORING]: 0,
  [SummaryCategory.TRANSFER]: 0,
  [SummaryCategory.ULTRASOUND]: 0
};

interface SummaryNumbersProps {
  isLoading: boolean;
  appointmentsCountByCategory: Partial<Record<AppointmentTypes, number>>;
}

const SummaryNumbers: FC<SummaryNumbersProps> = ({
  isLoading,
  appointmentsCountByCategory
}) => {
  const { t } = useTranslation();
  return isLoading ? (
    <Box margin="auto">
      <Loader />
    </Box>
  ) : (
    <Flex justifyContent="center">
      {Object.entries(appointmentsCountByCategory).map(
        ([category, count], index) => (
          <NumberPreview
            key={`${category}-${index}`}
            id={`dashboard-number-${category.toLowerCase()}-${index}`}
            size={NumberPreviewSize.large}
            value={count}
            bottomText={t(category)}
          />
        )
      )}
    </Flex>
  );
};

export const AppointmentsSummary: FC<{
  date: Dayjs;
  setDate: (date: Dayjs) => void;
  summary: DashboardSummary;
  isLoading: boolean;
}> = ({ date, setDate, summary, isLoading }) => {
  const { appointments } = summary || {};

  const { t } = useTranslation();

  const appointmentsCountByCategory =
    appointments?.[date?.format(dashSeparatedDateFormat)] || defaultSummary;

  return (
    <Box>
      <Flex
        flex={7}
        gap={spacings.small}
        alignItems="center"
        marginBottom={spacings.large}
        justifyContent="space-between"
      >
        <Flex marginRight={spacings.medium}>
          <IconButton
            icon={<ArrowBackIcon fill={Colors.emperor} />}
            bgColor="cupid"
            disabled={isLoading}
            onClick={() => {
              setDate(date.subtract(1, 'day'));
            }}
          />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems={{ xs: 'center', lg: 'flex-start' }}
          alignSelf="center"
          gap={spacings.medium}
        >
          <Flex
            flexDirection={{ xs: 'row', lg: 'column' }}
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            gap={spacings.small}
          >
            <Typography variant="h2" fontWeight={fontWeights.extraBold}>
              {t('PATIENT')} {t('STATUS')}
            </Typography>
          </Flex>
          <Typography variant="h3">{date.format(getDateFormat())}</Typography>
        </Flex>
        <Flex
          flex={8}
          sx={{
            display: {
              xs: 'none',
              lg: 'flex'
            }
          }}
          justifyContent="flex-end"
        >
          <SummaryNumbers
            isLoading={isLoading}
            appointmentsCountByCategory={appointmentsCountByCategory}
          />
        </Flex>

        <Box marginLeft={spacings.small}>
          <IconButton
            icon={<ArrowForwardIcon fill={Colors.emperor} />}
            bgColor="cupid"
            id="dashboard-summary-next-day-button"
            disabled={isLoading}
            onClick={() => {
              setDate(date.add(1, 'day'));
            }}
          />
        </Box>
      </Flex>
      <Flex
        flex={8}
        sx={{
          display: {
            xs: 'flex',
            lg: 'none'
          }
        }}
        justifyContent="center"
        marginBlock={spacings.large}
      >
        <SummaryNumbers
          isLoading={isLoading}
          appointmentsCountByCategory={appointmentsCountByCategory}
        />
      </Flex>
    </Box>
  );
};
