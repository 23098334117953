import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { ToastType } from 'src/components/display/Toast/Toast';
import { AppError } from 'src/types/global';
import {
  BillingProfile,
  BillingStats,
  Invoice,
  InvoiceMethod,
  InvoiceStatus,
  StatusesOptions
} from '../types/billing';
import {
  createInvoiceRequest,
  getBillingProfileRequest,
  getBillingStatsRequest,
  getInvoiceByIdRequest,
  getInvoicesRequest,
  updateInvoiceMethodRequest,
  updateInvoiceRequest,
  updateInvoiceStatusRequest
} from '../api/billing.api';
import { queryKeys, querySubKeys } from './queryKeys';
import { useToast } from 'src/contexts/UIContexts';

function useBilling() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return {
    getBillingStats: (options?: UseQueryOptions<BillingStats, AppError>) =>
      useQuery<BillingStats, AppError>(
        [queryKeys.BILLING_STATS],
        () => getBillingStatsRequest(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_BILLING_STATS')
            }),
          ...options
        }
      ),
    getInvoices: (
      status?: StatusesOptions,
      patientId?: string,
      options?: UseQueryOptions<Invoice[], AppError>
    ) =>
      useQuery<Invoice[], AppError>(
        [
          queryKeys.BILLING,
          patientId,
          querySubKeys[queryKeys.BILLING].INVOICES,
          status
        ],
        () => getInvoicesRequest(status, patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_INVOICES')
            }),
          ...options
        }
      ),
    getInvoiceById: (
      invoiceId: string,
      options?: UseQueryOptions<Invoice, AppError>
    ) =>
      useQuery<Invoice, AppError>(
        [
          queryKeys.BILLING,
          querySubKeys[queryKeys.BILLING].INVOICES,
          invoiceId
        ],
        async () => getInvoiceByIdRequest(invoiceId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_INVOICE')
            }),
          ...options
        }
      ),
    getBillingProfile: (
      patientId: string,
      options?: UseQueryOptions<BillingProfile, AppError>
    ) =>
      useQuery<BillingProfile, AppError>(
        [queryKeys.BILLING, patientId, querySubKeys[queryKeys.BILLING].PROFILE],
        () => getBillingProfileRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_BILLING_PROFILE')
            }),
          ...options
        }
      ),
    createInvoice: () =>
      useMutation<string, AppError, Invoice>(
        (invoice) => {
          return createInvoiceRequest(invoice);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_INVOICE')
            }),
          onSuccess: () => {
            openToast({
              title: t('CREATE_INVOICE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.BILLING_STATS]);
            queryClient.invalidateQueries([queryKeys.BILLING]);
          }
        }
      ),
    updateInvoice: () =>
      useMutation<string, AppError, Invoice>(
        (invoice) => {
          return updateInvoiceRequest(invoice);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_INVOICE')
            }),
          onSuccess: () => {
            openToast({
              title: t('UPDATE_INVOICE_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.BILLING_STATS]);
            queryClient.invalidateQueries([queryKeys.BILLING]);
          }
        }
      ),
    updateInvoiceStatus: () =>
      useMutation<string, AppError, { id: string; status: InvoiceStatus }>(
        ({ id, status }) => {
          return updateInvoiceStatusRequest(id, status);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_INVOICE_STATUS')
            }),
          onSuccess: () => {
            openToast({
              title: t('UPDATE_INVOICE_STATUS_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.BILLING_STATS]);
            queryClient.invalidateQueries([queryKeys.BILLING]);
          }
        }
      ),
    updateInvoiceMethod: () =>
      useMutation<string, AppError, { id: string; method: InvoiceMethod }>(
        ({ id, method }) => {
          return updateInvoiceMethodRequest(id, method);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_INVOICE_METHOD')
            }),
          onSuccess: () => {
            openToast({
              title: t('UPDATE_INVOICE_METHOD_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.BILLING_STATS]);
            queryClient.invalidateQueries([queryKeys.BILLING]);
          }
        }
      )
  };
}

export default useBilling;
