import { css, styled } from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import Flex from 'src/components/layout/Flex';
import {
  messengerPopoverHeight,
  zIndices
} from 'src/components/styles/constants';
import { ChannelTypes } from 'src/types/inbox';
import {
  useMessenger,
  useTelehealth
} from 'src/contexts/AppContexts/AppContexts';
import ApplicationsMessenger from '../messenger';
import Telehealth from '../telehealth';

export const StyledMessengerContainer = styled(Flex)`
  position: fixed;
  right: 20px;
  bottom: 5px;
  z-index: ${zIndices.high};
  height: fit-content;
  min-height: ${messengerPopoverHeight};
  ${({ theme }) => ({
    [theme.breakpoints.down('lg')]: css`
      width: 50dvw;
    `
  })}
`;

const PatientRelatedInstances: FC = () => {
  const { isMessengerOpen, setIsMessengerOpen } = useMessenger();
  const { isTelehealthOpen, setIsTelehealthOpen } = useTelehealth();

  const { patientId } = useParams();
  return (
    <>
      {isMessengerOpen && (
        <StyledMessengerContainer>
          <ApplicationsMessenger
            isPopover
            chatType={ChannelTypes.Patient}
            onClose={() => setIsMessengerOpen(false)}
            showExpandButton
            patientId={patientId}
          />
        </StyledMessengerContainer>
      )}
      {isTelehealthOpen && (
        <StyledMessengerContainer>
          <Telehealth
            patientId={patientId}
            onClose={() => setIsTelehealthOpen(false)}
          />
        </StyledMessengerContainer>
      )}
    </>
  );
};

export default PatientRelatedInstances;
