import { useTranslation } from 'react-i18next';
import Typography from 'src/components/display/Typography';
import Box from 'src/components/layout/Box';
import { StaffMember } from 'src/types/staff';
import useStaffMembers from 'src/hooks/useStaffMembers';
import useMeApi from 'src/hooks/useMeApi';
import { StyledFlexContainer } from 'src/modules/patients/actionMenu/PatientActionsMenu';
import { usePopover } from 'src/contexts/UIContexts';

interface Props {
  staff: StaffMember;
}
const ActiveMenu = ({ staff }: Props) => {
  const { t } = useTranslation();
  const { getMe } = useMeApi();
  const { data: me } = getMe();
  const { updateStaffmember } = useStaffMembers();
  const { mutate: updateStaffmemberMutate } = updateStaffmember();
  const { closePopover } = usePopover();

  const handleChangeActive = (isActive: boolean) => {
    updateStaffmemberMutate({
      staffId: staff.id,
      staff: { isActive: isActive, clinicId: me?.user?.clinicId }
    });
    closePopover();
  };
  return (
    <Box width="250px">
      <StyledFlexContainer onClick={() => handleChangeActive(true)}>
        <Typography>{t('SELECT_ACTIVE')}</Typography>
      </StyledFlexContainer>
      <StyledFlexContainer onClick={() => handleChangeActive(false)}>
        <Typography>{t('SELECT_INACTIVE')}</Typography>
      </StyledFlexContainer>
    </Box>
  );
};

export default ActiveMenu;
