import { FC } from 'react';
import { Link, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '../display/Typography';
import {
  StyledErrorPageContainer,
  StyledErrorPageInnerContainer,
  StyledErrorPageSubText,
  StyledErrorPageTextContainer
} from '../errorBoundary';
import Center from '../layout/Center';
import Flex from '../layout/Flex';
import { Colors } from '../styles/colors';
import { spacings } from '../styles/constants';
import { fonts } from '../styles/fonts';
import { ReactComponent as Embie } from '../../assets/icons/embie.svg';
import { supportEmailAddress } from 'src/utils/general';
import Button from '../display/Button';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCriticalAppError } from 'src/contexts/AppContexts/AppContexts';

const StyledBigErrorNumber = styled(Typography)`
  font: ${fonts.bigErrorNumber};
  color: ${Colors.emperor};
`;

const StyledEmbie = styled(Embie)`
  color: ${Colors.cupid};
  height: 225px;
  width: 225px;
`;

const StyledBackToDashboardButton = styled(Button)`
  margin-top: ${spacings.large};
`;

export const NotFoundErrorPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetCriticalAppErrorContext } = useCriticalAppError();
  return (
    <StyledErrorPageContainer>
      <StyledErrorPageInnerContainer width="60%" justifyContent="center">
        <Flex flexDirection="column" alignItems="center">
          {/* 404 but fancy */}
          <Center gap={spacings.medium}>
            <StyledBigErrorNumber>4</StyledBigErrorNumber>
            <StyledEmbie />
            <StyledBigErrorNumber>4</StyledBigErrorNumber>
          </Center>
          <StyledErrorPageTextContainer>
            {t('THIS_PAGE_DOESNT_EXIST')}
            <StyledErrorPageSubText>
              {t('PLEASE_CONTACT_SUPPORT') + ' '}
              <Link
                href={`mailto:${supportEmailAddress}`}
                color={Colors.emperor}
                fontSize={24}
              >
                {supportEmailAddress}
              </Link>
            </StyledErrorPageSubText>
          </StyledErrorPageTextContainer>

          <StyledBackToDashboardButton
            onClick={() => {
              navigate('/');
              resetCriticalAppErrorContext();
            }}
          >
            <Flex gap={spacings.medium} alignItems="center">
              <ArrowBack />
              {t('BACK_TO_DASHBOARD')}
            </Flex>
          </StyledBackToDashboardButton>
        </Flex>
      </StyledErrorPageInnerContainer>
    </StyledErrorPageContainer>
  );
};
