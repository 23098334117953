import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode
} from 'react';
import { SnackbarProvider } from 'notistack';
import { PopoverProps } from 'src/components/display/Popover/Popover';
import { DrawerProps } from 'src/components/display/Drawer/Drawer';
import { DialogProps } from 'src/components/display/Dialog/Dialog';
import { ToastProps, ToastType } from 'src/components/display/Toast/Toast';

type UIContextValue = {
  // Toast
  openToast: (params: ToastProps) => void;
  closeToast: () => void;
  handleQueryResultToast: (params: {
    data?: unknown;
    error?: unknown;
    actionName: string;
  }) => void;
  toastOpen: boolean;
  toast: ToastProps;
  // Popover
  openPopover: (props: Omit<PopoverProps, 'open'>) => void;
  closePopover: () => void;
  closeAllPopovers: () => void;
  popovers: PopoverProps[];
  // Drawer
  openDrawer: (props: Omit<DrawerProps, 'open'>) => void;
  closeDrawer: () => void;
  closeAllDrawers: () => void;
  drawers: DrawerProps[];
  // Dialog
  openDialog: (props: Omit<DialogProps, 'open'>) => void;
  closeDialog: () => void;
  closeAllDialogs: () => void;
  dialogs: DialogProps[];
};

const UIContext = createContext<UIContextValue>(null);

export const UIProvider = ({ children }: { children: ReactNode }) => {
  // Toast
  const [toastOpen, setToastOpen] = useState(false);
  const [toast, setToast] = useState<ToastProps>({
    type: ToastType.INFO,
    title: '',
    children: ''
  });
  const openToast = ({ type: toastType, title, children }: ToastProps) => {
    setToast({ type: toastType, title, children });
    setToastOpen(true);
  };
  const closeToast = () => setToastOpen(false);
  const handleQueryResultToast = ({
    error,
    actionName
  }: {
    data?: unknown;
    error?: unknown;
    actionName: string;
  }) => {
    if (!error) return;
    openToast({
      type: ToastType.ERROR,
      title: `Error: ${actionName}`,
      // @ts-ignore
      children: error?.response?.data?.message || 'An error occurred'
    });
  };

  // Popover
  const [popovers, setPopovers] = useState<PopoverProps[]>([]);
  const openPopover = (props: Omit<PopoverProps, 'open'>) =>
    setPopovers((prev) => [
      ...prev,
      { ...props, open: true, disableScrollLock: true }
    ]);
  const closePopover = () => setPopovers((prev) => prev.slice(0, -1));
  const closeAllPopovers = () => setPopovers([]);

  // Drawer
  const [drawers, setDrawers] = useState<DrawerProps[]>([]);
  const openDrawer = (props: Omit<DrawerProps, 'open'>) =>
    setDrawers((prev) => [
      ...prev,
      { ...props, open: true, disableScrollLock: true }
    ]);
  const closeDrawer = () => setDrawers((prev) => prev.slice(0, -1));
  const closeAllDrawers = () => setDrawers([]);
  useEffect(() => {
    if (drawers.length > 0) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [drawers]);

  // Dialog
  const [dialogs, setDialogs] = useState<DialogProps[]>([]);
  const openDialog = (props: Omit<DialogProps, 'open'>) =>
    setDialogs((prev) => [...prev, { ...props, open: true }]);
  const closeDialog = () => setDialogs((prev) => prev.slice(0, -1));
  const closeAllDialogs = () => setDialogs([]);

  const value: UIContextValue = {
    openToast,
    closeToast,
    handleQueryResultToast,
    toastOpen,
    toast,
    openPopover,
    closePopover,
    closeAllPopovers,
    popovers,
    openDrawer,
    closeDrawer,
    closeAllDrawers,
    drawers,
    openDialog,
    closeDialog,
    closeAllDialogs,
    dialogs
  };

  return (
    <UIContext.Provider value={value}>
      <SnackbarProvider
        maxSnack={6}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {children}
      </SnackbarProvider>
    </UIContext.Provider>
  );
};

function useUIContext() {
  const ctx = useContext(UIContext);
  if (!ctx) throw new Error('useUIContext must be used within UIProvider');
  return ctx;
}
export const useToast = () => {
  const { openToast, closeToast, handleQueryResultToast, toastOpen, toast } =
    useUIContext();
  return { openToast, closeToast, handleQueryResultToast, toastOpen, toast };
};
export const usePopover = () => {
  const { openPopover, closePopover, closeAllPopovers, popovers } =
    useUIContext();
  return { openPopover, closePopover, closeAllPopovers, popovers };
};
export const useDrawer = () => {
  const { openDrawer, closeDrawer, closeAllDrawers, drawers } = useUIContext();
  return { openDrawer, closeDrawer, closeAllDrawers, drawers };
};
export const useDialog = () => {
  const { openDialog, closeDialog, closeAllDialogs, dialogs } = useUIContext();
  return { openDialog, closeDialog, closeAllDialogs, dialogs };
};
