import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import Card from 'src/components/display/Card/Card';
import IconButton from 'src/components/display/IconButton/IconButton';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { Colors } from 'src/components/styles/colors';
import { spacings } from 'src/components/styles/constants';
import Button from 'src/components/display/Button/Button';
import Box from 'src/components/layout/Box/Box';
import { fontWeights } from 'src/components/styles/fonts';
import MiniCalendar from 'src/components/display/MiniCalendar/MiniCalendar';
import { useNavigate } from 'react-router-dom';
import AddEditAppointment from '../../calendar/AddEditAppointment';
import dayjs, { Dayjs } from 'dayjs';
import Calendar from 'src/components/display/Calendar/Calendar';
import { getCalendarLink } from 'src/router/routes';
import { useDialog } from 'src/contexts/UIContexts';

export const DashboardScheduleCard: FC<{
  date: Dayjs;
  setDate: (date: Dayjs) => void;
}> = ({ date, setDate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openDialog } = useDialog();

  return (
    <Card
      bgcolor={Colors.dawnPink}
      padding={`${spacings.xlarge} ${spacings.large} ${spacings.xlarge} 25px`}
    >
      <Flex
        alignItems="flex-start"
        marginBottom={spacings.medium}
        justifyContent="space-between"
      >
        <Typography fontWeight={fontWeights.extraBold} variant="h1">
          {t('SCHEDULE')}
        </Typography>
        <Flex gap={spacings.medium} alignItems="center">
          <Box height="100%">
            <IconButton
              id="add-new-appointment-button"
              icon={<AddIcon />}
              onClick={() =>
                openDialog({
                  header: t('ADD_APPOINTMENT'),
                  children: <AddEditAppointment />,
                  fullWidth: true,
                  maxWidth: 'lg',
                  closeButtonId: 'close-add-appointment-dialog'
                })
              }
            />
          </Box>
          <Button onClick={() => navigate(getCalendarLink())}>
            {t('SEE_ALL')}
          </Button>
        </Flex>
      </Flex>
      <Box marginBottom={spacings.large}>
        <MiniCalendar
          onChange={(newDate) => setDate(dayjs(newDate))}
          view="week"
          fullWidth
          value={date}
        />
      </Box>
      <Flex height="calc(100vh - 200px)" overflow="hidden" width="100%">
        <Calendar dayView smallHeader date={date} onNavigate={() => {}} />
      </Flex>
    </Card>
  );
};
