import { SemenVial } from './cycle';

export interface EmbryologyReport {
  id?: string;
  patientId: string;
  cycleId: string;
  patientName: string;
  retrievalDate: Date;
  eggsInseminated: number;
  eggsMature?: number;
  eggsRetrieved: number;
}
export enum GeneticStatus {
  NOT_BIOPSIED = 'Not biopsied',
  BIOPSIED = 'Biopsied',
  EUPLOID = 'Euploid',
  ANEUPLOID = 'Aneuploid',
  AFFECTED = 'Affected',
  NOT_AFFECTED = 'Not affected',
  MOSAIC = 'Mosaic',
  CHAOTIC = 'Chaotic',
  NO_RESULT = 'No Result',
  HIGH_LEVEL_MOSAIC = 'High Level Mosaic',
  INCONCLUSIVE = 'Inconclusive'
}
export enum Fate {
  CRYO = 'Cryo',
  DISCARDED = 'Discarded',
  TRANSFERRED = 'Transferred',
  THAWED = 'Thawed',
  DONATED = 'Donated',
  RESEARCH = 'Research',
  SHIPPED = 'Shipped',
  REFROZEN = 'ReFrozen'
}

export enum NotViableSince {
  DAY_ONE = 'day1',
  DAY_TWO = 'day2',
  DAY_THREE = 'day3',
  DAY_FOUR = 'day4',
  DAY_FIVE = 'day5',
  DAY_SIX = 'day6',
  DAY_SEVEN = 'day7',
  INSEMINATED = 'inseminated'
}

export interface UpdateGameteThawed {
  gradeAtFreeze?: string;
  gradeAtThaw?: string;
  gradeAtTransfer?: string;
  assistedHatching?: boolean;
  biopsy?: boolean;
  biopsyResults?: string;
  geneticStatus?: GeneticStatus;
  fate?: Fate;
}

interface EggDayCount {
  total: number;
  percentage: number;
}

export interface AvailableGamete {
  eggs: EggAndEmbryo[];
  embryos: EggAndEmbryo[];
  semenVials: SemenVial[];
}

export interface EggsCounts {
  collected: EggDayCount;
  mature: EggDayCount;
  inseminated: EggDayCount;
  dayOne: EggDayCount;
  dayTwo: EggDayCount;
  dayThree: EggDayCount;
  dayFour: EggDayCount;
  dayFive: EggDayCount;
  daySix: EggDayCount;
  daySeven: EggDayCount;
  euploid: EggDayCount;
  cryo: EggDayCount;
}

export interface EggsAndEmbryosReport extends EggsCounts {
  totalNumberOfFollicles: number;
  eggs: EggAndEmbryo[];
}

export interface UpdateEgg {
  day1?: string;
  day2?: string;
  day3?: string;
  day4?: string;
  day5?: string;
  day6?: string;
  day7?: string;
  ploidy?: GeneticStatus;
  fate?: Fate;
  location?: string;
  straw?: string;
}

export enum Maturity {
  MII = 'MII',
  MI = 'MI',
  GV = 'GV'
}

export enum LabratoryProtocol {
  EGG_ACTIVATION = 'Egg Activation',
  ASSISTED_HATCHING = 'Assisted Hatching',
  POLAR_BODY_BIOPSY = 'Polar body Biopsy',
  OTHER = 'Other'
}
export enum PGTTissueType {
  TROPHECTODERM = 'Trophectoderm',
  WHOLE_EMBRYO = 'Whole embryo',
  BLASTOMERE = 'Blastomere',
  POLAR_BODY_BIOPSY = 'Polar Body Biopsy'
}

export enum EmbryoGender {
  XX = 'XX',
  XY = 'XY',
  UNKNOWN = 'Unknown'
}
export enum InseminationMethods {
  IVF = 'IVF',
  ICSI = 'ICSI',
  PICSI = 'PICSI',
  MICSI = 'MICSI',
  Other = 'Other'
}

export enum Day1 {
  ZERO_PN = '0PN',
  ONE_PN = '1PN',
  TWO_PN = '2PN',
  THREE_PN = '3PN',
  GREATER_THEN_THREE_PN = '>3PN',
  MICRO_PN = 'MicroPN'
}
export interface EggAndEmbryoNotes {
  id?: string;
  cycleId?: string;
  embryoId?: string;
  value: string;
  isDeleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  createdByStaffId?: string;
}

export interface EggAndEmbryoNotesToCreate {
  cycleId: string;
  embryoId: string;
  date: string;
  patientId: string;
  notesToCreate: string[];
  notesToDelete?: string[];
}

export interface EggAndEmbryo {
  id: string;
  patientId: string;
  cycleId: string;
  retrieved: boolean;
  mature?: Maturity;
  inseminated?: boolean;
  day1?: string;
  day2?: string;
  day3?: string;
  day4?: string;
  day5?: string;
  day6?: string;
  day7?: string;
  geneticStatus?: GeneticStatus;
  fate?: Fate;
  strawNum?: number;
  location?: string;
  isViable: boolean;
  notViableSince?: NotViableSince;
  createdAt?: Date;
  updatedAt?: Date;
  tlid?: string;
  inseminationMethod?: InseminationMethods;
  inseminationTime?: Date;
  labratoryProtocols?: LabratoryProtocol[];
  aiScore?: string;
  notes?: EggAndEmbryoNotes[];
  biopsyTech?: string;
  biopsyWitness?: string;
  biopsyTime?: Date;
  tubeTech?: string;
  tubeWitness?: string;
  tubeTime?: Date;
  pgtTubeId?: string;
  pgtTissueType?: PGTTissueType[];
}
