import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { ExamReportServer } from '../types/patient-server';
import { AppError } from '../types/global';
import { ExamReport, Examination } from '../types/exam';
import {
  createExamReportRequest,
  generateExamDocumentRequest,
  getExaminationByIdRequest,
  getExaminationsByPatientIdRequest,
  updateExamReportRequest
} from '../api/patients.api';
import { useTranslation } from 'react-i18next';
import { ToastType } from '../components/display/Toast/Toast';
import { queryKeys, querySubKeys } from './queryKeys';
import { useToast, useDialog } from 'src/contexts/UIContexts';
import { examTemplateId, Document } from 'src/types/documents';
import { useDocumentEditor } from 'src/contexts/AppContexts/AppContexts';

function useExams() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const queryClient = useQueryClient();
  const { onDocumentEditorInfoChange, onDocumentsModalOpen } =
    useDocumentEditor();
  return {
    createExamReport: () =>
      useMutation<ExamReportServer, AppError, ExamReport>(
        (report: ExamReport) => createExamReportRequest(report),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_EXAM_REPORT')
            }),
          onSuccess: (_, { patientId, cycleId }) => {
            openToast({
              title: t('CREATE_EXAMINATION_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            closeDialog();
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].REPORTS
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].EXAMINATIONS
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CYCLES,
              cycleId,
              querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CRYO_GAMETE
            ]);
          }
        }
      ),
    updateExamReport: () =>
      useMutation<ExamReportServer, AppError, ExamReport>(
        (report: ExamReport) => updateExamReportRequest(report),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_EXAM_REPORT')
            }),
          onSuccess: (_, { id, patientId, cycleId }) => {
            openToast({
              title: t('UPDATE_EXAMINATION_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            closeDialog();
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].EXAMINATIONS
            ]);
            queryClient.invalidateQueries([queryKeys.EXAMINATIONS, id]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].CYCLES,
              cycleId,
              querySubKeys[queryKeys.PATIENTS].EGG_AND_EMBRYO
            ]);
            queryClient.invalidateQueries([
              queryKeys.CYCLES,
              cycleId,
              querySubKeys[queryKeys.CYCLES].GRAPH_DATA
            ]);
          }
        }
      ),
    getExaminationsByPatientId: (
      patientId: string,
      options?: UseQueryOptions<Examination[], AppError>
    ) =>
      useQuery<Examination[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].EXAMINATIONS
        ],
        () => getExaminationsByPatientIdRequest(patientId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_EXAMINATIONS')
            }),
          enabled: !!patientId,
          ...options
        }
      ),
    getExaminationById: (
      examId: string,
      options?: UseQueryOptions<Examination, AppError>
    ) =>
      useQuery<Examination, AppError>(
        [queryKeys.EXAMINATIONS, examId],
        () => getExaminationByIdRequest(examId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_EXAMINATION')
            }),
          enabled: !!examId,
          ...options
        }
      ),
    generateExamDocument: ({ patientId }: { patientId: string }) =>
      useMutation<
        Document,
        AppError,
        {
          examId: string;
          patientId: string;
        }
      >((data) => generateExamDocumentRequest(data), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_GENERATE_EXAM_DOCUMENT')
          }),
        onSuccess: (data) => {
          onDocumentEditorInfoChange({
            patientId,
            templateId: examTemplateId,
            documentId: data.id
          });
          onDocumentsModalOpen(true);
          queryClient.invalidateQueries([
            queryKeys.PATIENTS,
            patientId,
            querySubKeys[queryKeys.PATIENTS].EXAMINATIONS
          ]);
        }
      })
  };
}

export default useExams;
