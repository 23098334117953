import { useQuery, UseQueryOptions } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AppError } from 'src/types/global';
import { getClinicSettingInfoById } from 'src/api/clinic-settings.api';
import { ClinicSettings } from 'src/types/clinic-settings';
import { queryKeys } from './queryKeys';
import useMeApi from './useMeApi';
import { useToast } from 'src/contexts/UIContexts';

function useClinicSettingsApi() {
  const { getMe } = useMeApi();
  const { data: me } = getMe();

  const { t } = useTranslation();
  const { handleQueryResultToast } = useToast();
  return {
    getClinicSettings: (options?: UseQueryOptions<ClinicSettings, Error>) =>
      useQuery<ClinicSettings, AppError>(
        [queryKeys.CLINIC_SETTINGS, me?.user?.clinicId],
        () => getClinicSettingInfoById(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CLINIC_SETTINGS')
            }),
          ...options,
          enabled: options?.enabled ?? !!me?.user?.clinicId
        }
      )
  };
}

export default useClinicSettingsApi;
