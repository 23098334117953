import dayjs from 'dayjs';
import firebase from 'src/utils/firebase';
import { CycleWizardProps } from 'src/modules/patients/cycleWizard/CycleWizard';
import { Appointment } from 'src/types/appointment';
import { DocumentEditorParams } from 'src/types/documents';
import { InboxItem, InboxTabs } from 'src/types/inbox';
import { User } from 'src/types/user';

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  error: string | null;
  user: User | null;
  method: 'Firebase' | null;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  error: null,
  user: null,
  method: null
};

export type AuthAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    error: string | null;
    user: User | null;
  };
};

export interface CriticalAppError {
  code: number;
  message?: string;
}

export interface CriticalAppErrorContextValue {
  criticalAppError?: CriticalAppError | null;
  setCriticalAppError: (error: CriticalAppError) => void;
  resetCriticalAppErrorContext?: () => void;
}

export enum DailyWorkListDrawerViews {
  DAILY = 'daily',
  PATIENT = 'patient'
}

export interface AppContextValue extends AuthState {
  // Auth
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<firebase.auth.UserCredential>;
  signInWithGoogle: () => Promise<firebase.auth.UserCredential>;
  loginViaGoogleProvider: (
    token: string
  ) => Promise<firebase.auth.UserCredential>;
  logout: () => Promise<void>;
  // Critical App Error
  criticalAppError?: CriticalAppError | null;
  setCriticalAppError: (error: CriticalAppError) => void;
  resetCriticalAppErrorContext: () => void;
  // Cycle Wizard
  openWizard: (data: Omit<CycleWizardProps, 'onClose'>) => void;
  closeWizard: () => void;
  wizardProps: Omit<CycleWizardProps, 'onClose'> | null;
  // Document Editor
  documentEditorParams: DocumentEditorParams;
  isDocumentsModalOpen: boolean;
  setDocumentEditorParams: (params: DocumentEditorParams) => void;
  setDocumentsModalOpen: (isOpen: boolean) => void;
  // Daily Worklist Drawer
  isDailyWorklistDrawerOpen: boolean;
  dailyWorklistDate: dayjs.Dayjs;
  dailyWorklistView: DailyWorkListDrawerViews;
  dailyWorklistPatientId: string | null;
  dailyWorklistAppointment: Appointment | null;
  setIsDailyWorklistDrawerOpen: (isOpen: boolean) => void;
  setDailyWorklistDate: (date: dayjs.Dayjs) => void;
  setDailyWorklistView: (view: DailyWorkListDrawerViews) => void;
  setDailyWorklistPatientId: (id: string | null) => void;
  setDailyWorklistAppointment: (appointment: Appointment | null) => void;
  // Sidebar
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  // Navbar Drawer
  isNavbarDrawerOpen: boolean;
  setIsNavbarDrawerOpen: (isOpen: boolean) => void;
  // Messenger
  isMessengerOpen: boolean;
  setIsMessengerOpen: (isOpen: boolean) => void;
  // Telehealth
  isTelehealthOpen: boolean;
  setIsTelehealthOpen: (isOpen: boolean) => void;
  // Inbox
  selectedInboxItem: InboxItem;
  setSelectedInboxItem: (item: InboxItem) => void;
  selectedInboxTab: InboxTabs;
  setSelectedInboxTab: (tab: InboxTabs) => void;
}
