const enAdminTranslations = {
  SERVICE_TYPE: 'Service Type',
  BILLING_CODE: 'Billing Code',
  BILLING_CODE_TYPE: 'Billing Code Type',
  ORDER_CODE: 'Order Code',
  ORDER_CODE_TYPE: 'Order Code Type',
  VENDORS: 'Vendors',
  DESCRIPTION: 'Description'
};

export default enAdminTranslations;
