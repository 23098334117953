import { FC, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Appointment, AppointmentStatus } from 'src/types/appointment';
import { Colors } from 'src/components/styles/colors';
import { fontWeights } from 'src/components/styles/fonts';
import Card from 'src/components/display/Card/Card';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import IconButton from 'src/components/display/IconButton/IconButton';
import { shadows, spacings } from 'src/components/styles/constants';
import Table from 'src/components/display/Table/Table';
import Box from 'src/components/layout/Box/Box';
import Select from 'src/components/data-entry/Select/Select';
import useAppointments from 'src/hooks/useAppointments';
import { AppointmentDetails } from './AppointmentDetails';
import AddEditAppointment from 'src/modules/calendar/AddEditAppointment';
import { useGetScheduleColumns } from './useGetScheduleColumns';
import { useDialog } from 'src/contexts/UIContexts';

const StyledScheduleContainer = styled(Card)`
  background-color: ${Colors.dawnPink};
  padding: 0 ${spacings.large};
  box-shadow: ${shadows.default};
  height: 100%;
`;

export const ScheduleTable = styled(Table)`
  .MuiDataGrid-virtualScroller {
    overflow: auto !important;
  }

  .MuiDataGrid-scrollbar {
    display: none;
  }

  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell {
    white-space: unset;
  }
  .MuiDataGrid-row {
    padding: ${spacings.small};
    cursor: pointer;
  }
  .MuiDataGrid-row,
  .MuiDataGrid-row:hover {
    background-color: ${Colors.white};
  }
  .MuiDataGrid-cell .MuiBox-root {
    display: flex;
    padding: 0 ${spacings.small};
  }

  .MuiDataGrid-cell:last-of-type {
    padding-inline-end: 0;
  }

  .MuiTypography-root {
    min-width: 50px;
  }
`;

const StyledSelect = styled(Select)`
  .MuiInputBase-root {
    background-color: ${Colors.transparent};
    width: min-content;
    color: ${Colors.emperor};
    font-weight: ${fontWeights.extraBold};

    &:hover,
    &.Mui-focused {
      box-shadow: none;
    }
  }
`;

export const ScheduleCard: FC = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const [isPrevious, setIsPrevious] = useState(false);
  const { openDialog } = useDialog();
  const { getPatientAppointments } = useAppointments();

  const {
    data: appointments,
    isLoading: isLoadingAppointments,
    isFetching: isFetchingAppointments
  } = getPatientAppointments(patientId);

  const { getColumns } = useGetScheduleColumns();
  const columns = getColumns();
  const isLoading = isLoadingAppointments || isFetchingAppointments;

  const { previousMeetings, upcomingMeetings } = useMemo(
    () =>
      appointments?.reduce<{
        upcomingMeetings: Appointment[];
        previousMeetings: Appointment[];
      }>(
        (acc, meeting) => {
          if (dayjs(meeting.date).isBefore(dayjs().subtract(1, 'day'))) {
            acc.previousMeetings.push(meeting);
          } else {
            acc.upcomingMeetings.push(meeting);
          }

          return acc;
        },
        {
          upcomingMeetings: [],
          previousMeetings: []
        }
      ) || { upcomingMeetings: [], previousMeetings: [] },
    [appointments]
  );

  return (
    <StyledScheduleContainer>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        paddingX={spacings.medium}
        paddingTop={spacings.xlarge}
      >
        <Typography variant="h1">{t('SCHEDULE')}</Typography>
        <Flex gap={spacings.medium} alignItems="center">
          <StyledSelect
            variant="filter"
            value={
              isPrevious
                ? AppointmentStatus.PREVIOUS
                : AppointmentStatus.UPCOMING
            }
            options={[
              { value: AppointmentStatus.UPCOMING, label: t('UPCOMING') },
              { value: AppointmentStatus.PREVIOUS, label: t('PREVIOUS') }
            ]}
            onChange={(evt) => {
              setIsPrevious(evt.target.value === AppointmentStatus.PREVIOUS);
            }}
          />
          <Box>
            <IconButton
              id="add-new-appointment-button"
              icon={<AddIcon />}
              onClick={() =>
                openDialog({
                  header: t('ADD_APPOINTMENT'),
                  children: <AddEditAppointment patientId={patientId} />,
                  fullWidth: true,
                  maxWidth: 'lg',
                  closeButtonId: 'close-add-appointment-dialog'
                })
              }
            />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={spacings.medium}>
        <Box height={420} id="patient-scheduled-appointments-container">
          <ScheduleTable
            hideHeaders
            variant="carded"
            getRowSpacing={() => ({ bottom: 10 })}
            columns={columns}
            rows={(isPrevious ? previousMeetings : upcomingMeetings) || []}
            loading={isLoading}
            paginationModel={{ page: 0, pageSize: 4 }}
            onRowClick={({ id }: GridRowParams<Appointment>) =>
              openDialog({
                header: ' ',
                fullWidth: true,
                maxWidth: 'sm',
                removeDefaultPadding: true,
                hideCloseButton: true,
                children: <AppointmentDetails appointmentId={`${id}`} />
              })
            }
          />
        </Box>
      </Flex>
    </StyledScheduleContainer>
  );
};
