import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SvgIcon } from '@mui/material';
import {
  GridAlignment,
  GridColDef,
  GridRenderEditCellParams,
  GridSortItem
} from '@mui/x-data-grid-premium';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { styled } from '@mui/material';
import { isEqual } from 'lodash';

import Card from 'src/components/display/Card/Card';
import Table, {
  dateColumn,
  TableProps
} from 'src/components/display/Table/Table';
import { radii, spacings } from 'src/components/styles/constants';
import { Colors } from 'src/components/styles/colors';
import Button from 'src/components/display/Button/Button';
import Checkbox from 'src/components/data-entry/Checkbox/Checkbox';
import Typography from 'src/components/display/Typography/Typography';
import InputField from 'src/components/data-entry/InputField';
import { InputFieldVariant } from 'src/components/data-entry/InputField/InputField';
import MiniIconButton from 'src/components/display/MiniIconButton';
import DatePicker from 'src/components/data-entry/DatePicker';
import { Task, TaskPriority, TaskStatus } from 'src/types/task';
import useTasks from 'src/hooks/useTasks';
import useStaffMembers from 'src/hooks/useStaffMembers';
import { getDateFormat } from 'src/utils/dateAndTIme';
import {
  compareNumberValues,
  compareStringDateValues,
  compareStringValues,
  getFullName
} from '../../utils/general';
import { DoctorChips } from '../patients/common/DoctorChips';
import { ReactComponent as CheckListIcon } from '../../assets/icons/check-list.svg';
import { ReactComponent as FlagIcon } from '../../assets/icons/flag.svg';
import { TasksMenuProps } from './types';
import DeleteTasksDialog from './DeleteTasksDialog';
import Menu from 'src/components/display/Menu';
import Flex from 'src/components/layout/Flex';
import { UserTypes } from 'src/types/user';
import { patientRoutes } from 'src/router/routes';
import Avatar, { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import { useDialog, usePopover } from 'src/contexts/UIContexts';

const CustomEditCellComponent: FC<GridRenderEditCellParams<any | string>> = (
  props
) => {
  const { id, value, field, api } = props;
  const { t } = useTranslation();
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    api.setEditCellValue({
      id,
      field,
      value: event.target.value
    });
  };

  return (
    <InputField
      variant={InputFieldVariant.FILLED}
      autoFocus
      placeholder={t('ADD_COMMENT')}
      value={value}
      onChange={handleValueChange}
    />
  );
};

const EditDueDateCell: FC<GridRenderEditCellParams<Task | Date>> = (params) => {
  const { id, value, field, api } = params;

  const [errorMessage, setErrorMessage] = useState<string>(null);

  const { t } = useTranslation();

  const handleValueChange = (date: Date) => {
    const dateValue = dayjs(date);

    if (dateValue.isBefore(dayjs())) {
      setErrorMessage(t('DATE_CAN_ONLY_BE_IN_THE_FUTURE'));
    } else {
      setErrorMessage(null);

      api.setEditCellValue({
        id,
        field,
        value: date
      });
    }
  };

  return (
    <DatePicker
      autoFocus
      value={value}
      onChange={handleValueChange}
      fullWidth
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};

const renderCustomEditCellComponent: GridColDef['renderCell'] = (params) => {
  return <CustomEditCellComponent {...params} />;
};

const sortRows = (data: Task[], sortFields: GridSortItem[]): Task[] => {
  const sortedData = [...data];

  sortFields.forEach((sortField) => {
    if (sortField && sortField.field) {
      const { field, sort } = sortField;

      sortedData.sort((a: Task, b: Task) => {
        const valueA = a[field];
        const valueB = b[field];

        switch (field) {
          case 'dueDate':
          case 'doneDate':
            return compareStringDateValues(valueA, valueB, sort);
          case 'name':
            return compareStringValues(valueA.value, valueB.value, sort);
          case 'doneByStaffId':
          case 'assignee':
            return compareNumberValues(valueA || 0, valueB || 0, sort);
          default:
            return compareStringValues(valueA || '', valueB || '', sort);
        }
      });
    }
  });

  return sortedData;
};

interface TasksTableProps extends Omit<TableProps, 'columns'> {
  tasks: Task[];
  historyTasks?: boolean;
  tableHeight?: string;
  pageSize?: number;
  standalone?: boolean;
}

const StyledFlex = styled(Flex)`
  cursor: pointer;
  gap: ${spacings.medium};
  align-items: center;
`;

export const TasksTable: FC<TasksTableProps> = ({
  tasks,
  historyTasks,
  pageSize,
  standalone = false,
  ...rest
}) => {
  const [tasksUpdating, setTasksUpdating] = useState<string[]>([]);
  const { openPopover, closePopover } = usePopover();
  const { openDialog } = useDialog();
  const handleMenuClick = ({ event, taskId, patientId }: TasksMenuProps) => {
    openPopover({
      anchorEl: event.currentTarget,
      hideToolbar: true,
      children: (
        <Menu
          items={[
            {
              label: t('DELETE'),
              action: () => {
                closePopover();
                openDialog({
                  header: t('DELETE_TASK_TITLE'),
                  children: (
                    <DeleteTasksDialog taskId={taskId} patientId={patientId} />
                  ),
                  fullWidth: true
                });
              }
            }
          ]}
        />
      )
    });
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateTask } = useTasks();

  const { mutate: handleUpdateTask } = updateTask();
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const { getStaffMembersNames } = useStaffMembers();
  const { data: doctors } = getStaffMembersNames();

  const columns: GridColDef<Task>[] = [
    {
      field: 'menu',
      headerName: t('ACTIONS').toLocaleUpperCase(),
      renderHeader: () => null,
      width: 40,
      sortable: false,
      align: 'center',
      filterable: false,
      valueGetter: (_, row) => row.id,
      renderCell: ({ row }) => {
        return (
          <MiniIconButton
            icon={<MoreVertIcon />}
            onClick={(event) => {
              return handleMenuClick({
                event,
                taskId: row.id,
                patientId: row.patientId
              });
            }}
          />
        );
      }
    },
    {
      sortComparator: (v1, v2) =>
        (v1 === TaskStatus.DONE ? 1 : 0) - (v2 === TaskStatus.DONE ? 1 : 0),
      field: 'status',
      headerName: t('STATUS').toLocaleUpperCase(),
      renderHeader: () => <CheckListIcon />,
      width: 40,
      filterable: false,
      renderCell: ({ row: task }) => {
        const [isHovered, setIsHovered] = useState<boolean>(false);
        const [isChangingStatus, setIsChangingStatus] =
          useState<boolean>(false);

        useEffect(() => {
          setIsChangingStatus(false);
          setTasksUpdating(
            tasksUpdating.filter((taskId) => taskId !== task?.id)
          );
        }, [task]);

        const shouldDisplayChecked: boolean = useMemo(() => {
          if (task.status === TaskStatus.DONE) {
            return !(isChangingStatus || isHovered);
          } else {
            return isChangingStatus || isHovered;
          }
        }, [isChangingStatus, isHovered, task]);

        return (
          <Checkbox
            sx={{ padding: 0 }}
            checked={shouldDisplayChecked}
            disabled={isChangingStatus}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onChange={async () => {
              setIsChangingStatus(true);
              setTasksUpdating([...tasksUpdating, task?.id]);

              const { status, ...restOfTask } = task;
              const statusToChangeTo =
                status === TaskStatus.DONE
                  ? TaskStatus.NOT_DONE
                  : TaskStatus.DONE;
              await handleUpdateTask({
                status: statusToChangeTo,
                ...restOfTask
              });
            }}
          />
        );
      }
    },
    {
      field: 'patientId',
      flex: 2,
      headerName: t('PATIENT_NAME').toUpperCase(),
      filterable: false,
      sortable: true,
      valueGetter: (_, row) => {
        const patientName = getFullName(row.patient);

        return patientName;
      },
      renderCell: ({ row }) => {
        const patientName = getFullName(row.patient);

        const avatar = getDefaultAvatar({
          userId: row.patientId,
          userType: UserTypes.patients,
          pictureId: row.patient.pictureId
        });
        return (
          <StyledFlex
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              navigate(patientRoutes.getByIdLink(row.patientId));
            }}
          >
            <Avatar image={avatar} size={AvatarSizes.SMALL} />
            <Typography>{patientName}</Typography>
          </StyledFlex>
        );
      }
    },
    {
      field: 'category',
      flex: 3,
      headerName: t('CATEGORY').toLocaleUpperCase(),
      renderCell: ({ row: { category } }) => (
        <Typography title={category}>{category}</Typography>
      )
    },
    {
      field: 'description',
      flex: 3,
      headerName: t('TASK').toLocaleUpperCase(),
      valueGetter: (_, row) => {
        return t(row.name.translationKey) || row.name.value;
      },
      renderCell: ({ value }) => (
        <Typography
          style={{ whiteSpace: 'normal' }}
          title={value}
          paddingRight={spacings.large}
        >
          {value}
        </Typography>
      )
    },
    {
      field: 'comment',
      flex: 3,
      headerName: t('COMMENTS').toLocaleUpperCase(),
      editable: !historyTasks,
      renderEditCell: renderCustomEditCellComponent,
      renderCell: ({ row: { comment } }) => {
        return comment ? (
          <Typography displayLinks whiteSpace="pre-wrap">
            {comment}
          </Typography>
        ) : (
          <Typography color={Colors.alto2}>
            {!historyTasks ? t('ADD_COMMENT') : ''}
          </Typography>
        );
      }
    },
    ...(historyTasks
      ? [
          {
            ...dateColumn,
            field: 'doneDate',
            flex: 2,
            headerName: t('DONE_DATE').toLocaleUpperCase(),
            headerAlign: 'center' as GridAlignment,
            align: 'center' as GridAlignment,
            valueGetter: (value) => (value ? new Date(value) : null),
            renderCell: ({ row }) => (
              <Typography>{`${
                row.doneDate
                  ? dayjs(row.doneDate).format(getDateFormat({ isShort: true }))
                  : '-'
              }`}</Typography>
            )
          },
          {
            field: 'doneByStaffId',
            flex: 2,
            headerName: t('DONE_BY').toLocaleUpperCase(),
            headerAlign: 'center' as GridAlignment,
            sortComparator: (v1: string, v2: string) => v1?.localeCompare(v2),
            valueGetter: (value) => {
              const selectedDoctors = doctors?.filter(({ id }) => value === id);

              const doctorsFullNames = selectedDoctors?.map((doctor) => {
                return getFullName(doctor);
              });

              return doctorsFullNames;
            },
            renderCell: ({ row: task }) => (
              <DoctorChips
                id="checklist-done-by-doctor-chips"
                value={[task.doneByStaffId]}
                showSelectedValue
                shouldFetchStaffMembersNames={false}
              />
            )
          }
        ]
      : [
          {
            ...dateColumn,
            field: 'dueDate',
            flex: 3,
            headerName: t('DUE_DATE').toLocaleUpperCase(),
            headerAlign: 'center' as GridAlignment,
            valueGetter: (value) => (value ? new Date(value) : null),
            align: 'center' as GridAlignment,
            editable: !historyTasks,
            renderEditCell: (params) => {
              return <EditDueDateCell {...params} />;
            },
            renderCell: ({ row: { dueDate } }) => (
              <Typography>{`${
                dueDate
                  ? dayjs(dueDate).format(getDateFormat({ isShort: true }))
                  : '-'
              }`}</Typography>
            )
          },
          {
            sortComparator: (v1: string, v2: string) => v1?.localeCompare(v2),
            field: 'assignee',
            valueGetter: (_, row) => getFullName(row.assignedToStaff),
            flex: 2,
            headerName: t('ASSIGNED_TO').toLocaleUpperCase(),
            headerAlign: 'center' as GridAlignment,
            renderCell: ({ row: task }) => (
              <DoctorChips
                id="checklist-assigned-to-doctor-chips"
                value={[task.assignedToStaffId]}
                showSelectedValue
                addLabel=""
                onAddChip={async (selectedDoctorId) => {
                  await handleUpdateTask({
                    ...task,
                    assignedToStaffId:
                      task.assignedToStaffId === selectedDoctorId
                        ? null
                        : selectedDoctorId
                  });
                }}
                shouldFetchStaffMembersNames={false}
              />
            )
          }
        ]),
    {
      field: 'priority',
      width: 120,
      headerName: t('PRIORITY').toLocaleUpperCase(),
      headerAlign: 'center' as GridAlignment,
      align: 'center',
      filterable: false,
      renderCell: ({ row: task }) => (
        <Button
          bgColor="transparent"
          onClick={async () => {
            await handleUpdateTask({
              ...task,
              priority:
                task.priority === TaskPriority.NORMAL
                  ? TaskPriority.HIGH
                  : TaskPriority.NORMAL
            });
          }}
          disabled={historyTasks}
        >
          <SvgIcon>
            <FlagIcon
              color={
                task.priority === TaskPriority.HIGH
                  ? Colors.red
                  : Colors.emperor
              }
            />
          </SvgIcon>
        </Button>
      )
    }
  ];

  const handleSortModelChange = (newSortModel: GridSortItem[]) => {
    setSortModel(newSortModel);
  };

  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    { field: 'priority', sort: 'asc' }
  ]);

  const sortedTasks = useMemo(() => {
    if (tasks.length && sortModel.length) {
      const [sortField] = sortModel;
      const { sort } = sortField;

      const sortedData = sortRows(tasks, [sortField]);

      if (sort === 'desc') {
        sortedData.reverse();
      }

      return sortedData;
    }

    return tasks;
  }, [sortModel, tasks]);

  const handleTaskUpdate = async (
    newTask: Task,
    oldTask: Task
  ): Promise<Task> => {
    if (
      !isEqual(newTask.comment, oldTask.comment) ||
      !isEqual(newTask.dueDate, oldTask.dueDate)
    ) {
      await handleUpdateTask(newTask);
    }
    return newTask;
  };

  return (
    <Card
      shadow={!standalone}
      overflow="hidden"
      borderRadius={standalone ? radii.none : radii.xlarge}
      paddingTop={standalone ? spacings.none : spacings.large}
      id={`checklist-table-${historyTasks ? 'done' : 'not-done'}`}
    >
      <Table
        getRowClassName={({ row: task }) =>
          tasksUpdating.includes(task?.id) ? 'loading' : ''
        }
        columns={columns}
        columnHeaderHeight={50}
        rows={sortedTasks || []}
        processRowUpdate={handleTaskUpdate}
        freeHeight
        onSortModelChange={handleSortModelChange}
        initialState={{
          sorting: {
            sortModel
          }
        }}
        paginationModel={{ pageSize: pageSize || 5, page: 0 }}
        {...rest}
      />
    </Card>
  );
};
