import { Dayjs } from 'dayjs';
import { DocumentTypes } from 'src/types/documents';
import { longDateFormat } from 'src/utils/dateAndTIme';

export enum queryKeys {
  ME = 'me',
  APPOINTMENTS = 'appointments',
  CLINICS = 'clinics',
  CLINIC_SETTINGS = 'clinic-settings',
  PATIENTS = 'patients',
  PATIENT_INSURANCES = 'patient-insurances',
  CLINIC_ROOMS = 'clinic-rooms',
  BUSY_TIMES = 'busy-times',
  BILLING_STATS = 'billing-stats',
  BILLING = 'billing',
  FEED = 'feed',
  BILLING_SERVICES = 'billing-services',
  ICD10_CODES = 'icd10-codes',
  CYCLES = 'cycles',
  DOCUMENTS = 'documents',
  TEMPLATES = 'templates',
  ENCOUNTERS = 'encounters',
  EVENTS = 'events',
  EXAMINATIONS = 'examinations',
  CONSTANTS = 'constants',
  ORDER_VENDORS = 'order-vendors',
  PENDING_OTP = 'pending-otp',
  CHANNELS = 'channels',
  INBOX = 'inbox',
  DOCTORS = 'doctors',
  MEDICATIONS = 'medications',
  TELEHEALTH_LINK = 'telehealth-link',
  PRESCRIPTIONS = 'prescriptions',
  MEDICAL_QUESTIONS = 'medical-questions',
  STAFF = 'staff',
  TASKS = 'tasks',
  LAB = 'lab',
  EFAX = 'e-fax'
}

enum TasksKeys {
  STAFF = 'staff',
  PATIENTS = 'patients'
}

enum LabSubKeys {
  RESULTS = 'results',
  TESTS = 'tests'
}

enum PrescriptionSubKeys {
  CYCLE = 'cycle'
}

enum MedicationSubKeys {
  RX = 'rx'
}

enum InboxSubKeys {
  RECENTS = 'recents'
}

enum ChannelSubKeys {
  MESSAGES = 'messages'
}

enum FeedSubKeys {
  ACTIVITIES = 'activities',
  ACTIVITIES_BY_CLINIC = 'activities-by-clinic'
}

enum EncounterSubKeys {
  APPOINTMENT = 'appointment',
  CODE_ROWS = 'code-rows',
  INSURANCE = 'insurance',
  ACTIONS_TAKEN = 'actions-taken',
  RESULTS = 'results',
  PATIENTS = 'patients',
  GENERATE_ENCOUNTER_DOCUMENT = 'generate-encounter-document',
  ENCOUNTER_DOCUMENTS = 'encounter-documents'
}

enum CycleSubKeys {
  GRAPH_DATA = 'graph-data',
  PATIENTS = 'patients',
  CURRENT_CYCLE = 'current-cycle',
  HAS_EMBRYOLOGY_REPORT = 'has-embryology-report'
}

enum BillingServiceSubKeys {
  MODIFIERS = 'modifiers',
  PROCEDURES = 'procedures',
  LABS = 'labs',
  ENCOUNTERS = 'encounters'
}

enum ClinicSubKeys {
  DASHBOARD_SUMMARY = 'dashboard-summary',
  EXTERNAL_ORDER_BUNDLES = 'external-order-bundles',
  EXPORT_PATIENT_DATA = 'export-patient-data'
}

enum BillingSubKeys {
  INVOICES = 'invoices',
  PROFILE = 'profile'
}

enum BusyTimesSubKeys {
  STAFF = 'staff',
  ROOM = 'room'
}

enum PatientSubKeys {
  APPOINTMENTS = 'appointments',
  PATIENT_CARE_TEAM = 'patient-care-team',
  CARE_TEAM_PRIMARY = 'care-team-primary',
  DOCUMENTS = 'documents',
  PATIENT_HISTORY = 'patient-history',
  CYCLES = 'cycles',
  LAB_ORDER = 'lab-order',
  EMBRYOS_LIST = 'embryos-list',
  EGG_AND_EMBRYO = 'egg-and-embryo',
  EXAMINATIONS = 'examinations',
  REPORTS = 'reports',
  CRYO_GAMETE = 'cryo-gamete',
  THAW_REPORT = 'thaw-report',
  TASKS = 'tasks',
  LAB_RESULTS = 'lab-results',
  CHANNELS = 'channels',
  OVERVIEW = 'overview',
  GAMETE = 'gamete',
  TREATMENT_DATA = 'treatment-data',
  DIAGNOSIS = 'diagnosis',
  QUESTIONS = 'questions',
  CATEGORY = 'category',
  PATIENT_INSURANCES = 'patient-insurances',
  PATIENT_INSURANCES_MARK_UNINSURED = 'mark-uninsured',
  PATIENT_INSURANCES_INSURANCE_PAYERS = 'insurance-payers',
  SEARCH = 'search'
}

enum AppointmentSubKeys {
  AGGREGATED = 'aggregated',
  CODING_TEMPLATES = 'coding-templates',
  CYCLES = 'cycles',
}

export const DocumentSubKeys: Record<DocumentTypes, string> = {
  [DocumentTypes.ORDERS]: DocumentTypes.ORDERS,
  [DocumentTypes.GENERAL]: DocumentTypes.GENERAL,
  [DocumentTypes.CONSENT]: DocumentTypes.CONSENT,
  [DocumentTypes.RECORD]: DocumentTypes.RECORD,
  [DocumentTypes.MEDICAL_NOTES]: DocumentTypes.MEDICAL_NOTES
};

export const querySubKeys = {
  [queryKeys.PATIENTS]: PatientSubKeys,
  [queryKeys.APPOINTMENTS]: AppointmentSubKeys,
  [queryKeys.BUSY_TIMES]: BusyTimesSubKeys,
  [queryKeys.BILLING]: BillingSubKeys,
  [queryKeys.BILLING_SERVICES]: BillingServiceSubKeys,
  [queryKeys.CLINICS]: ClinicSubKeys,
  [queryKeys.CYCLES]: CycleSubKeys,
  [queryKeys.ENCOUNTERS]: EncounterSubKeys,
  [queryKeys.FEED]: FeedSubKeys,
  [queryKeys.CHANNELS]: ChannelSubKeys,
  [queryKeys.INBOX]: InboxSubKeys,
  [queryKeys.MEDICATIONS]: MedicationSubKeys,
  [queryKeys.PRESCRIPTIONS]: PrescriptionSubKeys,
  [queryKeys.LAB]: LabSubKeys,
  [queryKeys.DOCUMENTS]: DocumentSubKeys,
  [queryKeys.TASKS]: TasksKeys
};

export const dateRangeQuerySubkey = (
  startDate: Dayjs,
  endDate: Dayjs
): object => ({
  startDate: startDate.format(longDateFormat),
  endDate: endDate.format(longDateFormat)
});
