import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeProps, styled } from '@mui/material';
import Button from 'src/components/display/Button';
import Card from 'src/components/display/Card';
import Typography from 'src/components/display/Typography';
import Flex from 'src/components/layout/Flex';
import { Colors } from 'src/components/styles/colors';
import { iconSizes, radii, spacings } from 'src/components/styles/constants';
import {
  InboxData,
  InboxItem,
  InboxTabs,
  InboxTypes,
  MessageRecent
} from 'src/types/inbox';
import MessageInboxCard from './messages/MessageInboxCard';
import TaskInboxCard from './tasks/TaskInboxCard';
import SearchField from 'src/components/data-entry/SearchField';
import { Task } from 'src/types/task';
import Box from 'src/components/layout/Box';
import { kebabCase } from 'src/utils/general';
import Loader from 'src/components/display/Loader';
import { useNavbar } from 'src/contexts/AppContexts/AppContexts';

const InboxItemCard = styled(Card)`
  cursor: pointer;
  padding: ${spacings.large};
  border-radius: ${radii.large};
`;

const StyledBadge = styled(Badge)`
  margin-left: calc(${spacings.small} + 2px);
`;

const AlertIconBadge = (props: BadgeProps) => (
  <StyledBadge
    {...props}
    variant="dot"
    color="error"
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
  />
);

interface Props {
  inboxData: InboxData;
  selectedTab: InboxTabs;
  setSelectedTab: (tab: InboxTabs) => void;
  setSelectedItem: (item: InboxItem) => void;
}

const InboxRecentsList = ({
  inboxData,
  selectedTab,
  setSelectedTab,
  setSelectedItem
}: Props) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>();
  const { setIsNavbarDrawerOpen } = useNavbar();

  const filteredData = useMemo(() => {
    return searchTerm
      ? // @ts-ignore
        inboxData[selectedTab]?.data?.filter((val) =>
          Object.values(val)
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : inboxData[selectedTab]?.data;
  }, [searchTerm, inboxData, selectedTab]);

  return (
    <Flex flexDirection="column" flexGrow={1} minHeight={0}>
      <Flex marginTop={spacings.medium} flexDirection="column">
        {Object.entries(inboxData).map(
          (
            [
              inboxKey,
              { label: inboxLabel, value: inboxValue, shouldDisplayAlertIcon }
            ],
            index
          ) => {
            const typedInboxKey = inboxKey as InboxTabs;
            return (
              <Button
                key={'inbox-category' + index}
                bgColor={
                  selectedTab === typedInboxKey ? 'white' : 'transparent'
                }
                onClick={() => {
                  setSelectedTab(typedInboxKey);
                  setSelectedItem(null);
                }}
                fullWidth
                id={`inbox-category-${kebabCase(inboxLabel)}`}
              >
                <Flex flex={1} justifyContent="space-between">
                  <Typography variant="h3" color={Colors.black}>
                    {t(inboxLabel)}
                  </Typography>
                  <Typography variant="h3" color={Colors.black}>
                    {inboxValue === undefined ? (
                      <Loader
                        size={iconSizes.xsmall}
                        colorBase={Colors.black}
                      />
                    ) : (
                      inboxValue
                    )}
                    {shouldDisplayAlertIcon && <AlertIconBadge />}
                  </Typography>
                </Flex>
              </Button>
            );
          }
        )}
        <Box marginTop={spacings.x2large} marginBottom={spacings.medium}>
          <SearchField
            onChange={(value) => setSearchTerm(value)}
            value={searchTerm}
          />
        </Box>
      </Flex>
      <Flex
        id="inbox-recents-list"
        flexDirection="column"
        gap={spacings.large}
        overflow="auto"
      >
        {filteredData?.map((item, index) => {
          return (
            <InboxItemCard
              key={'inbox-item-' + index}
              shadow
              onClick={() => {
                inboxData[selectedTab].handleClickItem(item);
                setIsNavbarDrawerOpen(false);
              }}
            >
              <Flex alignItems="center" gap={spacings.xlarge} height="100%">
                {inboxData[selectedTab]?.type === InboxTypes.Message && (
                  <MessageInboxCard
                    message={item as MessageRecent}
                    selectedTab={selectedTab}
                  />
                )}
                {inboxData[selectedTab]?.type === InboxTypes.Task && (
                  <TaskInboxCard task={item as Task} />
                )}
              </Flex>
            </InboxItemCard>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default InboxRecentsList;
