import { FC } from 'react';
import { styled } from '@mui/material';

import { Appointment } from 'src/types/appointment';
import { makeShouldForwardProps } from 'src/components/utils';
import { spacings } from 'src/components/styles/constants';
import Card from 'src/components/display/Card';

import { DailyWorkList } from './DailyWorkList';
import { PatientQuickView } from './PatientQuickView';
import { useDailyWorkListDrawer } from 'src/contexts/AppContexts/AppContexts';
import { DailyWorkListDrawerViews } from 'src/contexts/AppContexts/types';

const shouldForwardProp = makeShouldForwardProps(['backgroundColor']);
export const DrawerContainerCard = styled(Card, {
  shouldForwardProp
})<{
  backgroundColor?: string;
}>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  min-height: calc(100% - (${spacings.xlarge} * 2));
  padding: ${spacings.xlarge};
  display: flex;
  flex-direction: column;
  gap: ${spacings.large};
`;

export const DailyWorkListDrawer: FC = () => {
  const {
    dailyWorklistDate: date,
    dailyWorklistView: view,
    dailyWorklistPatientId: patientId,
    dailyWorklistAppointment: appointment,
    onDailyWorklistDateChange: onDateChange,
    onDailyWorklistViewChange: onViewChange,
    onDailyWorklistPatientIdChange: onPatientIdSelect,
    onDailyWorklistAppointmentChange: onAppointmentSelect
  } = useDailyWorkListDrawer();

  const handlePatientSelect = ({
    patientId,
    appointment
  }: {
    patientId: string;
    appointment?: Appointment | null;
  }) => {
    onPatientIdSelect(patientId);
    onAppointmentSelect(appointment);
    onViewChange(DailyWorkListDrawerViews.PATIENT);
  };

  const handleBack = () => {
    onPatientIdSelect(null);
    onAppointmentSelect(null);
    onViewChange(DailyWorkListDrawerViews.DAILY);
  };

  switch (view) {
    case DailyWorkListDrawerViews.PATIENT:
      return (
        <PatientQuickView
          patientId={patientId}
          appointment={appointment}
          onBackClick={handleBack}
        />
      );
    case DailyWorkListDrawerViews.DAILY:
    default:
      return (
        <DailyWorkList
          date={date}
          onDateChange={onDateChange}
          onRowClick={handlePatientSelect}
        />
      );
  }
};
