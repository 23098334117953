import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import {
  getMessagesRequest,
  getPatientChannel,
  sendMessageRequest
} from 'src/api/messages.api';
import {
  getInboxRecentsRequest,
  markAsReadRequest
} from 'src/api/patients.api';
import { AppError } from 'src/types/global';
import { Channel, ChannelTypes, Message, MessageRecent } from 'src/types/inbox';
import { queryKeys, querySubKeys } from './queryKeys';
import { useToast } from 'src/contexts/UIContexts';

function useMessagesApi() {
  const { t } = useTranslation();
  const { handleQueryResultToast } = useToast();
  const queryClient = useQueryClient();

  return {
    getPatientChannel: (
      patientId: string,
      type: ChannelTypes,
      options?: UseQueryOptions<Channel, AppError>
    ) =>
      useQuery<Channel, AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].CHANNELS,
          type
        ],
        () => getPatientChannel(patientId, type),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_CHANNEL')
            }),
          ...options
        }
      ),
    getMessages: (
      channelId: string,
      options?: UseQueryOptions<Message[], AppError>
    ) =>
      useQuery<Message[], AppError>(
        [
          queryKeys.CHANNELS,
          channelId,
          querySubKeys[queryKeys.CHANNELS].MESSAGES
        ],
        () => getMessagesRequest(channelId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_MESSAGES')
            }),
          ...options
        }
      ),

    sendChannelMessage: () =>
      useMutation<
        void,
        AppError,
        { patientId: string; message: string; channelId: string }
      >(
        ({ patientId, message, channelId }) =>
          sendMessageRequest(patientId, message, channelId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_SEND_MESSAGE')
            }),
          onSuccess: (_, { channelId }) => {
            queryClient.invalidateQueries([
              queryKeys.CHANNELS,
              channelId,
              querySubKeys[queryKeys.CHANNELS].MESSAGES
            ]);
          }
        }
      ),
    markAsRead: () =>
      useMutation<string, AppError, { message: Message }>(
        ({ message }) =>
          markAsReadRequest({
            messageId: message.id,
            patientId: message.patientId
          }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_MARK_AS_READ')
            }),
          onSuccess: (_, {}) => {
            queryClient.invalidateQueries([
              queryKeys.INBOX,
              querySubKeys[queryKeys.INBOX].RECENTS
            ]); // remove unread indicator from sidebar
            queryClient.invalidateQueries([
              queryKeys.CLINICS,
              querySubKeys[queryKeys.CLINICS].DASHBOARD_SUMMARY
            ]);
          }
        }
      ),
    getInboxRecents: (
      type: ChannelTypes,
      options?: UseQueryOptions<MessageRecent[], AppError>
    ) =>
      useQuery<MessageRecent[], AppError>(
        [queryKeys.INBOX, querySubKeys[queryKeys.INBOX].RECENTS, type],
        () => getInboxRecentsRequest(type),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_RECENTS')
            }),
          ...options
        }
      )
  };
}

export default useMessagesApi;
