import { FC, useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GridColDef } from '@mui/x-data-grid-premium';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useFeatureFlag } from 'configcat-react';

import Table from 'src/components/display/Table/Table';
import Card from 'src/components/display/Card/Card';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import Flex from 'src/components/layout/Flex/Flex';
import { fontWeights } from 'src/components/styles/fonts';
import MiniIconButton from 'src/components/display/MiniIconButton/MiniIconButton';
import {
  DocumentEditorParams,
  DocumentExtension,
  DocumentTypes,
  PatientDocument,
  UpperCaseDocumentExtension
} from 'src/types/documents';
import DeleteDocumentDialog from './DeleteDocumentDialog';
import dayjs from 'dayjs';
import Chip from 'src/components/data-entry/Chips/Chip';
import { getDocumentSignatureStatus } from '../../documents/utils/editorUtils/getDocumentSignatureStatus';
import Menu from 'src/components/display/Menu';
import InputField from 'src/components/data-entry/InputField';
import Button from 'src/components/display/Button/Button';
import { getDateFormat, longDateTimeFormat } from 'src/utils/dateAndTIme';
import { phoneRegex } from '../utils/validations-regex';
import { AddDocumentForm } from '../actionMenu/AddDocumentForm';
import useDocumentsApi from '../../../hooks/useDocumentsApi';
import useEFaxApi from 'src/hooks/useEFaxApi';
import { DocumentMenuProps, SendFaxDetails } from './types';
import Box from 'src/components/layout/Box';
import { Colors } from '../../../components/styles/colors';
import { FeatureFlags } from 'src/types/featureFlags';
import {
  getDocumentExtension,
  getUpperCaseDocumentExtension
} from 'src/utils/general';
import EditDocumentDialog from './EditDocumentDialog';
import { useDialog, usePopover } from 'src/contexts/UIContexts';

const StyledTable = styled(Table)`
  .MuiDataGrid-columnHeaders,
  .MuiDataGrid-row {
    color: ${Colors.emperor};
    padding-inline: 0;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: ${fontWeights.extraBold};
  }
  .MuiDataGrid-cell:not(:first-of-type) {
    font-weight: ${fontWeights.extraBold};
  }
  .MuiPaginationItem-root {
    margin-right: 20px;
  }
`;

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;

const defaultValues: SendFaxDetails = {
  destination: {
    faxNumber: '',
    toName: '',
    toCompany: ''
  },
  document: {
    documentType: UpperCaseDocumentExtension.PDF,
    documentContent: ''
  },
  faxOptions: {
    includeCoverPage: false,
    coverPageOptions: {
      subject: '',
      message: ''
    }
  }
};

const EFaxForm: FC<{
  documentUrl: string;
  documentId: string;
  patientId: string;
  documentExtension?: DocumentExtension;
}> = ({
  documentUrl,
  documentId,
  patientId,
  documentExtension = DocumentExtension.PDF
}) => {
  const [base64, setBase64] = useState<string>('');

  const { t } = useTranslation();
  const { sendEFax } = useEFaxApi();
  const { closeDialog } = useDialog();
  const {
    mutate: sendEFaxMutate,
    isLoading: isSendingEFax,
    isSuccess
  } = sendEFax();
  const { control, formState, handleSubmit, setValue, getValues } =
    useForm<SendFaxDetails>({
      mode: 'onChange',
      defaultValues
    });
  const { errors } = formState;

  const onSubmit = async (details: SendFaxDetails) => {
    const documentType: UpperCaseDocumentExtension = getValues(
      'document.documentType'
    );

    const updatedEFaxDetails: SendFaxDetails = {
      ...details,
      document: {
        documentType,
        documentContent: base64
      },
      faxOptions: {
        ...details.faxOptions,
        includeCoverPage: !!(
          details.faxOptions?.coverPageOptions?.subject ||
          details.faxOptions?.coverPageOptions?.message
        )
      }
    };

    await sendEFaxMutate({
      faxDetails: updatedEFaxDetails,
      patientId,
      documentId
    });
  };

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const response = await fetch(documentUrl);
        const blob = await response.blob();
        const mimeType =
          documentExtension == DocumentExtension.PDF
            ? 'application/pdf'
            : `image/${documentExtension}`;

        const pdfBlob = new Blob([blob], { type: mimeType });
        const documentType: UpperCaseDocumentExtension =
          getUpperCaseDocumentExtension(documentExtension);

        setValue('document.documentType', documentType);

        const reader = new FileReader();

        reader.onloadend = () => {
          let base64String = reader.result as string;
          base64String = base64String.split(',')[1];

          setBase64(base64String);
        };

        reader.readAsDataURL(pdfBlob);
      } catch (error) {
        console.error('Error fetching the document:', error);
      }
    };
    fetchDoc();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Flex
        flexDirection="column"
        gap={spacings.large}
        marginTop={spacings.xlarge}
      >
        <Typography fontWeight={fontWeights.extraBold} variant="h3">
          {t('SEND_TO_NEW_CONTENT').toLocaleUpperCase()}
        </Typography>
        <Controller
          name="destination.toName"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputField
              {...field}
              inputRef={ref}
              placeholder={t('NAME')}
              error={!!errors?.destination?.toName}
              helperText={errors?.destination?.toName?.message}
              required
              fullWidth
            />
          )}
        />
        <Flex gap={spacings.x2large}>
          <Controller
            name="destination.toCompany"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                placeholder={t('COMPANY')}
                error={!!errors?.destination?.toCompany}
                helperText={errors?.destination?.toCompany?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="destination.faxNumber"
            control={control}
            rules={{
              pattern: {
                value: phoneRegex,
                message: t('FAX_NUMBER_INVALID')
              }
            }}
            render={({ field: { ref, ...field } }) => (
              <InputField
                {...field}
                inputRef={ref}
                placeholder={t('FAX_NUMBER')}
                error={!!errors?.destination?.faxNumber}
                helperText={errors?.destination?.faxNumber?.message}
                fullWidth
                required
              />
            )}
          />
        </Flex>
        <Typography fontWeight={fontWeights.extraBold} variant="h3">
          {t('SEND_COVER_LETTER').toLocaleUpperCase()}
        </Typography>
        <Controller
          name="faxOptions.coverPageOptions.subject"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputField
              {...field}
              inputRef={ref}
              placeholder={t('SUBJECT')}
              error={!!errors?.faxOptions?.coverPageOptions?.subject}
              helperText={
                errors?.faxOptions?.coverPageOptions?.subject?.message
              }
              fullWidth
            />
          )}
        />
        <Controller
          name="faxOptions.coverPageOptions.message"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <InputField
              {...field}
              inputRef={ref}
              placeholder={t('MESSAGE')}
              error={!!errors?.faxOptions?.coverPageOptions?.message}
              helperText={
                (
                  errors?.faxOptions?.coverPageOptions?.message as
                    | FieldError
                    | undefined
                )?.message
              }
              fullWidth
              multiline
              rows={4}
            />
          )}
        />
        <Flex justifyContent="center" marginTop={spacings.large}>
          <Box width="50%">
            <Button fullWidth type="submit" disabled={isSendingEFax}>
              {t('SEND')}
            </Button>
          </Box>
        </Flex>
      </Flex>
    </form>
  );
};

export const PatientGeneralDocumentsTable: FC<{
  patientId: string;
  partnerId?: string;
  isPartner?: boolean;
  onDocumentsModalOpen?: () => void;
  onDocumentEditorInfoChange?: (info: DocumentEditorParams) => void;
  documentEditorInfo?: DocumentEditorParams;
}> = ({
  patientId,
  partnerId,
  isPartner,
  onDocumentsModalOpen,
  onDocumentEditorInfoChange
}) => {
  const { t } = useTranslation();
  const { getPatientGeneralDocuments } = useDocumentsApi();
  const { openDialog } = useDialog();
  const { openPopover } = usePopover();
  const { value: enableEFax } = useFeatureFlag(FeatureFlags.ENABLE_EFAX, false);

  const handleTaskNameClick = ({
    templateId,
    id
  }: {
    templateId: string;
    id: string;
  }) => {
    onDocumentEditorInfoChange({
      patientId,
      templateId: templateId || null,
      documentId: id
    });

    onDocumentsModalOpen();
  };

  const handleMenuClick = ({
    event,
    id,
    url,
    extension,
    templateId,
    isCompleted,
    documentType,
    documentName
  }: DocumentMenuProps) => {
    onDocumentEditorInfoChange({
      patientId,
      templateId: templateId || null,
      documentId: id
    });

    openPopover({
      anchorEl: event.currentTarget,
      hideToolbar: true,
      children: (
        <Menu
          items={[
            {
              label: t('VIEW_SIGN_DOCUMENT_TITLE'),
              action: () => {
                onDocumentsModalOpen();
              }
            },
            ...(documentType !== DocumentTypes.MEDICAL_NOTES
              ? [
                  {
                    label: t('DELETE'),
                    action: () =>
                      openDialog({
                        header: t('DELETE_DOCUMENT_TITLE'),
                        children: (
                          <DeleteDocumentDialog
                            documentId={id}
                            patientId={patientId}
                          />
                        ),
                        fullWidth: true
                      })
                  }
                ]
              : []),
            {
              label: t('EDIT_NAME'),
              action: () => {
                openDialog({
                  header: t('EDIT_DOCUMENT'),
                  children: (
                    <EditDocumentDialog
                      documentId={id}
                      documentType={documentType}
                      patientId={patientId}
                      originalFileName={documentName}
                    />
                  ),
                  fullWidth: true
                });
              }
            },
            ...(enableEFax && isCompleted
              ? [
                  {
                    label: 'eFax',
                    action: () =>
                      openDialog({
                        header: t('EFAX_DOCUMENT_TITLE'),
                        children: (
                          <EFaxForm
                            documentUrl={url}
                            documentId={id}
                            patientId={patientId}
                            documentExtension={extension}
                          />
                        ),
                        fullWidth: true,
                        maxWidth: 'sm'
                      })
                  }
                ]
              : [])
          ]}
        />
      ),
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom'
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'top'
      }
    });
  };

  const {
    data: patientDocuments,
    isLoading: isLoadingPatientDocuments,
    refetch: refetchPatientsDocuments,
    isFetching: isFetchingPatientDocuments
  } = getPatientGeneralDocuments(patientId);

  const refetchPartnerDocuments = getPatientGeneralDocuments(partnerId, {
    enabled: !!partnerId
  }).refetch;

  const isLoading = isLoadingPatientDocuments || isFetchingPatientDocuments;

  const columns: GridColDef<PatientDocument>[] = [
    {
      field: 'menu',
      headerName: t('ACTIONS').toLocaleUpperCase(),
      renderHeader: () => null,
      flex: 0.5,
      sortable: false,
      align: 'center',
      filterable: false,
      valueGetter: (_, row) => row.id,
      renderCell: ({ row }) => {
        const extension = Object.values(DocumentExtension).find((ext) => {
          const documentExtension = getDocumentExtension(ext);

          return row.name.endsWith(`.${documentExtension}`);
        });

        return (
          <MiniIconButton
            icon={<MoreVertIcon />}
            onClick={(event) => {
              return handleMenuClick({
                event,
                extension,
                templateId: row.templateId,
                id: row.id,
                url: row.url,
                isCompleted: row.isCompleted,
                documentType: row.documentType,
                documentName: row.name
              });
            }}
          />
        );
      }
    },
    {
      field: 'createdAt',
      flex: 2,
      headerName: t('ADDED_DATE').toLocaleUpperCase(),
      type: 'date' as const,
      valueFormatter: (value) => dayjs(value).format(getDateFormat()),
      sortComparator: (v1, v2) => dayjs(v1).unix() - dayjs(v2).unix()
    },
    {
      field: 'name',
      flex: 5,
      headerName: t('NAME').toLocaleUpperCase(),
      renderCell: ({ row: { name, createdAt, templateId, id } }) => {
        return (
          <StyledTypography
            onClick={() => handleTaskNameClick({ templateId, id })}
          >{`${name} - ${dayjs(createdAt).format(
            longDateTimeFormat
          )}`}</StyledTypography>
        );
      }
    },
    {
      field: 'isCompleted',
      flex: 5,
      headerName: t('STATUS').toLocaleUpperCase(),
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: ({
        row: { isCompleted, isInitialVendorEmailSent, documentType }
      }) => {
        const { color, status } =
          getDocumentSignatureStatus({
            isDocumentCompleted: isCompleted,
            isInitialVendorEmailSent
          }) || {};

        if (documentType === DocumentTypes.GENERAL) return null;

        return (
          <Chip
            label={t(status).toLocaleUpperCase()}
            backgroundColor={color}
            fontWeight={fontWeights.extraBold}
          />
        );
      }
    }
  ];

  return (
    <Card shadow marginBottom={spacings.x2large}>
      <StyledTable
        columns={columns}
        columnHeaderHeight={50}
        rows={patientDocuments || []}
        paginationModel={{ page: 0, pageSize: 25 }}
        loading={isLoading}
        toolbar={
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Typography
              paddingY={spacings.large}
              fontWeight={fontWeights.extraBold}
            >
              {t(
                isPartner ? 'PARTNER_GENERAL_DOCUMENTS' : 'GENERAL_DOCUMENTS'
              ).toLocaleUpperCase()}
            </Typography>
            <MiniIconButton
              icon={<AddIcon />}
              onClick={() => {
                openDialog({
                  header: t('CHOOSE_A_DOCUMENT'),
                  children: (
                    <AddDocumentForm
                      patientId={patientId}
                      defaultDocumentType={DocumentTypes.GENERAL}
                      onSuccess={() => {
                        refetchPartnerDocuments();
                        refetchPatientsDocuments();
                      }}
                    />
                  ),
                  maxWidth: 'md'
                });
              }}
            />
          </Flex>
        }
        autoHeight
        freeHeight
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'createdAt',
                sort: 'desc'
              }
            ]
          }
        }}
      />
    </Card>
  );
};
