import axiosApiInstance from 'src/utils/axios';
import {
  BillingServiceCode,
  BillingServiceCodeServer,
  BillingServiceParams,
  EncounterServiceCode,
  EncounterServiceCodeType,
  Icd10Code,
  Modifier,
  ModifierServiceCodeType,
  NDCCode,
  NihIcd10ApiResultIndices,
  PaginatedApiRequestStructure
} from './../types/codes';
import axios from 'axios';
import { InfiniteQueryResponse } from 'src/types/global';
import { convertBillingServiceCodeServerToClient } from 'src/modules/patients/utils/conversions';

export async function getModifiersRequest(): Promise<Modifier[]> {
  const response = await axiosApiInstance.get<Modifier[]>(
    `billing-services?billingCodeType=${ModifierServiceCodeType.MOD}`
  );
  return response.data;
}

export async function getEncounterServiceCodesRequest(): Promise<
  EncounterServiceCode[]
> {
  const response = await axiosApiInstance.get<EncounterServiceCode[]>(
    `billing-services?billingCodeType=${EncounterServiceCodeType.CPT}&billingCodeType=${EncounterServiceCodeType.HCPCS}`
  );

  return response.data;
}

export async function getNdcCodesRequest(): Promise<NDCCode[]> {
  // TODO - request data from the server

  return [];
}

export async function getIcd10CodesRequest({
  searchTerm,
  page,
  pageSize
}: PaginatedApiRequestStructure): Promise<InfiniteQueryResponse<Icd10Code>> {
  const response = await axios.get(
    `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search`,
    {
      params: {
        terms: searchTerm,
        maxList: pageSize,
        offset: page * pageSize || 0,
        sf: 'code,name'
      }
    }
  );

  const arrayOfOptions = response.data[NihIcd10ApiResultIndices.RESULTS];

  const icd10Options: Icd10Code[] = arrayOfOptions.map((icd10: string[]) => {
    const code = icd10[NihIcd10ApiResultIndices.CODE];
    const description = icd10[NihIcd10ApiResultIndices.DESCRIPTION];
    return {
      code,
      description,
      label: `${code} - ${description}`
    };
  });
  return {
    results: icd10Options,
    totalResults: response.data[NihIcd10ApiResultIndices.TOTAL_RESULTS]
  };
}

export async function getBillingServicesRequest(
  params: BillingServiceParams
): Promise<BillingServiceCode[]> {
  const response = await axiosApiInstance.get<BillingServiceCodeServer[]>(
    `billing-services`,
    {
      params
    }
  );
  return response.data?.map(convertBillingServiceCodeServerToClient);
}
