import { GridColDef } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableProps } from 'src/components/display/Table/Table';
import Typography from 'src/components/display/Typography/Typography';
import Box from 'src/components/layout/Box/Box';
import Flex from 'src/components/layout/Flex/Flex';
import { iconSizes, spacings } from 'src/components/styles/constants';
import { fontWeights } from 'src/components/styles/fonts';
import { TreatmentGridHeaders } from 'src/types/patient';
import { TreatmentUltrasoundDialog } from './TreatmentUltrasoundDialog';
import { getDateFormat } from 'src/utils/dateAndTIme';
import { AddEditFollicularExamForm } from '../medicalHistory/AddEditFollicularForm';
import { useParams } from 'react-router-dom';
import { CycleDayNotes } from './CycleDayNotes';
import {
  ConfirmDeletePrescription,
  TreatmentEditMedicationForm,
  checkIsMedicationStoppingCompletely
} from './TreatmentEditMedicationForm';
import { Colors } from 'src/components/styles/colors';
import useLabResults from 'src/hooks/useLabResults';
import IconButton from 'src/components/display/IconButton';
import StopIcon from '@mui/icons-material/Stop';
import usePrescription from 'src/hooks/usePrescription';
import Loader from 'src/components/display/Loader';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import { CycleData, CycleGraphData, CycleStatus } from 'src/types/cycle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LabResultEditForm } from './LabResultEditForm';
import useCycle from '../../../hooks/useCycle';
import Center from '../../../components/layout/Center';
import { styled } from '@mui/material';
import { usePopover, useDialog } from 'src/contexts/UIContexts';
import { GenerateDocumentButton } from '../medicalHistory/GenerateDocumentButton';

interface rowGridDef {
  id: string;
  type: string;
  [key: string]: string | number;
}

interface GraphTableProps extends Omit<TableProps, 'columns'> {
  chartLeftMargin: number;
  yAxisWidth: number;
  data: CycleGraphData;
  columnWidth: number;
}

const StyledTable = styled(Table)`
  .MuiDataGrid-cell[aria-colindex='1'] {
    border: none;
  }
`;

const StyledBox = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
`;

const getCellContent = (
  rowType: string,
  {
    afcLeft,
    afcRight,
    endometrium,
    Estradiol,
    LH,
    Progesterone,
    FSH,
    follicularExamId,
    notes,
    medications
  }: CycleData
) => {
  switch (rowType) {
    case TreatmentGridHeaders.FOLLICLES:
      return {
        value: follicularExamId ? `${afcLeft || '-'}/${afcRight || '-'}` : '',
        examId: follicularExamId
      };
    case TreatmentGridHeaders.ENDOMETRIUM:
      return {
        value: endometrium,
        examId: follicularExamId
      };
    case TreatmentGridHeaders.E2:
      return Estradiol;
    case TreatmentGridHeaders.LH:
      return LH;
    case TreatmentGridHeaders.Progesterone:
      return Progesterone;
    case TreatmentGridHeaders.FSH:
      return FSH;
    case TreatmentGridHeaders.NOTES:
      return notes;
    case TreatmentGridHeaders.WITNESSED_TASKS:
      return '';
    default:
      return medications?.[rowType] || '';
  }
};

interface EditMedicationPopoverHeaderProps {
  prescriptionId: string;
  title: string;
  patientId: string;
  cycleId: string;
  date: string;
}

const EditMedicationPopoverHeader: FC<EditMedicationPopoverHeaderProps> = ({
  prescriptionId,
  title,
  patientId,
  cycleId,
  date
}) => {
  const { t } = useTranslation();
  const { closeAllPopovers } = usePopover();
  const { openDialog, closeDialog } = useDialog();
  const { getPrescriptionById, updatePrescriptionDosage } = usePrescription();

  const { data: prescription, isLoading } = getPrescriptionById(prescriptionId);

  const {
    mutate: handleUpdatePrescriptionDosage,
    isLoading: isUpdatingPrescription
  } = updatePrescriptionDosage();

  const stopPrescription = async () => {
    const details = {
      prescriptionId,
      dosage: 0,
      patientId,
      cycleId,
      startDate: new Date(date)
    };

    const isPrescriptionStoppingCompletely =
      checkIsMedicationStoppingCompletely(details, prescription);

    if (isPrescriptionStoppingCompletely) {
      openDialog({
        header: t('ARE_YOU_SURE_YOU_WANT_TO_PROCEED'),
        children: (
          <ConfirmDeletePrescription
            onConfirm={async () => {
              await handleUpdatePrescriptionDosage(details, {
                onSuccess: () => {
                  closeAllPopovers();
                  closeDialog();
                }
              });
            }}
          />
        )
      });
      return;
    }

    await handleUpdatePrescriptionDosage(details, {
      onSuccess: () => closeAllPopovers()
    });
  };

  const hideStopButton =
    prescription?.cycle?.isHistorical ||
    prescription?.cycle?.status === CycleStatus.PENDING;

  return (
    <Flex justifyContent="space-between" width="100%">
      <Typography fontWeight={fontWeights.extraBold}>{title}</Typography>
      {!hideStopButton && (
        <IconButton
          disabled={isUpdatingPrescription || isLoading}
          icon={
            isUpdatingPrescription || isLoading ? (
              <Loader size={iconSizes.xsmall} />
            ) : (
              <TooltipWrapper title={t('STOP_MEDICATION')}>
                <StopIcon sx={{ color: Colors.mineShaft }} />
              </TooltipWrapper>
            )
          }
          onClick={stopPrescription}
          bgColor={isUpdatingPrescription || isLoading ? 'transparent' : 'alto'}
          iconSize="xsmall"
        />
      )}
    </Flex>
  );
};

export const GraphTable: FC<GraphTableProps> = ({
  chartLeftMargin,
  yAxisWidth,
  data: { data, medicationsList } = {},
  columnWidth,
  ...rest
}) => {
  const { patientId, cycleId } = useParams();

  const { t } = useTranslation();
  const { openPopover, closePopover } = usePopover();
  const { deleteLabResult } = useLabResults();
  const { getCycleById } = useCycle();
  const { openDialog } = useDialog();

  const { mutate: handleDeleteLabResult, isLoading: isDeletingLabResult } =
    deleteLabResult();

  const { data: cycle, isLoading: isLoadingCycle } = getCycleById(cycleId, {
    enabled: !!cycleId
  });

  const chartWidth =
    columnWidth * (data?.length || 0) + yAxisWidth + chartLeftMargin;

  const getTreatmentGridHeaderTranslation = (type: string, row: rowGridDef) => {
    const resultWithUnits: any = Object.values(row)?.find(
      (cellContent: any) => !!cellContent?.measurementType
    );

    let header = '';
    switch (type) {
      case TreatmentGridHeaders.FOLLICLES:
        header = t('FOLLICLES (L/R)');
        break;
      case TreatmentGridHeaders.ENDOMETRIUM:
        header = t('ENDOMETRIUM');
        break;
      case TreatmentGridHeaders.E2:
        header = t('E2');
        break;
      case TreatmentGridHeaders.LH:
        header = t('LH');
        break;
      case TreatmentGridHeaders.Progesterone:
        header = t('PG');
        break;
      case TreatmentGridHeaders.FSH:
        header = t('FSH');
        break;
      case TreatmentGridHeaders.NOTES:
        header = t('NOTES').toUpperCase();
        break;
      case TreatmentGridHeaders.WITNESSED_TASKS:
        header = t('WITNESS').toUpperCase();
        break;
      default:
        header = type?.toUpperCase();
    }

    return {
      header,
      unit: resultWithUnits?.measurementType
    };
  };

  const deleteLabResultHandler = async (id: string) => {
    await handleDeleteLabResult({
      id,
      patientId,
      cycleId
    });

    closePopover();
  };

  const disableMedicationEdit =
    cycle?.isHistorical || cycle?.status === CycleStatus.PENDING;

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: '',
      width: chartLeftMargin + yAxisWidth,
      minWidth: 0,
      align: 'right',
      renderCell: (params) => {
        const { header, unit } = getTreatmentGridHeaderTranslation(
          params.row.type,
          params.row
        );
        return (
          <Flex
            gap={spacings.small}
            alignItems="center"
            paddingRight={spacings.small}
          >
            <TooltipWrapper title={header} arrow placement="top">
              <StyledBox>
                <Typography truncated fontWeight={fontWeights.extraBold}>
                  {header}
                </Typography>
                <Typography>{unit}</Typography>
              </StyledBox>
            </TooltipWrapper>
          </Flex>
        );
      }
    },
    ...(data?.map((day) => {
      return {
        field: dayjs(day.date).toString(),
        headerName: '',
        width: columnWidth,
        minWidth: 0,
        maxWidth: columnWidth,
        align: 'center',
        valueGetter: (_, row) => row[dayjs(day.date).toString()]?.value,
        renderCell: (params) => {
          const date = dayjs(day.date).toString();
          const examId = params.row[date]?.examId;
          const labResultsId = params.row[date]?.labResultId;
          const value = params.row[date]?.value;

          switch (params.row.type) {
            case TreatmentGridHeaders.FOLLICLES:
            case TreatmentGridHeaders.ENDOMETRIUM:
              return (
                <Box
                  sx={{ cursor: examId ? 'pointer' : '' }}
                  onClick={(ev) => {
                    if (!examId) return;

                    openPopover({
                      children: <TreatmentUltrasoundDialog examId={examId} />,
                      anchorEl: ev.currentTarget,
                      header: `${t('ULTRASOUND').toUpperCase()} ${dayjs(
                        day.date
                      ).format(getDateFormat())}`,
                      paperPadding: spacings.large,
                      onEditClick: () => {
                        openDialog({
                          header: t('ADD_FOLLICULAR_SCAN'),
                          children: (
                            <AddEditFollicularExamForm
                              patientId={patientId}
                              examinationId={examId}
                            />
                          ),
                          maxWidth: 'lg',
                          fullWidth: true,
                          headerActions: [
                            <GenerateDocumentButton
                              key="generateDocument"
                              examId={examId}
                              patientId={patientId}
                            />
                          ]
                        });
                        closePopover();
                      }
                    });
                  }}
                >
                  {value}
                </Box>
              );
            case TreatmentGridHeaders.LH:
            case TreatmentGridHeaders.Progesterone:
            case TreatmentGridHeaders.FSH:
            case TreatmentGridHeaders.E2:
              return (
                <Box
                  onClick={(ev) => {
                    openPopover({
                      closeTooltipTitle: t('CANCEL_ACTION'),
                      headerChildren: (
                        <Flex justifyContent="space-between" width="100%">
                          <Typography fontWeight={fontWeights.extraBold}>
                            {params.row.type?.toUpperCase()}
                          </Typography>
                          <IconButton
                            icon={
                              isDeletingLabResult ? (
                                <Loader size={iconSizes.xsmall} />
                              ) : (
                                <TooltipWrapper title={t('DELETE_RESULT')}>
                                  <DeleteForeverIcon
                                    sx={{ color: Colors.mineShaft }}
                                  />
                                </TooltipWrapper>
                              )
                            }
                            onClick={async () => {
                              if (!params.row[date]?.labResultId) return;

                              await deleteLabResultHandler(
                                params.row[date]?.labResultId
                              );
                            }}
                            bgColor={
                              isDeletingLabResult ? 'transparent' : 'alto'
                            }
                            iconSize="xsmall"
                          />
                        </Flex>
                      ),
                      children: (
                        <LabResultEditForm
                          resultId={params.row[date]?.labResultId}
                          disabled={isDeletingLabResult}
                        />
                      ),
                      anchorEl: ev.currentTarget,
                      paperPadding: spacings.large
                    });
                  }}
                  sx={{ cursor: labResultsId ? 'pointer' : '' }}
                >
                  {value}
                </Box>
              );

            case TreatmentGridHeaders.NOTES:
              return <CycleDayNotes date={day.date} initialNotes={day.notes} />;
            case TreatmentGridHeaders.WITNESSED_TASKS:
              return (
                <Box
                  width="100%"
                  height="calc(100% - 2px)"
                  borderBottom={`solid 3px ${Colors.mineShaft}`}
                />
              );
            default:
              return (
                <Box
                  sx={{
                    cursor:
                      params.row[date]?.id && !disableMedicationEdit
                        ? 'pointer'
                        : ''
                  }}
                  onClick={(ev) => {
                    if (disableMedicationEdit) {
                      return;
                    }
                    openPopover({
                      closeTooltipTitle: t('CANCEL_ACTION'),
                      headerChildren: (
                        <EditMedicationPopoverHeader
                          cycleId={cycleId}
                          date={date}
                          patientId={patientId}
                          prescriptionId={params.row[date]?.id}
                          title={params.row.type}
                        />
                      ),
                      children: (
                        <TreatmentEditMedicationForm
                          date={new Date(date)}
                          id={params.row[date]?.id}
                          dosage={params.row[date]?.dosage}
                        />
                      ),
                      anchorEl: ev.currentTarget,
                      paperPadding: spacings.large
                    });
                  }}
                >
                  {params.row[date]?.dosage || ''}
                </Box>
              );
          }
        }
      } as GridColDef;
    }) || [])
  ];

  const rows: rowGridDef[] = useMemo(
    () =>
      [
        ...Object.values(TreatmentGridHeaders).map((type) => ({
          label: type,
          value: type
        })),
        ...medicationsList.map(({ medicationName, id, unit }) => ({
          label: `${medicationName}${unit ? ` (${unit})` : ''}`,
          value: id
        }))
      ].map(({ label, value }, index) => ({
        id: `${index}`,
        type: label,
        ...(data?.reduce((acc, day) => {
          acc[dayjs(day.date).toString()] = getCellContent(value, day);
          return acc;
        }, {}) || [])
      })),
    [data, medicationsList]
  );

  if (isLoadingCycle) {
    return (
      <Center minHeight={200}>
        <Loader size={iconSizes.large} />
      </Center>
    );
  }

  return (
    <Box minWidth={chartWidth}>
      <StyledTable
        columns={columns}
        rows={rows}
        variant="grid"
        getRowHeight={() => 25}
        hasBorder
        hideFooter
        hideHeaders
        freeHeight
        autoHeight
        paginationModel={{ pageSize: 100, page: 0 }}
        hideFilterToolbar
        {...rest}
      />
    </Box>
  );
};
