import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'configcat-react';
import { Box, css, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AppsIcon from '@mui/icons-material/Apps';

import { FeatureFlags } from 'src/types/featureFlags';
import { patientRoutes } from 'src/router/routes';
import { spacings } from 'src/components/styles/constants';
import Flex from 'src/components/layout/Flex/Flex';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';
import IconButton from 'src/components/display/IconButton';
import Button from 'src/components/display/Button/Button';

import HeaderUserbox from './Userbox';
import { PatientsSearchBar } from './PatientsSearchBar';
import {
  useDailyWorkListDrawer,
  useNavbar
} from 'src/contexts/AppContexts/AppContexts';

const HeaderWrapper = styled(Box)`
  ${({ theme }) => css`
    padding: ${spacings.medium} 0;
    justify-content: space-between;
    margin-bottom: ${spacings.large};
    width: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      left: ${theme.spacing(12)};
      width: auto;
    }
  `}
`;

function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { value: enableWorkListDrawer } = useFeatureFlag(
    FeatureFlags.ENABLE_WORK_LIST_DRAWER,
    false
  );

  const { isNavbarDrawerOpen, setIsNavbarDrawerOpen } = useNavbar();
  const { onDailyWorklistDrawerOpenChange: onDrawerOpenChange } =
    useDailyWorkListDrawer();

  return (
    <HeaderWrapper alignItems="center">
      <Flex alignItems="center" width="100%">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex gap={spacings.medium} width="50%">
            <PatientsSearchBar
              onSelectPatient={(patientId) =>
                navigate(patientRoutes.getByIdLink(patientId))
              }
              variant={SearchFieldVariants.STYLED}
            />
            <Button
              startIcon={<AddIcon />}
              onClick={() => navigate(patientRoutes.getAddLink())}
              id="add-new-patient-button"
            >
              {t('ADD_NEW_PATIENT')}
            </Button>
          </Flex>
          <Flex alignItems="center" gap={spacings.medium}>
            {enableWorkListDrawer && (
              <IconButton
                iconColor="emperor"
                bgColor="transparent"
                size="large"
                onClick={() => onDrawerOpenChange(true)}
              >
                <AppsIcon fontSize="large" />
              </IconButton>
            )}
            <HeaderUserbox />
            <Box
              sx={{
                display: { lg: 'none', xs: 'inline-block' }
              }}
            >
              <IconButton
                iconColor="emperor"
                bgColor="transparent"
                size="large"
                onClick={() => setIsNavbarDrawerOpen(!isNavbarDrawerOpen)}
              >
                {!isNavbarDrawerOpen ? (
                  <MenuTwoToneIcon fontSize="large" />
                ) : (
                  <CloseTwoToneIcon fontSize="large" />
                )}
              </IconButton>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </HeaderWrapper>
  );
}

export default Header;
