import { FC, useEffect } from 'react';
import Box from '../../../components/layout/Box';
import useLabResults from '../../../hooks/useLabResults';
import { Controller, useForm } from 'react-hook-form';
import Loader from '../../../components/display/Loader';
import InputField from '../../../components/data-entry/InputField';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/layout/Flex';
import { iconSizes, spacings } from '../../../components/styles/constants';
import Button from '../../../components/display/Button';
import { usePopover } from 'src/contexts/UIContexts';

interface LabResultEditFormProps {
  resultId: string;
  disabled?: boolean;
}

interface UpdateLabResult {
  id: string;
  value: string;
  patientId: string;
  cycleId: string;
}

export const LabResultEditForm: FC<LabResultEditFormProps> = ({
  resultId,
  disabled
}) => {
  const { closePopover } = usePopover();
  const { t } = useTranslation();
  const { getLabResultById, updateLabResult } = useLabResults();

  const { data: labResult, isLoading } = getLabResultById(resultId);
  const { mutate: handleUpdateLabResult, isLoading: isUpdatingLabResult } =
    updateLabResult();

  const { control, formState, handleSubmit, reset } = useForm<UpdateLabResult>({
    mode: 'onChange',
    defaultValues: {
      id: resultId,
      value: null,
      patientId: '',
      cycleId: ''
    }
  });
  const { errors } = formState;

  useEffect(() => {
    if (labResult) {
      reset({
        id: resultId,
        value: labResult.value,
        patientId: labResult.patientId,
        cycleId: labResult.cycleId
      });
    }
  }, [labResult]);

  if (isLoading) {
    return <Loader />;
  }

  const onSubmit = async (details: UpdateLabResult) => {
    await handleUpdateLabResult(details, {
      onSuccess: () => closePopover()
    });
  };

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={spacings.large}>
          <Controller
            name="value"
            control={control}
            rules={{
              required: t('RESULT_REQUIRED'),
              min: {
                value: 0,
                message: t('VALUE_MUST_BE_POSITIVE')
              }
            }}
            render={({ field: { ref, value, ...field } }) => (
              <InputField
                {...field}
                disabled={isUpdatingLabResult || disabled}
                inputRef={ref}
                value={value}
                label={t('RESULT')}
                error={!!errors.value}
                helperText={errors?.value?.message}
                type="number"
              />
            )}
          />
        </Box>

        <Flex justifyContent="end">
          <Box width={100}>
            <Button
              disabled={isUpdatingLabResult || disabled}
              fullWidth
              type="submit"
            >
              {isUpdatingLabResult ? (
                <Loader size={iconSizes.xsmall} />
              ) : (
                t('SAVE')
              )}
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
