import {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
  useState,
  useContext
} from 'react';
import { useQueryClient, useQuery, UseQueryOptions } from 'react-query';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  browserSessionPersistence,
  getAuth,
  setPersistence
} from 'firebase/auth';
import { IdleTimerContext, useIdleTimer, IIdleTimer } from 'react-idle-timer';
import { AxiosError } from 'axios';

import firebase from 'src/utils/firebase';
import { envConfig } from 'src/config';
import { DbUser } from 'src/types/user';
import { EmbieError } from 'src/types/global';
import { DocumentEditorParams } from 'src/types/documents';
import { Appointment } from 'src/types/appointment';
import { InboxItem, InboxTabs } from 'src/types/inbox';
import { queryKeys } from 'src/hooks/queryKeys';
import { ToastProps, ToastType } from 'src/components/display/Toast/Toast';
import { NotFoundErrorPage } from 'src/components/notFoundErrorPage';
import {
  CycleWizard,
  CycleWizardProps
} from 'src/modules/patients/cycleWizard/CycleWizard';
import { DocumentEditorDialog } from 'src/modules/documents/DocumentEditorDialog';
import { DailyWorkListDrawer } from 'src/modules/me/DailyWorkListDrawer/DailyWorkListDrawer';
import Drawer from 'src/components/display/Drawer/Drawer';
import { getMeRequest } from 'src/api/me.api';
import { Colors } from 'src/components/styles/colors';
import { useToast, useDialog } from '../UIContexts';
import {
  AuthState,
  AuthAction,
  initialAuthState,
  DailyWorkListDrawerViews,
  AppContextValue,
  CriticalAppError,
  CriticalAppErrorContextValue
} from './types';
import Box from 'src/components/layout/Box';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography';
import Button from 'src/components/display/Button';

const PROMPT_LENGTH = 1000 * 60 * 10;
const IDLE_LENGTH = 1000 * 60 * 30;

const auth = envConfig.isStorybookActive ? null : getAuth();

function createGetMeFn({
  t,
  openToast,
  isAuthenticated
}: {
  t: Function;
  openToast: (p: ToastProps) => void;
  isAuthenticated: boolean;
}) {
  return (options?: UseQueryOptions<DbUser, AxiosError<EmbieError>>) =>
    useQuery<DbUser, AxiosError<EmbieError>>([queryKeys.ME], getMeRequest, {
      onError: (error) => {
        const toastProps: ToastProps = {
          title: t('GET_ME_ERROR_TOAST_TITLE'),
          children: t('TRY_AGAIN_LATER'),
          type: ToastType.ERROR
        };
        if (error.response?.status === 401) {
          toastProps.title =
            error.response?.data?.message || t('EMAIL_IS_NOT_RECOGNIZED');
        }
        openToast(toastProps);
      },
      enabled: isAuthenticated,
      ...options
    });
}

function authReducer(state: AuthState, action: AuthAction): AuthState {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user, error } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, error, user };
  }
  return state;
}

async function signInWithEmailAndPasswordFn(email: string, password: string) {
  await setPersistence(auth, browserSessionPersistence);
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

async function signInWithGoogleFn() {
  const provider = new firebase.auth.GoogleAuthProvider();
  await setPersistence(auth, browserSessionPersistence);
  return firebase.auth().signInWithPopup(provider);
}

async function loginViaGoogleProviderFn(token: string) {
  await setPersistence(auth, browserSessionPersistence);
  const credential = firebase.auth.GoogleAuthProvider.credential(token);
  return firebase.auth().signInWithCredential(credential);
}

async function createUserWithEmailAndPasswordFn(
  email: string,
  password: string
) {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

function logoutFn(): Promise<void> {
  return firebase.auth().signOut();
}

const CriticalAppErrorContext =
  createContext<CriticalAppErrorContextValue>(null);

const AppContext = createContext<AppContextValue>(null);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const { openToast } = useToast();
  const { openDialog, closeDialog } = useDialog();

  // Auth
  const [authState, dispatchAuthState] = useReducer(
    authReducer,
    initialAuthState
  );

  const getMe = createGetMeFn({
    t,
    openToast,
    isAuthenticated: authState.isAuthenticated
  });
  const { refetch: refetchMe } = getMe();

  // Critical App Error
  const [criticalAppError, setCriticalAppError] =
    useState<CriticalAppError | null>(null);
  const queryClient = useQueryClient();
  const resetCriticalAppErrorContext = () => {
    queryClient.removeQueries();
    setCriticalAppError(null);
  };
  const getErrorComponent = (child: ReactNode, error?: CriticalAppError) => {
    switch (error?.code) {
      case 404:
      case 403:
        return <NotFoundErrorPage />;
      default:
        return child;
    }
  };

  // Sidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Navbar
  const [isNavbarDrawerOpen, setIsNavbarDrawerOpen] = useState(false);

  // Messenger
  const [isMessengerOpen, setIsMessengerOpen] = useState(false);

  // Telehealth
  const [isTelehealthOpen, setIsTelehealthOpen] = useState(false);

  // Inbox
  const [selectedInboxItem, setSelectedInboxItem] = useState<InboxItem>();
  const [selectedInboxTab, setSelectedInboxTab] = useState<InboxTabs>();

  useEffect(() => {
    if (!envConfig.isStorybookActive) {
      return firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const { data: me } = await refetchMe();
          if (me?.userType === 'staff') {
            dispatchAuthState({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                error: null,
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  jobTitle: '',
                  avatar: user.photoURL,
                  email: user.email,
                  displayName: user.displayName || user.email,
                  role: 'admin',
                  location: '',
                  username: '',
                  posts: '',
                  coverImg: '',
                  followers: '',
                  description: ''
                }
              }
            });
            if (window.location.search.includes('id_token')) {
              const url = new URL(window.location.href);
              const params = new URLSearchParams(url.search);
              params.delete('id_token');
              const newUrl = params.toString()
                ? `${url.pathname}?${params.toString()}`
                : url.pathname;
              window.history.replaceState({}, document.title, newUrl);
            }
            return;
          }
        }
        await logoutFn();
        dispatchAuthState({
          type: 'AUTH_STATE_CHANGED',
          payload: { isAuthenticated: false, error: null, user: null }
        });
        queryClient.removeQueries();
      });
    }
  }, [dispatchAuthState, refetchMe]);

  const [shouldShowPrompt, setShouldShowPrompt] = useState(true);

  const onIdle = async () => {
    await logoutFn();
    closeDialog();
    window.location.reload();
  };
  const handleActivate = () => {
    idleTimer.reset();
    setShouldShowPrompt(true);
    closeDialog();
  };
  const onPrompt = async () => {
    if (shouldShowPrompt) {
      setShouldShowPrompt(false);
      openDialog({
        header: t('LOGOUT_TITLE'),
        children: (
          <Box marginTop={spacings.medium}>
            <Typography>{t('LOGOUT_CONTENT')}</Typography>
            <Box marginTop={spacings.medium} textAlign="center">
              <Button onClick={handleActivate}>
                {t('LOGOUT_ACTION_TITLE')}
              </Button>
            </Box>
          </Box>
        )
      });
    }
  };

  const idleTimer: IIdleTimer = useIdleTimer({
    onPrompt,
    onIdle,
    promptBeforeIdle: PROMPT_LENGTH,
    timeout: IDLE_LENGTH,
    crossTab: true,
    syncTimers: 200
  });

  useEffect(() => {
    if (authState.isAuthenticated) idleTimer.activate();
    else idleTimer.pause();
  }, [authState.isAuthenticated]);

  const [documentEditorParams, setDocumentEditorParams] =
    useState<DocumentEditorParams>({});
  const [isDocumentsModalOpen, setDocumentsModalOpen] = useState(false);

  const [isDailyWorklistDrawerOpen, setIsDailyWorklistDrawerOpen] =
    useState(false);
  const [dailyWorklistDate, setDailyWorklistDate] = useState(dayjs());
  const [dailyWorklistView, setDailyWorklistView] = useState(
    DailyWorkListDrawerViews.DAILY
  );
  const [dailyWorklistPatientId, setDailyWorklistPatientId] = useState<
    string | null
  >(null);
  const [dailyWorklistAppointment, setDailyWorklistAppointment] =
    useState<Appointment | null>(null);

  const [wizardProps, setWizardProps] = useState<Omit<
    CycleWizardProps,
    'onClose'
  > | null>(null);
  const openWizard = (data: Omit<CycleWizardProps, 'onClose'>) => {
    setWizardProps(data);
  };
  const closeWizard = () => {
    setWizardProps(null);
  };

  const appValue: AppContextValue = {
    ...authState,
    method: 'Firebase',
    createUserWithEmailAndPassword: createUserWithEmailAndPasswordFn,
    signInWithEmailAndPassword: signInWithEmailAndPasswordFn,
    signInWithGoogle: signInWithGoogleFn,
    loginViaGoogleProvider: loginViaGoogleProviderFn,
    logout: logoutFn,
    criticalAppError,
    setCriticalAppError,
    resetCriticalAppErrorContext,
    openWizard,
    closeWizard,
    wizardProps,
    documentEditorParams,
    isDocumentsModalOpen,
    setDocumentEditorParams,
    setDocumentsModalOpen,
    isDailyWorklistDrawerOpen,
    dailyWorklistDate,
    dailyWorklistView,
    dailyWorklistPatientId,
    dailyWorklistAppointment,
    setIsDailyWorklistDrawerOpen,
    setDailyWorklistDate,
    setDailyWorklistView,
    setDailyWorklistPatientId,
    setDailyWorklistAppointment,
    isSidebarOpen,
    setIsSidebarOpen,
    isNavbarDrawerOpen,
    setIsNavbarDrawerOpen,
    isMessengerOpen,
    setIsMessengerOpen,
    isTelehealthOpen,
    setIsTelehealthOpen,
    selectedInboxItem,
    setSelectedInboxItem,
    selectedInboxTab,
    setSelectedInboxTab
  };

  return (
    <CriticalAppErrorContext.Provider
      value={{
        criticalAppError,
        setCriticalAppError,
        resetCriticalAppErrorContext
      }}
    >
      <IdleTimerContext.Provider value={idleTimer}>
        <AppContext.Provider value={appValue}>
          {getErrorComponent(
            <>
              {children}
              {wizardProps && (
                <CycleWizard {...wizardProps} onClose={closeWizard} />
              )}
              {documentEditorParams && (
                <DocumentEditorDialog
                  documentEditorInfo={documentEditorParams}
                  isDocumentsModalOpen={isDocumentsModalOpen}
                  onDocumentsModalOpen={setDocumentsModalOpen}
                  onDocumentEditorInfoChange={setDocumentEditorParams}
                />
              )}
              <Drawer
                width="600px"
                open={isDailyWorklistDrawerOpen}
                onClose={(_ev, reason) => {
                  if (reason !== 'backdropClick')
                    setIsDailyWorklistDrawerOpen(false);
                }}
                backgroundColor={Colors.vistaWhite}
              >
                <DailyWorkListDrawer />
              </Drawer>
            </>,
            criticalAppError
          )}
        </AppContext.Provider>
      </IdleTimerContext.Provider>
    </CriticalAppErrorContext.Provider>
  );
};

function useAppContext() {
  const ctx = useContext(AppContext);
  if (!ctx) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return ctx;
}

export function useCriticalAppError() {
  const ctx = useContext(AppContext);
  return {
    criticalAppError: ctx.criticalAppError,
    setCriticalAppError: ctx.setCriticalAppError,
    resetCriticalAppErrorContext: ctx.resetCriticalAppErrorContext
  };
}

export function useAuth() {
  const ctx = useAppContext();
  return {
    isInitialized: ctx.isInitialized,
    isAuthenticated: ctx.isAuthenticated,
    error: ctx.error,
    user: ctx.user,
    method: ctx.method,
    createUserWithEmailAndPassword: ctx.createUserWithEmailAndPassword,
    signInWithEmailAndPassword: ctx.signInWithEmailAndPassword,
    signInWithGoogle: ctx.signInWithGoogle,
    loginViaGoogleProvider: ctx.loginViaGoogleProvider,
    logout: ctx.logout
  };
}

export function useCycleWizard() {
  const ctx = useAppContext();
  return {
    openWizard: ctx.openWizard,
    closeWizard: ctx.closeWizard,
    wizardProps: ctx.wizardProps
  };
}

export function useDocumentEditor() {
  const ctx = useAppContext();
  return {
    documentEditorParams: ctx.documentEditorParams,
    isDocumentsModalOpen: ctx.isDocumentsModalOpen,
    onDocumentEditorInfoChange: ctx.setDocumentEditorParams,
    onDocumentsModalOpen: ctx.setDocumentsModalOpen
  };
}

export function useDailyWorkListDrawer() {
  const ctx = useAppContext();
  return {
    isDailyWorklistDrawerOpen: ctx.isDailyWorklistDrawerOpen,
    dailyWorklistDate: ctx.dailyWorklistDate,
    dailyWorklistView: ctx.dailyWorklistView,
    dailyWorklistPatientId: ctx.dailyWorklistPatientId,
    dailyWorklistAppointment: ctx.dailyWorklistAppointment,
    onDailyWorklistDrawerOpenChange: ctx.setIsDailyWorklistDrawerOpen,
    onDailyWorklistDateChange: ctx.setDailyWorklistDate,
    onDailyWorklistViewChange: ctx.setDailyWorklistView,
    onDailyWorklistPatientIdChange: ctx.setDailyWorklistPatientId,
    onDailyWorklistAppointmentChange: ctx.setDailyWorklistAppointment
  };
}

export function useSidebar() {
  const ctx = useAppContext();
  return {
    isSidebarOpen: ctx.isSidebarOpen,
    setIsSidebarOpen: ctx.setIsSidebarOpen
  };
}

export function useNavbar() {
  const ctx = useAppContext();
  return {
    isNavbarDrawerOpen: ctx.isNavbarDrawerOpen,
    setIsNavbarDrawerOpen: ctx.setIsNavbarDrawerOpen
  };
}

export function useMessenger() {
  const ctx = useAppContext();
  return {
    isMessengerOpen: ctx.isMessengerOpen,
    setIsMessengerOpen: ctx.setIsMessengerOpen
  };
}

export function useTelehealth() {
  const ctx = useAppContext();
  return {
    isTelehealthOpen: ctx.isTelehealthOpen,
    setIsTelehealthOpen: ctx.setIsTelehealthOpen
  };
}

export function useInbox() {
  const ctx = useAppContext();
  return {
    selectedInboxItem: ctx.selectedInboxItem,
    selectedInboxTab: ctx.selectedInboxTab,
    setSelectedInboxItem: ctx.setSelectedInboxItem,
    setSelectedInboxTab: ctx.setSelectedInboxTab
  };
}

export {
  signInWithEmailAndPasswordFn,
  signInWithGoogleFn,
  loginViaGoogleProviderFn,
  createUserWithEmailAndPasswordFn,
  createGetMeFn,
  logoutFn
};
