import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { patientRoutes } from 'src/router/routes';
import { useAuth } from 'src/contexts/AppContexts/AppContexts';

interface GuestProps {
  children: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={patientRoutes.getPatientsLink()} />;
  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};

export default Guest;
