import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'src/components/display/Typography/Typography';
import { spacings } from 'src/components/styles/constants';
import Table from 'src/components/display/Table/Table';
import { GridColDef, GridRowSpacingParams } from '@mui/x-data-grid-premium';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { styled } from '@mui/material';

import Flex from 'src/components/layout/Flex/Flex';
import Avatar, { AvatarSizes } from 'src/components/display/Avatar/Avatar';
import IconButton from 'src/components/display/IconButton/IconButton';
import { Colors } from 'src/components/styles/colors';
import Select from 'src/components/data-entry/Select';
import { fontWeights } from 'src/components/styles/fonts';
import Box from 'src/components/layout/Box/Box';
import { TooltipWrapper } from 'src/components/display/Tooltip';
import {
  AppointmentStatusType,
  Appointment,
  AddEditAppointmentTabs
} from 'src/types/appointment';
import { UserTypes } from 'src/types/user';
import { getFullName } from 'src/utils/general';
import { useGetDefaultAvatar } from 'src/utils/defaultImages';
import useAppointments from 'src/hooks/useAppointments';
import { patientRoutes } from 'src/router/routes';
import { useDialog, usePopover } from 'src/contexts/UIContexts';
import AddEditAppointment from 'src/modules/calendar/AddEditAppointment';
import { ReactComponent as CycleIcon } from '../../../assets/icons/cycle.svg';
import { getPrimaryPhysicianColumnRenderCell } from '../utils/getPrimaryPhysicianColumnRenderCell';
import { PatientActionsMenu } from '../actionMenu/PatientActionsMenu';
import { SummaryCategory, SummaryCategoryDefault } from './DashboardSummary';
import {
  filterAppointmentsByType,
  getAppointmentCategory,
  getAppointmentTypeCellBgColor,
  getCycleCurrentDay
} from './utils';
import { AppointmentStatusSelect } from './AppointmentStatusSelect';

dayjs.extend(isToday);

const StyledFlexPointer = styled(Flex)`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  gap: ${spacings.large};
  padding: ${spacings.medium} 0;
`;

const defaultTab = AddEditAppointmentTabs.ENCOUNTER_NOTE;

export const DashboardPatientsList: FC<{ date: Dayjs }> = ({ date }) => {
  const [startOfDay, endOfDay] = useMemo(
    () => [date.startOf('day'), date.add(1, 'day').startOf('day')],
    [date]
  );
  const [appointmentTypeFilter, setAppointmentTypeFilter] = useState<
    SummaryCategory | SummaryCategoryDefault
  >(SummaryCategoryDefault.SHOW_ALL_APPOINTMENTS);

  const { t } = useTranslation();
  const { openPopover } = usePopover();
  const { getAggregatedAppointments } = useAppointments();
  const { getDefaultAvatar } = useGetDefaultAvatar();
  const navigate = useNavigate();
  const { openDialog } = useDialog();
  const { getAppointmentsWithCycle } = useAppointments();

  const {
    data: appointments,
    isLoading: isAggregatedAppointmentsLoading,
    isFetching: isAggregatedAppointmentsFetching
  } = getAggregatedAppointments(
    {
      minDate: startOfDay,
      maxDate: endOfDay
    },
    { refetchInterval: 10000 }
  );

  const {
    data: appointmentsWithCycle,
    isLoading: isLoadingAppointmentsWithCycle
  } = getAppointmentsWithCycle(appointments, { refetchInterval: 10000 });

  const isLoading =
    isAggregatedAppointmentsLoading ||
    isAggregatedAppointmentsFetching ||
    isLoadingAppointmentsWithCycle;

  const appointmentTypeOptions = useMemo(() => {
    const defaultValue = 'SHOW_ALL_APPOINTMENTS';
    return [
      {
        value: defaultValue,
        label: t(defaultValue)
      },
      ...Object.entries(SummaryCategory).map(([_, label]) => ({
        value: label,
        label: t(label)
      }))
    ];
  }, []);

  const columns: GridColDef<Appointment>[] = [
    {
      field: 'patient',
      headerName: t('PATIENT').toUpperCase(),
      flex: 2,
      align: 'left',
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      valueGetter: (value) => getFullName(value),
      renderCell: ({
        row: { patient: { pictureId, id: patientId, ...rest } = {} } = {}
      }) => {
        const avatar = getDefaultAvatar({
          userId: patientId,
          userType: UserTypes.patients,
          pictureId: pictureId
        });

        return (
          <StyledFlexPointer
            onClick={() => navigate(patientRoutes.getByIdLink(patientId))}
          >
            <Avatar image={avatar} size={AvatarSizes.SMALL} />
            <Typography>{getFullName(rest)}</Typography>
          </StyledFlexPointer>
        );
      }
    },
    {
      field: 'currentDay',
      headerName: t('CD').toUpperCase(),
      width: 40,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      valueGetter: (_, row) => {
        return getCycleCurrentDay(row.cycleStartDate, row.date);
      },
      renderCell: ({ value }) => (
        <Flex
          height="100%"
          width="100%"
          paddingX={spacings.small}
          justifyContent="center"
          alignItems="center"
          borderLeft={`1px solid ${Colors.alto}`}
        >
          {value}
        </Flex>
      )
    },
    {
      field: 'appointmentType',
      headerName: t('TYPE').toUpperCase(),
      sortComparator: (v1: AppointmentStatusType, v2: AppointmentStatusType) =>
        v1.localeCompare(v2),
      width: 160,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => getAppointmentCategory(value),
      renderCell: ({ row: { appointmentType } }) => (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          bgcolor={getAppointmentTypeCellBgColor(appointmentType)}
        >
          <Typography fontWeight={fontWeights.extraBold}>
            {getAppointmentCategory(appointmentType)}
          </Typography>
        </Flex>
      )
    },
    {
      field: 'status',
      headerName: t('STATUS').toUpperCase(),
      width: 160,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (value) => value,
      renderCell: ({ row: appointment }) => (
        <AppointmentStatusSelect appointment={appointment} />
      )
    },
    {
      field: 'actions',
      headerName: t('ACTIONS').toUpperCase(),
      width: 150,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
      renderCell: (params) => {
        const isLocked = params?.row?.encounter.isLocked;

        return (
          <Flex justifyContent="space-between" gap={spacings.medium}>
            <IconButton
              icon={
                <SvgIcon>
                  <CycleIcon viewBox="0 0 28 28" />
                </SvgIcon>
              }
              disabled={!params?.row?.cycleId}
              bgColor="gray"
              onClick={() => {
                if (!params?.row?.patientId || !params?.row?.cycleId) return;
                navigate(
                  patientRoutes.getCurrentTreatmentByCycleLink(
                    params.row.patientId,
                    params.row.cycleId
                  )
                );
              }}
            />
            <TooltipWrapper
              title={isLocked ? t('NOTE_IS_LOCKED') : t('NOTE_IS_UNLOCKED')}
            >
              <>
                <IconButton
                  bgColor={isLocked ? 'riptide' : 'red'}
                  id="edit-appointment-button"
                  icon={
                    isLocked ? (
                      <LockIcon htmlColor={Colors.black} />
                    ) : (
                      <LockOpenIcon htmlColor={Colors.black} />
                    )
                  }
                  onClick={() =>
                    openDialog({
                      header: t('EDIT_APPOINTMENT'),
                      children: (
                        <AddEditAppointment
                          appointmentId={params.row.id}
                          patientId={params.row.patientId}
                          defaultTab={defaultTab}
                        />
                      ),
                      fullWidth: true,
                      maxWidth: 'lg',
                      closeButtonId: 'close-add-appointment-dialog'
                    })
                  }
                />
              </>
            </TooltipWrapper>
            <IconButton
              icon={<AddRoundedIcon />}
              onClick={(ev) => {
                if (!params?.row?.patientId) return;
                openPopover({
                  children: (
                    <PatientActionsMenu patientId={params.row.patientId} />
                  ),
                  anchorEl: ev.currentTarget,
                  hideToolbar: true
                });
              }}
            />
          </Flex>
        );
      }
    },
    {
      field: 'primaryPhysician',
      headerName: t('PHYSICIAN').toUpperCase(),
      flex: 2,
      align: 'left',
      valueGetter: (_, row) => getFullName(row?.patient?.staff),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      renderCell: ({ row }) =>
        getPrimaryPhysicianColumnRenderCell({ row: row.patient })
    }
  ];

  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 2 : 5
    };
  }, []);

  const appointmentsToDisplay =
    appointmentsWithCycle?.filter(({ date: appointmentDate }) => {
      return dayjs(appointmentDate).isSame(date, 'day');
    }) || [];

  const filteredAppointments = useMemo(
    () =>
      filterAppointmentsByType(appointmentsToDisplay, appointmentTypeFilter),
    [appointmentsToDisplay]
  );

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Typography
          fontWeight={fontWeights.extraBold}
          variant="h2"
          marginBottom={spacings.large}
        >
          {t('APPOINTMENTS')}
        </Typography>
        <Select
          width="250px"
          value={appointmentTypeFilter}
          options={appointmentTypeOptions}
          onChange={(e) =>
            setAppointmentTypeFilter(e.target.value as SummaryCategory)
          }
        />
      </Flex>
      <Box id="dashboard-patients-appointments-container">
        <Table
          variant="carded"
          getRowSpacing={getRowSpacing}
          loading={isLoading}
          columns={columns}
          rows={filteredAppointments || []}
          autoHeight={true}
          getRowId={(row: Appointment) => row.id}
          paginationModel={{ page: 0, pageSize: 25 }}
          hideFilterToolbar
        />
      </Box>
    </Box>
  );
};
