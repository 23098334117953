import { useInfiniteQuery, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { AppError } from 'src/types/global';
import {
  BillingServiceCode,
  BillingServiceParams,
  EncounterServiceCode,
  Icd10Code,
  Modifier,
  NDCCode,
  PaginatedApiRequestStructure
} from 'src/types/codes';
import {
  getEncounterServiceCodesRequest,
  getIcd10CodesRequest,
  getModifiersRequest,
  getNdcCodesRequest,
  getBillingServicesRequest
} from 'src/api/codes.api';
import { getNextPageParamFromInfo } from 'src/utils/general';
import { queryKeys, querySubKeys } from './queryKeys';
import { useToast } from 'src/contexts/UIContexts';

function useCodes() {
  const { handleQueryResultToast } = useToast();
  const { t } = useTranslation();

  return {
    getModifiers: () =>
      useQuery<Modifier[], AppError>(
        [
          queryKeys.BILLING_SERVICES,
          querySubKeys[queryKeys.BILLING_SERVICES].MODIFIERS
        ],
        () => getModifiersRequest(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_MODIFIERS')
            })
        }
      ),
    getEncounterServiceCodes: () =>
      useQuery<EncounterServiceCode[], AppError>(
        [
          queryKeys.BILLING_SERVICES,
          querySubKeys[queryKeys.BILLING_SERVICES].ENCOUNTERS
        ],
        () => getEncounterServiceCodesRequest(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_ENCOUNTER_SERVICE_CODES')
            })
        }
      ),
    getNDCCodes: () =>
      useQuery<NDCCode[], AppError>(['ndc-codes'], () => getNdcCodesRequest(), {
        onSettled: (data, error) =>
          handleQueryResultToast({
            data,
            error,
            actionName: t('ACTION_TITLE_GET_NDC_CODES')
          })
      }),
    getPaginatedIcd10Codes: ({
      searchTerm,
      pageSize
    }: PaginatedApiRequestStructure) =>
      useInfiniteQuery<
        { results: Icd10Code[]; totalResults: number },
        AppError
      >(
        [queryKeys.ICD10_CODES, { searchTerm, pageSize }],
        ({ pageParam }) =>
          getIcd10CodesRequest({ searchTerm, page: pageParam, pageSize }),

        {
          getNextPageParam: (lastPage, allPages) =>
            getNextPageParamFromInfo({
              totalResults: lastPage.totalResults,
              allPagesLength: allPages.length,
              pageSize
            }),
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_ICD10_CODES')
            })
        }
      ),
    getIcd10Codes: ({ icd10Codes }: { icd10Codes: string[] }) =>
      useQuery<Icd10Code[], AppError>(
        [queryKeys.ICD10_CODES, icd10Codes],
        async ({ pageParam }) => {
          const results = await Promise.all(
            icd10Codes.map((term) =>
              getIcd10CodesRequest({
                searchTerm: term,
                page: pageParam
              })
            )
          );

          return results.flatMap((result) => result.results);
        },
        {
          enabled: !!icd10Codes.length
        }
      ),
    getBillingServices: (params: BillingServiceParams) =>
      useQuery<BillingServiceCode[], AppError>(
        [queryKeys.BILLING_SERVICES, params],
        () => getBillingServicesRequest(params),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_SERVICE_CODES')
            })
        }
      )
  };
}

export default useCodes;
