import { FC } from 'react';
import { Box, css, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Appointment } from 'src/types/appointment';
import { getFullName } from 'src/utils/general';
import usePatientsApi from 'src/hooks/usePatientsApi';
import { makeShouldForwardProps } from 'src/components/utils';
import { getTimeFormat } from 'src/utils/dateAndTIme';
import { AppointmentDetails } from '../../../modules/patients/overview/AppointmentDetails';
import Typography from '../Typography';
import { radii, spacings, zIndices, iconSizes } from '../../styles/constants';
import { Colors } from '../../styles/colors';
import { fontFamilies, fonts, fontWeights } from '../../styles/fonts';
import { TooltipWrapper } from '../Tooltip';
import Loader from '../Loader';
import Flex from 'src/components/layout/Flex';
import { useDialog } from 'src/contexts/UIContexts';

interface CalendarEvent extends Appointment {
  allDay?: boolean;
  color?: string;
  start: Date;
  end: Date;
  isBusyTime?: boolean;
}

const isShort = (event: CalendarEvent) =>
  dayjs(event.end).diff(dayjs(event.start), 'minutes') < 30;

const shouldForwardPropBigCalendarWrapper = makeShouldForwardProps(['dayView']);
export const BigCalendarWrapper = styled(Box, {
  shouldForwardProp: shouldForwardPropBigCalendarWrapper
})<{ dayView?: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;

  ${({ dayView }) =>
    dayView &&
    css`
      .rbc-time-header {
        display: none;
      }
    `}

  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-header-content {
    border-color: transparent;
  }
  .rbc-time-view {
    border-color: transparent;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-today {
    background: transparent;
    .rbc-events-container {
      .rbc-event {
        background: #f9f0f1;
      }
    }
  }

  .rbc-header.rbc-today {
    .MuiTypography-secondary {
      color: black;
    }
  }

  .rbc-event {
    background-color: transparent;
    border: none;
    z-index: ${zIndices.low};
    padding: 0;
  }
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border-color: transparent;
  }

  .rbc-event-content {
    flex-direction: column;
  }

  .rbc-header + .rbc-header {
    border-color: transparent;
  }

  .rbc-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;
    .rbc-button-link {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span {
        font: ${fonts.tableColumnHeader};
        font-weight: ${fontWeights.regular};
        color: ${Colors.emperor};
      }
    }
    &.rbc-today {
      span {
        font-weight: bold;
      }
    }
  }

  .rbc-time-content {
    background: ${({ dayView }) =>
      dayView ? Colors.transparent : Colors.white};
    padding-left: ${({ dayView }) =>
      dayView ? spacings.none : spacings.x2large};
    border-color: ${Colors.transparent};
    padding-left: ${spacings.none};
    padding-top: ${spacings.medium};
  }

  .rbc-time-content:first-of-type {
    background: #191515;
    border-color: transparent;
  }

  .rbc-time-slot {
    border-color: transparent;
    border-top: none;
    margin-top: -12px;
    padding: 0 7px;
    font-weight: 700;
  }

  .rbc-time-gutter .rbc-time-column {
    border-color: transparent;
  }

  .rbc-time-gutter {
    & > .rbc-timeslot-group {
      border-color: transparent;
    }
  }

  .rbc-label {
    font-family: ${fontFamilies.primary};
    font-size: 10px;
    color: ${Colors.silverChalice};
  }

  .rbc-events-container {
    overflow: visible;
    z-index: ${zIndices.low};
    margin: 0;
  }

  .rbc-time-gutter {
    .rbc-timeslot-group:first-of-type {
      .rbc-time-slot {
        .rbc-label {
          display: none;
        }
      }
    }
  }

  .rbc-events-container {
    z-index: unset;
  }

  .rbc-calendar {
    .rbc-time-view {
      overflow: visible;
    }
  }

  .rbc-time-header {
    height: 55px;
    border: none;
  }

  .rbc-row {
    height: 100%;
  }

  .rbc-timeslot-group {
    border: none;
    border-left: 1px solid transparent;
    border-bottom: 1px solid ${Colors.silverChalice};
    min-height: max(
      calc((100vh - 120px) / 15),
      35px
    ); // make sure ~12 slots are in view
  }

  .rbc-time-gutter + .rbc-day-slot {
    .rbc-timeslot-group {
      border: none;
      border-left: 1px solid transparent;
      border-bottom: 1px solid ${Colors.silverChalice};
    }
    .rbc-events-container {
      border-left: 1px solid transparent;
    }
  }

  .rbc-background-event {
    background-color: ${Colors.whiteIceAlpha50};
    cursor: default;
    left: 20% !important;
    width: 50%;
    border-radius: ${radii.large};
    .rbc-event-content .MuiBox-root {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .rbc-current-time-indicator {
    z-index: ${zIndices.high};
  }
`;

export const DayHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
`;

const shouldForwardPropEventWrapper = makeShouldForwardProps(['isShort']);
export const EventWrapper = styled(Box, {
  shouldForwardProp: shouldForwardPropEventWrapper
})<{ isShort?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 3px;
  padding: 0 ${spacings.small};
  border-radius: ${radii.small};
  border: 2px solid ${Colors.white};
  width: 100%;
  min-width: 0;
  overflow: hidden;
`;

export const DayHeader: FC<{ date: Date }> = ({ date }) => {
  return (
    <DayHeaderWrapper>
      <Typography>
        {date
          .toLocaleDateString('en', {
            weekday: 'short'
          })
          .toUpperCase()}
      </Typography>
      <Typography>{date.getDate()}</Typography>
    </DayHeaderWrapper>
  );
};

const EventContent: FC<{
  event: CalendarEvent;
  noWrap?: boolean;
}> = ({ event, noWrap = false }) => {
  const { getPatientById } = usePatientsApi();
  const { data: patient, isLoading: isLoadingPatient } = getPatientById(
    event.patientId
  );

  return isLoadingPatient ? (
    <Loader size={iconSizes.xsmall} colorBase={Colors.emperor} />
  ) : (
    <Flex flexDirection="column" minWidth="0" width="100%">
      <Typography variant="label" noWrap={noWrap}>
        <Typography fontWeight={fontWeights.bold}>
          {getFullName(patient?.personalInfo)}
        </Typography>{' '}
        <Typography fontWeight={fontWeights.regular}>
          {event.appointmentType}
        </Typography>
      </Typography>
      <Typography
        variant="caption"
        fontWeight={fontWeights.regular}
        noWrap={noWrap}
      >
        {dayjs(event.start).format(getTimeFormat({ isShort: true }))} -{' '}
        {dayjs(event.end).format(getTimeFormat({ isShort: true }))}
      </Typography>
    </Flex>
  );
};

export const EventRenderer: FC<{ event: CalendarEvent }> = ({ event }) => {
  const { openDialog } = useDialog();
  const { t } = useTranslation();

  if (event.createdByPatient) return <></>;

  const shortEvent = isShort(event);

  return event.isBusyTime ? (
    <EventWrapper
      isShort={shortEvent}
      bgcolor={event.color}
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography style={{ fontWeight: 700 }}>{t('BUSY')}</Typography>
    </EventWrapper>
  ) : (
    <TooltipWrapper title={<EventContent event={event} />} placement="top">
      <EventWrapper
        isShort={shortEvent}
        bgcolor={event.color}
        height="100%"
        width="100%"
        id={`appointment-event-${event.id}`}
        onClick={() => {
          openDialog({
            header: ' ',
            fullWidth: true,
            maxWidth: 'sm',
            removeDefaultPadding: true,
            hideCloseButton: true,
            children: <AppointmentDetails appointmentId={event.id} />
          });
        }}
      >
        <EventContent event={event} noWrap />
      </EventWrapper>
    </TooltipWrapper>
  );
};
