import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Flex from '../../../components/layout/Flex';
import Typography from '../../../components/display/Typography';
import { fontWeights } from '../../../components/styles/fonts';
import { spacings } from '../../../components/styles/constants';
import { useTranslation } from 'react-i18next';
import NumberPreview from '../../../components/display/NumberPreview';
import Loader from '../../../components/display/Loader';
import { getInboxLink } from '../../../router/routes';
import { NumberPreviewSize } from 'src/components/display/NumberPreview/types';
import { DashboardSummary } from 'src/types/patient';

export const TasksSummary: FC<{
  summary: DashboardSummary;
  isLoading: boolean;
}> = ({ summary, isLoading }) => {
  const { messages, tasks } = summary || {};

  const navigate = useNavigate();
  const { t } = useTranslation();

  if (isLoading) return <Loader />;

  return (
    <Flex flex={2} alignItems="center" justifyContent="space-between">
      <Flex flexDirection="column" gap={spacings.small}>
        <Typography variant="h2" fontWeight={fontWeights.extraBold}>
          {t('OUTSTANDING')} {t('TASKS')}
        </Typography>
      </Flex>
      <Flex>
        <NumberPreview
          id="messages-summary"
          size={NumberPreviewSize.large}
          value={messages}
          bottomText={t('MESSAGES')}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(getInboxLink())}
        />
        <NumberPreview
          id="tasks-summary"
          size={NumberPreviewSize.large}
          value={tasks}
          bottomText={t('TASKS')}
        />
      </Flex>
    </Flex>
  );
};
