import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFieldVariants } from 'src/components/data-entry/SearchField/SearchField';
import Button from 'src/components/display/Button';
import Loader from 'src/components/display/Loader';
import Box from 'src/components/layout/Box';
import { spacings } from 'src/components/styles/constants';
import { useDialog } from 'src/contexts/UIContexts';
import useMessagesApi from 'src/hooks/useMessages';
import { PatientsSearchBar } from 'src/layouts/CollapsedSidebarLayout/Header/PatientsSearchBar';
import {
  ChannelTypes,
  InboxItem,
  InboxTabs,
  InboxTypes
} from 'src/types/inbox';

interface Props {
  setSelectedItem: (item: InboxItem) => Promise<void> | void;
  setSelectedTab: (tab: InboxTabs) => void;
  selectedNewChatType: ChannelTypes;
}
const SelectChatPatient = ({
  setSelectedItem,
  selectedNewChatType,
  setSelectedTab
}: Props) => {
  const { t } = useTranslation();
  const { closeDialog } = useDialog();
  const { getPatientChannel } = useMessagesApi();
  const [selectedPatientId, setSelectedPatientId] = useState<string>();

  const { data: channelData, isLoading: isLoadingChannelData } =
    getPatientChannel(selectedPatientId, selectedNewChatType, {
      enabled: !!selectedPatientId
    });

  const handleSettingChannel = () => {
    closeDialog();
    setSelectedTab(
      selectedNewChatType === ChannelTypes.Patient
        ? InboxTabs.PatientInbox
        : InboxTabs.InternalInbox
    );
    setSelectedItem({
      type: InboxTypes.Message,
      channelId: channelData?.id,
      patientId: selectedPatientId
    });
  };

  return (
    <Box id="select-chat-patient-form">
      <PatientsSearchBar
        onSelectPatient={setSelectedPatientId}
        variant={SearchFieldVariants.STYLED}
      />
      <Button
        sx={{ marginTop: spacings.large }}
        onClick={handleSettingChannel}
        disabled={isLoadingChannelData}
      >
        {isLoadingChannelData ? <Loader /> : t('SELECT')}
      </Button>
    </Box>
  );
};

export default SelectChatPatient;
