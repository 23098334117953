import { FC, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { Collapse, styled } from '@mui/material';
import Card from 'src/components/display/Card/Card';
import { spacings } from 'src/components/styles/constants';
import Switch from 'src/components/data-entry/Switch/Switch';
import Button from 'src/components/display/Button/Button';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import Typography from 'src/components/display/Typography/Typography';
import {
  PatientFormValues,
  PersonEthnicity,
  PersonMaritalStatuses,
  personSexEntries,
  TreatmentReasons
} from 'src/types/patient';
import { PatientServer } from 'src/types/patient-server';
import { getFullName } from 'src/utils/general';
import { Colors } from 'src/components/styles/colors';
import { fontWeights } from 'src/components/styles/fonts';
import Flex from '../../../components/layout/Flex/Flex';
import Box from '../../../components/layout/Box/Box';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { convertPatientClientToServer } from '../utils/conversions';
import useMeApi from '../../../hooks/useMeApi';
import { patientRoutes } from 'src/router/routes';
import useStaffMembers from 'src/hooks/useStaffMembers';
import { YesOrNo, yesOrNoEntries } from 'src/types/global';
import { emailRegex, phoneRegex } from '../utils/validations-regex';

export const StyledDatePicker = styled(DatePicker)`
  & .MuiFormHelperText-root {
    color: ${Colors.red};
    font-weight: ${fontWeights.extraBold};
  }
`;

const StyledCard = styled(Card)`
  padding: 48px;
  min-width: 700px;
`;

const StyledBox = styled(Box)`
  width: 50%;
  padding-inline-end: ${spacings.medium};
  margin-bottom: ${spacings.x2large};
`;

const defaultValues: PatientFormValues = {
  primaryPhysician: null,
  firstName: '',
  middleInitial: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  ssn: '',
  lastNameAtBirth: '',
  nickname: '',
  hasDisability: YesOrNo.NO,
  language: 'English',
  dateOfBirth: null,
  ethnicity: null,
  maritalStatus: null,
  sexAtBirth: null,
  treatmentReason: TreatmentReasons.FERTILITY_TREATMENT,
  hasPartner: false,
  partnerFirstName: '',
  partnerMiddleInitial: '',
  partnerLastName: '',
  partnerEmail: '',
  partnerPhoneNumber: '',
  partnerAddress: '',
  partnerCity: '',
  partnerState: '',
  partnerZip: '',
  partnerCountry: '',
  partnerSsn: '',
  partnerDateOfBirth: null,
  partnerEthnicity: null,
  partnerGender: null
};

const EditPatient: FC = () => {
  const { t } = useTranslation();
  const { getMe } = useMeApi();
  const { data: me } = getMe();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { createPatient, updatePatient, getPatientById } = usePatientsApi();
  const { getStaffMembersNames } = useStaffMembers();

  const { mutateAsync: createPatientMutate, isLoading: isCreatingPatient } =
    createPatient();

  const { mutateAsync: updatePatientMutate, isLoading: isUpdatingPatient } =
    updatePatient();
  const isAdd = !patientId;

  const { data: patient } = getPatientById(patientId, {
    enabled: !isAdd
  });

  const { data: staffMembers } = getStaffMembersNames();

  const { control, formState, handleSubmit, watch, setValue, reset } =
    useForm<PatientFormValues>({
      mode: 'onChange',
      defaultValues
    });
  const { errors } = formState;
  const { hasPartner } = watch();

  const clinicId = me?.user?.clinic?.id;

  const onSubmit = async (formValues: PatientFormValues) => {
    try {
      const patientInfo: PatientServer = convertPatientClientToServer({
        ...formValues,
        clinicId
      });

      if (isAdd) {
        await createPatientMutate(patientInfo, {
          onSuccess: (patientId) => {
            navigate(patientRoutes.getConfirmationEmailLink(patientId));
          }
        });
      } else {
        await updatePatientMutate(
          { patientId, patient: patientInfo },
          {
            onSuccess: () => {
              navigate(-1);
            }
          }
        );
      }
    } catch (error) {
      console.error('Error creating patient', error);
    }
  };

  const filteredStaffMembers = useMemo(
    () => staffMembers?.filter(({ isActive }) => isActive),
    [staffMembers]
  );

  useEffect(() => {
    if (patient) {
      reset({
        primaryPhysician: patient?.primaryPhysician || null,
        firstName: patient?.personalInfo?.firstName || '',
        middleInitial: patient?.personalInfo?.middleInitial || '',
        lastName: patient?.personalInfo?.lastName || '',
        phoneNumber: patient?.personalInfo?.phoneNumber || '',
        email: patient?.personalInfo?.email || '',
        address: patient?.personalInfo?.address || '',
        city: patient?.personalInfo?.city || '',
        state: patient?.personalInfo?.state || '',
        zip: patient?.personalInfo?.postalCode || '',
        country: patient?.personalInfo?.country || '',
        ssn: patient?.personalInfo?.ssn || '',
        dateOfBirth: patient?.personalInfo?.dateOfBirth || null,
        ethnicity: patient?.personalInfo?.ethnicity || null,
        maritalStatus: patient?.maritalStatus || null,
        sexAtBirth: patient?.personalInfo?.sexAtBirth || null,
        treatmentReason:
          patient?.mainReasonForTreatment ||
          TreatmentReasons.FERTILITY_TREATMENT,
        lastNameAtBirth: patient?.personalInfo?.lastNameAtBirth || '',
        nickname: patient?.personalInfo?.nickname || '',
        hasDisability: patient?.personalInfo?.hasDisability || null,
        language: patient?.personalInfo?.language || 'English',
        hasPartner: patient.hasPartner,
        partnerFirstName: patient?.partnerInfo?.firstName || '',
        partnerMiddleInitial: patient?.partnerInfo?.middleInitial || '',
        partnerLastName: patient?.partnerInfo?.lastName || '',
        partnerEmail: patient?.partnerInfo?.email || '',
        partnerPhoneNumber: patient?.partnerInfo?.phoneNumber || '',
        partnerAddress: patient?.partnerInfo?.address || '',
        partnerCity: patient?.partnerInfo?.city || '',
        partnerState: patient?.partnerInfo?.state || '',
        partnerZip: patient?.partnerInfo?.postalCode || '',
        partnerCountry: patient?.partnerInfo?.country || '',
        partnerSsn: patient?.partnerInfo?.ssn || '',
        partnerDateOfBirth: patient?.partnerInfo?.dateOfBirth || null,
        partnerEthnicity: patient?.partnerInfo?.ethnicity || null,
        partnerGender: patient?.partnerInfo?.gender || null,
        partnerSexAtBirth: patient?.partnerInfo?.sexAtBirth || null
      });
    }
  }, [patient]);

  return (
    <Box width="100%" paddingBottom="10px">
      <Helmet>
        <title>{t('EDIT_PATIENT_PAGE_TITLE')}</title>
      </Helmet>
      <Typography variant="h1" marginBottom={spacings.large} display="flex">
        {t(isAdd ? 'ADD_NEW_PATIENT' : 'EDIT_PATIENT')}
      </Typography>
      <StyledCard>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <StyledBox>
            <Controller
              name="primaryPhysician"
              control={control}
              rules={{
                required: t('PROVIDER_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('DOCTOR_LABEL')}
                  error={!!errors?.primaryPhysician}
                  helperText={errors?.primaryPhysician?.message}
                  defaultOption={t('DOCTOR_DEFAULT_OPTION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={
                    filteredStaffMembers?.map(({ id, ...rest }) => ({
                      label: getFullName(rest),
                      value: id
                    })) || []
                  }
                  required
                  id="add-patient-primary-physician-select"
                />
              )}
            />
          </StyledBox>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom={spacings.xlarge}
          >
            <Typography variant="h2">{t('ABOUT_THE_PATIENT')}</Typography>
            <Flex alignItems="center" gap={spacings.large}>
              <Typography variant="h3">{t('PATIENT_HAS_PARTNER')}</Typography>
              <Switch
                id="patient-details-has-partner-switch"
                switchOnText="Yes"
                switchOffText="No"
                checked={hasPartner}
                onChange={() => setValue('hasPartner', !hasPartner)}
              />
            </Flex>
          </Flex>

          <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
            <Flex flex={1} gap={spacings.medium}>
              <Box flex={3}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: t('PATIENT_NAME_REQUIRED')
                  }}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('FIRST_NAME_LABEL')}
                      placeholder={t('FIRST_NAME_PLACEHOLDER')}
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message}
                      fullWidth
                      required
                    />
                  )}
                />
              </Box>
              <Controller
                name="middleInitial"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    label={t('MIDDLE_INITIAL_LABEL')}
                    placeholder={t('MIDDLE_INITIAL_PLACEHOLDER')}
                    error={!!errors.middleInitial}
                    helperText={errors?.middleInitial?.message}
                    width="25%"
                    inputRef={ref}
                  />
                )}
              />
            </Flex>
            <Box flex={1}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: t('PATIENT_NAME_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    label={t('LAST_NAME_LABEL')}
                    placeholder={t('LAST_NAME_PLACEHOLDER')}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    required
                    inputRef={ref}
                    fullWidth
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: t('EMAIL_REQUIRED'),
                pattern: {
                  value: emailRegex,
                  message: t('EMAIL_INVALID')
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('EMAIL').toUpperCase()}
                  placeholder={t('EMAIL_PLACEHOLDER')}
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  required
                  fullWidth
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                pattern: {
                  value: phoneRegex,
                  message: t('PHONE_NUMBER_INVALID')
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('PHONE_NUMBER_LABEL')}
                  placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
                  error={!!errors.phoneNumber}
                  helperText={errors?.phoneNumber?.message}
                  fullWidth
                />
              )}
            />
          </Flex>
          <Flex gap={spacings.xlarge} marginBottom={spacings.medium} flex={1}>
            <Flex flex={1}>
              <Controller
                name="address"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    label={t('ADDRESS').toUpperCase()}
                    placeholder={t('ADDRESS_PLACEHOLDER')}
                    error={!!errors.address}
                    helperText={errors?.address?.message}
                    fullWidth
                  />
                )}
              />
            </Flex>
            <Flex gap={spacings.small} flex={1}>
              <Flex flexDirection="column" flex={2}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('CITY_LABEL')}
                      placeholder={t('CITY_PLACEHOLDER')}
                      error={!!errors.city}
                      helperText={errors?.city?.message}
                      fullWidth
                    />
                  )}
                />
              </Flex>
              <Flex flexDirection="column" flex={1}>
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('STATE_LABEL')}
                      placeholder={t('STATE_PLACEHOLDER')}
                      error={!!errors.state}
                      helperText={errors?.state?.message}
                      fullWidth
                    />
                  )}
                />
              </Flex>
              <Flex flexDirection="column" flex={1}>
                <Controller
                  name="zip"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('ZIP_LABEL')}
                      placeholder={t('ZIP_PLACEHOLDER')}
                      error={!!errors.zip}
                      helperText={errors?.zip?.message}
                      fullWidth
                    />
                  )}
                />
              </Flex>
              <Flex flexDirection="column" flex={1}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputField
                      {...field}
                      inputRef={ref}
                      label={t('COUNTRY_LABEL')}
                      placeholder={t('COUNTRY_PLACEHOLDER')}
                      error={!!errors.country}
                      helperText={errors?.country?.message}
                      fullWidth
                    />
                  )}
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: t('DATE_OF_BIRTH_REQUIRED'),
                validate: (date) => {
                  return (
                    dayjs(date).isBefore(dayjs()) || t('DATE_OF_BIRTH_INVALID')
                  );
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <StyledDatePicker
                  {...field}
                  inputRef={ref}
                  label={t('DATE_OF_BIRTH_LABEL').toUpperCase()}
                  error={!!errors.dateOfBirth}
                  helperText={errors?.dateOfBirth?.message}
                  fullWidth
                  required
                />
              )}
            />

            <Controller
              name="ssn"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('SSN_LABEL')}
                  error={!!errors.ssn}
                  helperText={errors?.ssn?.message}
                  fullWidth
                />
              )}
            />
          </Flex>
          <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
            <Controller
              name="ethnicity"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('ETHNICITY_LABEL')}
                  error={!!errors.ethnicity}
                  helperText={errors?.ethnicity?.message}
                  defaultOption={t('ETHNICITY_DEFAULT_OPTION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(PersonEthnicity).map(([, label]) => ({
                    value: label,
                    label: t(label)
                  }))}
                  id="add-patient-ethnicity-select"
                />
              )}
            />
            <Controller
              name="sexAtBirth"
              control={control}
              rules={{
                required: t('GENDER_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('GENDER_AT_BIRTH')}
                  error={!!errors.sexAtBirth}
                  helperText={errors?.sexAtBirth?.message}
                  defaultOption={t('GENDER_DEFAULT_OPTION')}
                  required
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={personSexEntries.map(([key, value]) => ({
                    label: t(key),
                    value
                  }))}
                  id="add-patient-sex-at-birth-select"
                />
              )}
            />
          </Flex>
          <Flex gap={spacings.xlarge} marginBottom={spacings.x2large}>
            <Controller
              name="maritalStatus"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('MARITAL_STATUS_LABEL')}
                  error={!!errors.maritalStatus}
                  helperText={errors?.maritalStatus?.message}
                  defaultOption={t('MARITAL_STATUS_DEFAULT_OPTION')}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(PersonMaritalStatuses).map(
                    ([, label]) => ({ value: label, label: t(label) })
                  )}
                  id="add-patient-marital-status-select"
                />
              )}
            />
            <Controller
              name="treatmentReason"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  inputRef={ref}
                  label={t('TREATMENT_REASON_LABEL')}
                  error={!!errors.treatmentReason}
                  helperText={errors?.treatmentReason?.message}
                  defaultOption={t(TreatmentReasons.FERTILITY_TREATMENT)}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={Object.entries(TreatmentReasons).map(
                    ([, label]) => ({ value: label, label: t(label) })
                  )}
                  id="add-patient-treatment-reason-select"
                />
              )}
            />
          </Flex>
          <Flex gap={spacings.xlarge} marginBottom={spacings.x2large}>
            <Controller
              name="lastNameAtBirth"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('LAST_NAME_AT_BIRTH_LABEL')}
                  error={!!errors.lastNameAtBirth}
                  helperText={errors?.lastNameAtBirth?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              name="nickname"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('NICK_NAME_LABEL')}
                  error={!!errors.nickname}
                  helperText={errors?.nickname?.message}
                  fullWidth
                />
              )}
            />
          </Flex>
          <Flex gap={spacings.xlarge} marginBottom={spacings.x2large}>
            <Controller
              name="hasDisability"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <Select
                  label={t('HAS_DISABILITY_LABEL').toUpperCase()}
                  {...field}
                  error={!!errors.hasDisability}
                  inputRef={ref}
                  onChange={(event) => {
                    field.onChange(event.target.value);
                  }}
                  options={useMemo(
                    () =>
                      yesOrNoEntries.map(([label, value]) => ({
                        label: t(label),
                        value
                      })),
                    []
                  )}
                  id="patient-details-has-disability"
                />
              )}
            />
            <Controller
              name="language"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <InputField
                  {...field}
                  inputRef={ref}
                  label={t('LANGUAGE_LABEL')}
                  error={!!errors.language}
                  helperText={errors?.language?.message}
                  fullWidth
                  defaultValue={t('LANGUAGE_DEFAULT_VALUE')}
                />
              )}
            />
          </Flex>
          <Collapse in={hasPartner}>
            {hasPartner && (
              <>
                <Typography variant="h2" marginBottom={spacings.xlarge}>
                  {t('ABOUT_THE_PARTNER')}
                </Typography>
                <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
                  <Flex flex={1} gap={spacings.medium}>
                    <Box flex={3}>
                      <Controller
                        name="partnerFirstName"
                        control={control}
                        rules={{
                          required: t('PATIENT_NAME_REQUIRED')
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <InputField
                            {...field}
                            inputRef={ref}
                            label={t('FIRST_NAME_LABEL')}
                            placeholder={t('FIRST_NAME_PLACEHOLDER')}
                            error={!!errors.partnerFirstName}
                            helperText={errors?.partnerFirstName?.message}
                            required
                          />
                        )}
                      />
                    </Box>
                    <Controller
                      name="partnerMiddleInitial"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('MIDDLE_INITIAL_LABEL')}
                          placeholder={t('MIDDLE_INITIAL_PLACEHOLDER')}
                          error={!!errors.partnerMiddleInitial}
                          helperText={errors?.partnerMiddleInitial?.message}
                          width="25%"
                        />
                      )}
                    />
                  </Flex>
                  <Box flex={1}>
                    <Controller
                      name="partnerLastName"
                      control={control}
                      rules={{
                        required: t('PATIENT_NAME_REQUIRED')
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('LAST_NAME_LABEL')}
                          placeholder={t('LAST_NAME_PLACEHOLDER')}
                          error={!!errors.partnerLastName}
                          helperText={errors?.partnerLastName?.message}
                          required
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </Flex>
                <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
                  <Controller
                    name="partnerEmail"
                    control={control}
                    rules={{
                      required: t('EMAIL_REQUIRED'),
                      pattern: {
                        value: emailRegex,
                        message: t('EMAIL_INVALID')
                      }
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <InputField
                        {...field}
                        inputRef={ref}
                        label={t('EMAIL')}
                        placeholder={t('EMAIL_PLACEHOLDER')}
                        type="email"
                        error={!!errors.partnerEmail}
                        helperText={errors?.partnerEmail?.message}
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="partnerPhoneNumber"
                    control={control}
                    rules={{
                      pattern: {
                        value: phoneRegex,
                        message: t('PHONE_NUMBER_INVALID')
                      }
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <InputField
                        {...field}
                        inputRef={ref}
                        label={t('PHONE_NUMBER_LABEL')}
                        placeholder={t('PHONE_NUMBER_PLACEHOLDER')}
                        error={!!errors.partnerPhoneNumber}
                        helperText={errors?.partnerPhoneNumber?.message}
                        fullWidth
                      />
                    )}
                  />
                </Flex>
                <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
                  <Flex flex={1}>
                    <Controller
                      name="partnerAddress"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('ADDRESS').toUpperCase()}
                          placeholder={t('ADDRESS_PLACEHOLDER')}
                          error={!!errors.partnerAddress}
                          helperText={errors?.partnerAddress?.message}
                          fullWidth
                        />
                      )}
                    />
                  </Flex>
                  <Flex gap={spacings.small} flex={2}>
                    <Controller
                      name="partnerCity"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <InputField
                          {...field}
                          inputRef={ref}
                          label={t('CITY_LABEL')}
                          placeholder={t('CITY_PLACEHOLDER')}
                          error={!!errors.partnerCity}
                          helperText={errors?.partnerCity?.message}
                          fullWidth
                        />
                      )}
                    />
                    <Flex flex={1}>
                      <Controller
                        name="partnerState"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <InputField
                            {...field}
                            inputRef={ref}
                            label={t('STATE_LABEL')}
                            placeholder={t('STATE_PLACEHOLDER')}
                            error={!!errors.partnerState}
                            helperText={errors?.partnerState?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Flex>
                    <Flex flex={1}>
                      <Controller
                        name="partnerZip"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <InputField
                            {...field}
                            inputRef={ref}
                            label={t('ZIP_LABEL')}
                            placeholder={t('ZIP_PLACEHOLDER')}
                            error={!!errors.partnerZip}
                            helperText={errors?.partnerZip?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Flex>
                    <Flex flex={1}>
                      <Controller
                        name="partnerCountry"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <InputField
                            {...field}
                            inputRef={ref}
                            label={t('COUNTRY_LABEL')}
                            placeholder={t('COUNTRY_PLACEHOLDER')}
                            error={!!errors.partnerCountry}
                            helperText={errors?.partnerCountry?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
                  <Controller
                    name="partnerDateOfBirth"
                    control={control}
                    rules={{
                      required: t('DATE_OF_BIRTH_REQUIRED'),
                      validate: (date) => {
                        return (
                          dayjs(date).isBefore(dayjs()) ||
                          t('DATE_OF_BIRTH_INVALID')
                        );
                      }
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <StyledDatePicker
                        {...field}
                        inputRef={ref}
                        label={t('DATE_OF_BIRTH_LABEL').toUpperCase()}
                        error={!!errors.partnerDateOfBirth}
                        helperText={errors?.partnerDateOfBirth?.message}
                        required
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="partnerSsn"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <InputField
                        {...field}
                        inputRef={ref}
                        label={t('SSN_LABEL')}
                        error={!!errors.partnerSsn}
                        helperText={errors?.partnerSsn?.message}
                        fullWidth
                      />
                    )}
                  />
                </Flex>
                <Flex gap={spacings.xlarge} marginBottom={spacings.medium}>
                  <Controller
                    name="partnerEthnicity"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        inputRef={ref}
                        label={t('ETHNICITY_LABEL')}
                        error={!!errors.partnerEthnicity}
                        helperText={errors?.partnerEthnicity?.message}
                        defaultOption={t('ETHNICITY_DEFAULT_OPTION')}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        options={Object.entries(PersonEthnicity).map(
                          ([, label]) => ({
                            value: label,
                            label: t(label)
                          })
                        )}
                        id="add-partner-ethnicity-select"
                      />
                    )}
                  />
                  <Controller
                    name="partnerSexAtBirth"
                    control={control}
                    rules={{
                      required: t('GENDER_REQUIRED')
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <Select
                        {...field}
                        inputRef={ref}
                        label={t('GENDER_AT_BIRTH')}
                        required
                        error={!!errors.partnerSexAtBirth}
                        helperText={errors?.partnerSexAtBirth?.message}
                        defaultOption={t('GENDER_DEFAULT_OPTION')}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        options={personSexEntries.map(([key, value]) => ({
                          label: t(key),
                          value
                        }))}
                        id="add-partner-sex-at-birth-select"
                      />
                    )}
                  />
                </Flex>
              </>
            )}
          </Collapse>
          <Box mt="20px">
            <Button
              type="submit"
              disabled={isCreatingPatient || isUpdatingPatient}
            >
              {t(isAdd ? 'ADD_PATIENT_CTA' : 'SAVE_PATIENT_CTA')}
            </Button>
          </Box>
        </form>
      </StyledCard>
    </Box>
  );
};

export default EditPatient;
