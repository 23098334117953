import {
  AppointmentCodingTemplate,
  BusyTime,
  ClincRoomsBusyTimesRequest,
  RequestRange,
  StaffBusyTimesRequest
} from './../types/appointment';
import { useTranslation } from 'react-i18next';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { ToastType } from 'src/components/display/Toast/Toast';
import {
  getAppointmentCodingTemplatesRequest,
  getClinicRoomBusyTimesRequest,
  getClinicRoomsRequest,
  getStaffBusyTimesRequest,
  updateAppointmentStatusRequest
} from 'src/api/appointment.api';
import { convertAppointmentClientToServer } from 'src/modules/patients/utils/conversions';
import { AppError } from 'src/types/global';
import { Appointment, ClinicRoom } from 'src/types/appointment';
import { AppointmentServer } from 'src/types/patient-server';
import {
  createAppointmentRequest,
  cancelAppointmentRequest,
  getAggregatedAppointmentsRequest,
  getAppointmentByIdRequest,
  getPatientAppointmentsRequest,
  updateAppointmentRequest
} from 'src/api/appointment.api';
import { CreatePatientDocumentPayload } from 'src/types/documents';
import { dateRangeQuerySubkey, queryKeys, querySubKeys } from './queryKeys';
import { useToast, useDialog } from 'src/contexts/UIContexts';
import { getCycleById } from 'src/api/cycle.api';
import { convertDateToDayjs } from 'src/utils/dateAndTIme';

const INVALIDATE_BUSY_TIMES_DELAY = 3000;

function useAppointments() {
  const { openToast, handleQueryResultToast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { closeDialog } = useDialog();

  return {
    createAppointment: () =>
      useMutation<
        Appointment,
        AppError,
        {
          appointment: Appointment;
          ordersDocumentPayload?: CreatePatientDocumentPayload;
        }
      >(
        ({ appointment, ordersDocumentPayload }) => {
          const appointmentToCreate = convertAppointmentClientToServer({
            ...appointment
          });
          return createAppointmentRequest({
            appointment: appointmentToCreate,
            ordersDocumentPayload
          });
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CREATE_APPOINTMENTS')
            }),
          onSuccess: (_, { appointment }) => {
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              appointment?.patientId,
              querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
            ]);
            queryClient.invalidateQueries([queryKeys.APPOINTMENTS]);
            queryClient.invalidateQueries([queryKeys.FEED]);
            queryClient.invalidateQueries([
              queryKeys.CLINICS,
              querySubKeys[queryKeys.CLINICS].DASHBOARD_SUMMARY
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              appointment?.patientId,
              querySubKeys[queryKeys.PATIENTS].DOCUMENTS
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              appointment?.patientId,
              querySubKeys[queryKeys.PATIENTS].TASKS
            ]);
            // Do not delete this setTimeout is here to force a race condition
            // that happens when creating an outlook appointment and immideately fetching it
            setTimeout(
              () => queryClient.invalidateQueries([queryKeys.BUSY_TIMES]),
              INVALIDATE_BUSY_TIMES_DELAY
            );
          }
        }
      ),
    getPatientAppointments: (
      patientId: string,
      includeCanceled = false,
      options?: UseQueryOptions<Appointment[], AppError>
    ) =>
      useQuery<Appointment[], AppError>(
        [
          queryKeys.PATIENTS,
          patientId,
          querySubKeys[queryKeys.PATIENTS].APPOINTMENTS,
          { includeCanceled }
        ],
        () => getPatientAppointmentsRequest(patientId, { includeCanceled }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_APPOINTMENTS')
            }),
          ...options
        }
      ),
    updateAppointmentStatus: () =>
      useMutation<
        AppointmentServer,
        AppError,
        { appointmentId: string; updatedAppointment: Appointment }
      >(
        ({ appointmentId, updatedAppointment }) =>
          updateAppointmentStatusRequest(appointmentId, updatedAppointment),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_PATIENT_APPOINTMENTS')
            }),
          onSuccess: () => {
            openToast({
              title: t('UPDATE_APPOINTMENT_STATUS_SUCCESS_TOAST_TITLE'),
              type: ToastType.SUCCESS
            });
            queryClient.invalidateQueries([queryKeys.APPOINTMENTS]);
          }
        }
      ),
    updateAppointment: () =>
      useMutation<void, AppError, Partial<Appointment>>(
        (appointment) => {
          const appointmentToUpdate = convertAppointmentClientToServer({
            ...appointment
          });

          const updatedAppointment = updateAppointmentRequest({
            appointment: appointmentToUpdate
          });
          return updatedAppointment;
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_UPDATE_APPOINTMENT')
            }),
          onSuccess: (_, { id, patientId }) => {
            queryClient.invalidateQueries([queryKeys.APPOINTMENTS]);
            queryClient.invalidateQueries([queryKeys.FEED]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              id,
              querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
            ]);
            // Do not delete this setTimeout is here to force a race condition
            // that happens when creating an outlook appointment and immideately fetching it
            setTimeout(
              () => queryClient.invalidateQueries([queryKeys.BUSY_TIMES]),
              INVALIDATE_BUSY_TIMES_DELAY
            );
          }
        }
      ),
    getAppointmentById: (
      appointmentId: string,
      options?: UseQueryOptions<Appointment, AppError>
    ) =>
      useQuery<Appointment, AppError>(
        [
          queryKeys.PATIENTS,
          appointmentId,
          querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
        ],
        () => getAppointmentByIdRequest(appointmentId),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_APPOINTMENT')
            }),
          ...options
        }
      ),
    cancelAppointment: () =>
      useMutation<
        void,
        AppError,
        { appointmentId: string; patientId: string; cancellationReason: string }
      >(
        ({ appointmentId, cancellationReason }) => {
          return cancelAppointmentRequest(appointmentId, cancellationReason);
        },
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_CANCEL_APPOINTMENT')
            }),
          onSuccess: (_, { patientId }) => {
            openToast({
              title: t('APPOINTMENT_CANCELLED_SUCCESSFULLY'),
              type: ToastType.SUCCESS
            });

            queryClient.invalidateQueries([queryKeys.APPOINTMENTS]);
            queryClient.invalidateQueries([
              queryKeys.CLINICS,
              querySubKeys[queryKeys.CLINICS].DASHBOARD_SUMMARY
            ]);
            queryClient.invalidateQueries([
              queryKeys.PATIENTS,
              patientId,
              querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
            ]);
            queryClient.invalidateQueries([queryKeys.FEED]);

            closeDialog();
            // Do not delete this setTimeout is here to force a race condition
            // that happens when creating an outlook appointment and immideately fetching it
            setTimeout(
              () => queryClient.invalidateQueries([queryKeys.BUSY_TIMES]),
              INVALIDATE_BUSY_TIMES_DELAY
            );
          }
        }
      ),
    getAggregatedAppointments: (
      { minDate, maxDate }: RequestRange,
      options?: UseQueryOptions<Appointment[], AppError>
    ) =>
      useQuery<Appointment[], AppError>(
        [
          queryKeys.APPOINTMENTS,
          querySubKeys[queryKeys.APPOINTMENTS].AGGREGATED,
          dateRangeQuerySubkey(minDate, maxDate)
        ],
        () =>
          getAggregatedAppointmentsRequest({
            minDate,
            maxDate
          }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_APPOINTMENTS')
            }),
          ...options
        }
      ),
    getAppointmentsWithCycle: (
      appointments: Appointment[],
      options?: UseQueryOptions<Appointment[], AppError>
    ) =>
      useQuery<any[], AppError>(
        [
          queryKeys.APPOINTMENTS,
          querySubKeys[queryKeys.APPOINTMENTS].CYCLES,
          appointments?.map((appointment) => appointment.cycleId)
        ],
        async () => {
          const result = await Promise.all(
            appointments.map(async (appointment) => {
              if (appointment.cycleId) {
                try {
                  const cycle = await getCycleById(appointment.cycleId);

                  return {
                    ...appointment,
                    cycle: undefined,
                    cycleStartDate: convertDateToDayjs(cycle.startDate),
                    cycleId: cycle.id
                  };
                } catch (error) {
                  return appointment;
                }
              }

              return appointment;
            })
          );

          return result;
        },
        {
          ...options,
          enabled: !!appointments?.length
        }
      ),
    getClinicRooms: (options?: UseQueryOptions<ClinicRoom[], AppError>) =>
      useQuery<ClinicRoom[], AppError>(
        [queryKeys.CLINIC_ROOMS],
        () => getClinicRoomsRequest(),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CLINIC_ROOMS')
            }),
          ...options
        }
      ),
    getClinicRoomBusyTimes: (
      { roomId, minDate, maxDate }: ClincRoomsBusyTimesRequest,
      options?: UseQueryOptions<BusyTime[], AppError>
    ) =>
      useQuery<BusyTime[], AppError>(
        [
          queryKeys.BUSY_TIMES,
          querySubKeys[queryKeys.BUSY_TIMES].ROOM,
          roomId,
          dateRangeQuerySubkey(minDate, maxDate)
        ],
        () =>
          getClinicRoomBusyTimesRequest({
            roomId,
            minDate,
            maxDate
          }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_CLINIC_ROOMS_BUSY_TIMES')
            }),
          ...options
        }
      ),
    getStaffBusyTimes: (
      { staffIds, minDate, maxDate }: StaffBusyTimesRequest,
      options?: UseQueryOptions<BusyTime[], AppError>
    ) =>
      useQuery<BusyTime[], AppError>(
        [
          queryKeys.BUSY_TIMES,
          querySubKeys[queryKeys.BUSY_TIMES].STAFF,
          staffIds,
          dateRangeQuerySubkey(minDate, maxDate)
        ],
        () =>
          getStaffBusyTimesRequest({
            staffIds,
            minDate,
            maxDate
          }),
        {
          onSettled: (data, error) =>
            handleQueryResultToast({
              data,
              error,
              actionName: t('ACTION_TITLE_GET_STAFF_BUSY_TIMES')
            }),
          ...options
        }
      ),
    getAppointmentCodingTemplates: () =>
      useQuery<AppointmentCodingTemplate[], AppError>(
        [
          queryKeys.APPOINTMENTS,
          querySubKeys[queryKeys.APPOINTMENTS].CODING_TEMPLATES
        ],
        () => getAppointmentCodingTemplatesRequest(),
        {
          cacheTime: 0
        }
      )
  };
}

export default useAppointments;
