import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import Flex from 'src/components/layout/Flex/Flex';
import Box from 'src/components/layout/Box/Box';
import Button from 'src/components/display/Button/Button';
import { spacings } from 'src/components/styles/constants';
import Typography from 'src/components/display/Typography/Typography';
import InputField from 'src/components/data-entry/InputField/InputField';
import DatePicker from 'src/components/data-entry/DatePicker/DatePicker';
import Select from 'src/components/data-entry/Select/Select';
import Loader from 'src/components/display/Loader/Loader';
import TextArea from 'src/components/data-entry/TextArea/TextArea';
import TimePicker from 'src/components/data-entry/TimePicker/TimePicker';
import { fontWeights } from 'src/components/styles/fonts';
import InputLabel from 'src/components/data-entry/InputLabel/InputLabel';
import Checkbox from 'src/components/data-entry/Checkbox/Checkbox';
import i18n from '../../../i18n/i18n';
import usePatientsApi from '../../../hooks/usePatientsApi';
import { DoctorChips } from '../../patients/common/DoctorChips';
import {
  formatDateAndTime,
  getDateFormat,
  getTimeFormat,
  roundToNearest15Min
} from 'src/utils/dateAndTIme';
import useAppointments from 'src/hooks/useAppointments';
import {
  Appointment,
  AppointmentPurposes,
  AppointmentTypes,
  ConsultationAppointmentTypes,
  DefaultClinicRoomOptions,
  DiagnosticAppointmentTypes,
  FinancialAppointmentTypes,
  InCycleAppointmentTypes,
  LabOrderBillTo,
  MaleProcedureAppointmentTypes,
  NO_ROOM_OPTION,
  PregnancyAppointmentTypes,
  SurgeryAppointmentTypes,
  ThirdPartyScreeningAppointmentTypes,
  VIRTUAL_LOCATION
} from 'src/types/appointment';
import {
  CreatePatientDocumentPayload,
  orderTemplateId
} from 'src/types/documents';
import { getDocumentAsDocFileFromURL } from '../../documents/utils/getDocumentAsDocFileFromURL';
import useDocumentsApi from 'src/hooks/useDocumentsApi';
import useCareTeam from 'src/hooks/useCareTeam';
import useMeApi from 'src/hooks/useMeApi';
import { getDefaultClinicLogo } from 'src/utils/defaultImages';
import { AppointmentDetails } from '../../patients/overview/AppointmentDetails';
import { useQueryClient } from 'react-query';
import { queryKeys, querySubKeys } from 'src/hooks/queryKeys';
import PatientPartnerSelection from './PatientPartnerSelection';
import { useDialog } from 'src/contexts/UIContexts';

interface RoomOptionType {
  label: string;
  value: string;
}

const LAB_TEST_ID_PREFIX = 'lab-test';

const appointmentTypeOptionsCycle = Object.entries(InCycleAppointmentTypes).map(
  ([label, value]) => ({
    label: i18n.t(label),
    value
  })
);
const appointmentTypeOptionsConsultation = Object.entries(
  ConsultationAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));
const appointmentTypeOptionsDiagnostic = Object.entries(
  DiagnosticAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));
const appointmentTypeOptionsPregnancy = Object.entries(
  PregnancyAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeThirdPartyScreening = Object.entries(
  ThirdPartyScreeningAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsFinancial = Object.entries(
  FinancialAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsMaleProcedures = Object.entries(
  MaleProcedureAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));

const appointmentTypeOptionsSurgery = Object.entries(
  SurgeryAppointmentTypes
).map(([label, value]) => ({
  label: i18n.t(label),
  value
}));
const getAppointmentTypeOptions = (
  appointmentPurpose: AppointmentPurposes
): { label: string; value: AppointmentTypes }[] => {
  switch (appointmentPurpose) {
    case AppointmentPurposes.IN_CYCLE:
      return appointmentTypeOptionsCycle;
    case AppointmentPurposes.CONSULTATION:
      return appointmentTypeOptionsConsultation;
    case AppointmentPurposes.DIAGNOSTIC:
      return appointmentTypeOptionsDiagnostic;
    case AppointmentPurposes.PREGNANCY:
      return appointmentTypeOptionsPregnancy;
    case AppointmentPurposes.THIRD_PARTY_SCREENING:
      return appointmentTypeThirdPartyScreening;
    case AppointmentPurposes.FINANCIAL:
      return appointmentTypeOptionsFinancial;
    case AppointmentPurposes.MALE_PROCEDURES:
      return appointmentTypeOptionsMaleProcedures;
    case AppointmentPurposes.SURGERY:
      return appointmentTypeOptionsSurgery;
  }
};

const appointmentPurposeOptions = Object.entries(AppointmentPurposes).map(
  ([key, value]) => ({
    label: i18n.t(key),
    value: value
  })
);

const ScheduleAndOrdersTab: FC<{
  onDirtyFormChange: (dirty: boolean) => void | Promise<void>;
  appointmentId?: string;
  patientId?: string;
  cycleId?: string;
}> = ({ appointmentId, patientId, cycleId, onDirtyFormChange }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { closeDialog, openDialog } = useDialog();
  const { getAppointmentById } = useAppointments();
  const { getTemplateById } = useDocumentsApi();
  const { getMe } = useMeApi();

  const {
    data: loggedStaffMember,
    isLoading: isLoadingLoggedStaffMember,
    isFetching: isFetchingLoggedStaffMember
  } = getMe();

  const clinicLogoForTemplate = useMemo(() => {
    return getDefaultClinicLogo({
      organizationStringId:
        loggedStaffMember?.user?.clinic?.organization?.stringId,
      isForDocument: true
    });
  }, [loggedStaffMember]);

  // TODO - Bring this back whenever we need internal orders in appointments again
  // const { data: hl7Vendors, isLoading: isLoadingOrderVendors } = getOrdersVendors();

  const {
    data: appointmentToEdit,
    isLoading: isLoadingAppointment,
    isFetching: isFetchingAppointment
  } = getAppointmentById(appointmentId, { enabled: !!appointmentId });

  const { getPatientById, getPanels } = usePatientsApi();

  const { createAppointment, updateAppointment, getClinicRooms } =
    useAppointments();

  const { getPatientPrimaryCareTeamMember } = useCareTeam();

  const { data: panels } = getPanels();
  const {
    data: clinicRooms,
    isLoading: isLoadingClinicRooms,
    isFetching: isFetchingClinicRooms
  } = getClinicRooms();

  const { data: patientFullData } = getPatientById(patientId);

  const {
    data: ordersTemplate,
    isLoading: isLoadingOrdersTemplate,
    isFetching: isFetchingOrdersTemplate
  } = getTemplateById(orderTemplateId);

  const {
    mutate: createAppointmentMutate,
    isLoading: isLoadingCreateAppointment
  } = createAppointment();

  const {
    mutate: updateAppointmentMutate,
    isLoading: isLoadingUpdateAppointment
  } = updateAppointment();

  const endTime = appointmentToEdit
    ? dayjs(appointmentToEdit?.endTime).toDate()
    : roundToNearest15Min(dayjs().add(30, 'minutes')).toDate();

  const startTime = appointmentToEdit
    ? dayjs(appointmentToEdit?.date).toDate()
    : roundToNearest15Min(dayjs()).toDate();

  const defaultValues: Appointment = {
    patientId: patientId || '',
    cycleId: cycleId || '',
    patient: patientFullData?.personalInfo || null,
    date: dayjs(appointmentToEdit?.date).toDate() || null,
    endTime,
    startTime,
    location: appointmentToEdit?.isVirtual
      ? VIRTUAL_LOCATION
      : appointmentToEdit?.location || VIRTUAL_LOCATION,
    room: appointmentToEdit?.room || NO_ROOM_OPTION,
    staffIds: appointmentToEdit?.staffIds || [],
    panelIds:
      appointmentToEdit?.labOrders?.map(
        ({ panelId, labTest }) =>
          panelId || (labTest && `${LAB_TEST_ID_PREFIX}-${labTest?.id}`)
      ) || [],
    appointmentPurpose:
      appointmentToEdit?.appointmentPurpose || AppointmentPurposes.CONSULTATION,
    appointmentType:
      appointmentToEdit?.appointmentType ||
      ConsultationAppointmentTypes.RN_HEALTHCARE_PROFESSIONAL_CONSULT,
    patientNotes: appointmentToEdit?.patientNotes || '',
    isVirtual: appointmentToEdit?.isVirtual || true,
    labOrderInfo: {
      isUrgent: appointmentToEdit?.labOrderInfo?.isUrgent || false,
      billTo: appointmentToEdit?.labOrderInfo?.billTo || LabOrderBillTo.CLINIC,
      performingLabEmail:
        appointmentToEdit?.labOrderInfo?.performingLabEmail || '',
      isFasting: appointmentToEdit?.labOrderInfo?.isFasting || false,
      externalId: appointmentToEdit?.labOrderInfo?.externalId || null
    }
  };

  // TODO - Bring this back whenever we need internal orders in appointments again
  // const labTestOptions = useMemo(
  //   () =>
  //     appointmentToEdit?.labOrders?.map(({ labTest }) => {
  //       if (labTest?.id) {
  //         return {
  //           value: `${LAB_TEST_ID_PREFIX}-${labTest.id}`,
  //           label: t(labTest?.name)
  //         };
  //       }
  //     }),
  //   [appointmentToEdit?.labOrders]
  // );

  // const panelOptions = useMemo(
  //   () =>
  //     panels?.map((panel) => ({
  //       value: panel?.id.toString(),
  //       label: t(panel?.name)
  //     })) || [],
  //   [panels]
  // );

  const {
    control,
    formState,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    clearErrors,
    reset
  } = useForm<Appointment>({
    mode: 'onChange',
    defaultValues
  });

  const { isDirty } = formState;

  useEffect(() => {
    if (isDirty) {
      onDirtyFormChange(true);
    }
  }, [isDirty]);

  useEffect(() => {
    if (appointmentToEdit) {
      reset(defaultValues);
    }
  }, [appointmentToEdit]);

  const { errors } = formState;
  const appointmentDetails = watch();
  const { patientId: chosenPatientId } = watch();

  const {
    data: patientPrimaryPhysician,
    isLoading: isLoadingPatientPrimaryPhysician,
    isFetching: isFetchingPatientPrimaryPhysician
  } = getPatientPrimaryCareTeamMember(chosenPatientId, {
    enabled: !!chosenPatientId
  });

  const isLoadingForm =
    isLoadingAppointment ||
    isLoadingLoggedStaffMember ||
    isFetchingAppointment ||
    isFetchingLoggedStaffMember;

  const isLoadingDocumentOnlyData =
    isLoadingOrdersTemplate ||
    isLoadingPatientPrimaryPhysician ||
    isFetchingOrdersTemplate ||
    isFetchingPatientPrimaryPhysician;

  const { appointmentPurpose, isVirtual, panelIds } = appointmentDetails;

  const selectedStartTime = watch('startTime');
  const selectedEndTime = watch('endTime');

  useEffect(() => {
    if (selectedStartTime && selectedEndTime) {
      trigger('startTime');
      trigger('endTime');
    }
  }, [selectedStartTime, selectedEndTime]);

  const isDocumentRelatedInputsDisabled = !panelIds?.length;

  useEffect(() => {
    if (isDocumentRelatedInputsDisabled) {
      clearErrors('labOrderInfo.performingLabEmail');
    }
  }, [isDocumentRelatedInputsDisabled]);

  const defaultRoomOptions = useMemo(
    () =>
      Object.values(DefaultClinicRoomOptions).map((value) => ({
        value,
        label: t(value)
      })),
    [t]
  );

  const roomOptions: RoomOptionType[] = useMemo(() => {
    if (isLoadingClinicRooms) return [];

    const options: RoomOptionType[] = [];
    options.push({ label: t(NO_ROOM_OPTION), value: NO_ROOM_OPTION });

    if (clinicRooms.length === 0) {
      options.push(...defaultRoomOptions);
    } else {
      options.push(
        ...clinicRooms?.map((room) => ({
          label: t(room?.name),
          value: room?.id
        }))
      );
    }

    return options;
  }, [clinicRooms]);

  const appointmentOptions = useMemo(
    () => getAppointmentTypeOptions(appointmentPurpose),
    [appointmentPurpose]
  );

  const clinicInfo = loggedStaffMember?.user?.clinic;

  const onSubmit = async (appointmentDetails: Appointment) => {
    const reducedLabOrders = [];
    appointmentDetails?.panelIds?.forEach((orderId) => {
      const panel = panels.find(({ id }) => id == orderId);
      if (panel?.name && panel?.code) {
        reducedLabOrders.push({
          name: panel.name,
          code: panel.code
        });
      }
    });

    const labTestIds: string[] = [];
    const panelIds: string[] = [];

    appointmentDetails?.panelIds?.map((orderId: string) => {
      if (orderId.includes(LAB_TEST_ID_PREFIX)) {
        const id: string = orderId.split(LAB_TEST_ID_PREFIX + '-').pop();
        labTestIds.push(id);
      } else {
        panelIds.push(orderId);
      }
    });

    const { patient } = appointmentDetails || {};

    const { billTo } = appointmentDetails?.labOrderInfo || {};

    const isBillToPatient = billTo === LabOrderBillTo.PATIENT;

    if (isVirtual) {
      delete appointmentDetails.location;
      delete appointmentDetails.room;
    }

    const startHour = dayjs(appointmentDetails?.startTime).hour();
    const startMinutes = dayjs(appointmentDetails?.startTime).minute();
    const updatedStartDate = dayjs(appointmentDetails?.date)
      .hour(startHour)
      .minute(startMinutes)
      .startOf('minute')
      .toDate();

    const endHour = dayjs(appointmentDetails?.endTime).hour();
    const endMinutes = dayjs(appointmentDetails?.endTime).minute();
    const updatedEndDate = dayjs(appointmentDetails?.date)
      .hour(endHour)
      .minute(endMinutes)
      .startOf('minute')
      .toDate();

    const room =
      appointmentDetails?.room === NO_ROOM_OPTION
        ? null
        : appointmentDetails?.room;

    if (appointmentToEdit) {
      appointmentDetails.id = appointmentToEdit.id;
      updateAppointmentMutate(
        {
          ...appointmentDetails,
          room,
          date: updatedStartDate,
          endTime: updatedEndDate,
          labTestIds,
          panelIds
        },
        {
          onSuccess: () => closeDialog()
        }
      );
    } else {
      let ordersDocumentPayload: CreatePatientDocumentPayload;

      if (appointmentDetails?.panelIds?.length) {
        const orderTemplateMetaData = {
          clinic_logo: {
            image_url: clinicLogoForTemplate || '',
            width: 100,
            height: 100
          },
          is_urgent: appointmentDetails?.labOrderInfo?.isUrgent
            ? i18n.t('YES')
            : i18n.t('NO'),
          order_date: formatDateAndTime(dayjs().toDate()),
          bill_to:
            appointmentDetails?.labOrderInfo?.billTo === LabOrderBillTo.PATIENT
              ? t(LabOrderBillTo.PATIENT)
              : t(LabOrderBillTo.CLINIC),
          order_reason: i18n.t(appointmentDetails?.appointmentPurpose) || '',
          is_fasting: appointmentDetails?.labOrderInfo?.isFasting
            ? i18n.t('YES')
            : i18n.t('NO'),
          clinic_patient_address: isBillToPatient
            ? patient?.address
            : clinicInfo?.address || '',
          clinic_patient_city: isBillToPatient
            ? patient?.city
            : clinicInfo?.city || '',
          clinic_patient_zip: isBillToPatient
            ? patient?.postalCode
            : clinicInfo?.postalCode || '',
          clinic_patient_state: isBillToPatient
            ? patient?.state
            : clinicInfo?.state || '',
          clinic_patient_phone: isBillToPatient
            ? patient?.phoneNumber
            : clinicInfo?.phoneNumber || '',
          clinic_doctor_id: patientPrimaryPhysician?.id || '',
          doctors_last_name: patientPrimaryPhysician.lastName || '',
          doctors_first_name: patientPrimaryPhysician.firstName || '',
          doctors_license: patientPrimaryPhysician.licenseNumber || '',
          patient_clinic_id: `${patient?.id || ''}`,
          patient_dob: dayjs(patient?.dateOfBirth).format(getDateFormat()),
          patient_email: patient?.email || '',
          patient_first_name: patient?.firstName || '',
          patient_last_name: patient?.lastName || '',
          patient_phone: patient?.phoneNumber || '',
          patient_gender: patient?.sexAtBirth ? t(patient?.sexAtBirth) : '',
          staff_surname: '',
          staff_firstname: '',
          signing_date_time: '',
          todays_date: '',
          orders: reducedLabOrders
        };

        const file: File = await getDocumentAsDocFileFromURL({
          template: ordersTemplate
        });

        ordersDocumentPayload = {
          file,
          patientId,
          templateId: orderTemplateId,
          metadata: JSON.stringify(orderTemplateMetaData),
          isCompleted: false
        };
      }

      createAppointmentMutate(
        {
          appointment: {
            ...appointmentDetails,
            date: updatedStartDate,
            endTime: updatedEndDate,
            labTestIds,
            panelIds
          },
          ordersDocumentPayload
        },
        {
          onSuccess: (newAppointment) => {
            const invalidateAppointments = (id: string) => {
              queryClient.invalidateQueries([
                queryKeys.PATIENTS,
                id,
                querySubKeys[queryKeys.PATIENTS].APPOINTMENTS
              ]);
            };

            if (patientFullData?.hasPartner) {
              const mainId = patientFullData.personalInfo.id;
              const partnerId = patientFullData.partnerInfo.id;
              invalidateAppointments(
                chosenPatientId === mainId ? partnerId : mainId
              );
            } else {
              invalidateAppointments(patientId);
            }

            closeDialog();

            if (newAppointment) {
              openDialog({
                fullWidth: true,
                maxWidth: 'sm',
                removeDefaultPadding: true,
                hideCloseButton: true,
                children: <AppointmentDetails appointment={newAppointment} />
              });
            }
          }
        }
      );
    }
  };

  const isSubmitDisabled =
    isLoadingCreateAppointment ||
    isLoadingUpdateAppointment ||
    (!!panelIds?.length && isLoadingDocumentOnlyData);

  useEffect(() => {
    if (patientFullData) {
      reset(defaultValues);
      setValue('patientId', patientFullData.personalInfo.id);
    }
  }, [patientFullData, setValue]);

  if (isLoadingForm) return <Loader />;

  return (
    <form noValidate>
      <Flex flexDirection="column" gap={spacings.medium}>
        <Flex gap={spacings.large} flexDirection="column">
          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1} id="edit-appointment-patient-select-container">
              <PatientPartnerSelection
                patientId={patientId}
                control={control}
                getValues={getValues}
                setValue={setValue}
                errors={errors}
              />
            </Box>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              gap={spacings.large}
            >
              <Box
                height="100%"
                flex={0.5}
                id="edit-appointment-date-container"
              >
                <Controller
                  name="date"
                  control={control}
                  rules={{
                    required: t('DATE_OF_APPOINTMENT_REQUIRED'),
                    validate: (value) =>
                      dayjs(value).isBefore(dayjs(), 'day')
                        ? t('DATE_MUST_NOT_BE_IN_PAST')
                        : true
                  }}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label={t('DATE_OF_APPOINTMENT')}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      fullWidth
                      onChange={(date) => {
                        onChange(date);
                      }}
                    />
                  )}
                />
              </Box>
              <Flex
                height="100%"
                flex={1}
                alignItems="center"
                gap={spacings.large}
              >
                <Box height="100%" id="edit-appointment-start-time-container">
                  <Controller
                    name="startTime"
                    control={control}
                    rules={{
                      required: t('TIME_REQUIRED'),
                      validate: {
                        endTimeAfterStartTime: (value) => {
                          if (!value || !selectedEndTime) {
                            return true;
                          }
                          return (
                            value < selectedEndTime ||
                            t('START_TIME_BEFORE_END_TIME')
                          );
                        }
                      }
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <TimePicker
                        {...field}
                        format={getTimeFormat({ isShort: true })}
                        label={t('TIME_LABEL')}
                        inputRef={ref}
                        error={!!errors.startTime}
                        helperText={errors.startTime?.message}
                      />
                    )}
                  />
                </Box>
                <Flex
                  height="100%"
                  justifyContent="flex-start"
                  flexDirection="column"
                >
                  <Typography
                    marginTop="40px"
                    fontWeight={fontWeights.extraBold}
                  >
                    {t('TO_LABEL')}
                  </Typography>
                </Flex>
                <Box height="100%" id="edit-appointment-end-time-container">
                  <Controller
                    name="endTime"
                    control={control}
                    rules={{
                      required: t('TIME_REQUIRED'),
                      validate: {
                        startTimeBeforeEndTime: (value) => {
                          if (!value || !selectedStartTime) {
                            return true;
                          }

                          return (
                            value > selectedStartTime ||
                            t('END_TIME_AFTER_START_TIME')
                          );
                        }
                      }
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <TimePicker
                        {...field}
                        format={getTimeFormat({ isShort: true })}
                        label="&nbsp;"
                        inputRef={ref}
                        error={!!errors.endTime}
                        helperText={errors.endTime?.message}
                      />
                    )}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>

          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1}>
              <InputLabel label={t('APPOINTMENT_PURPOSE_LABEL')} />
              <Controller
                name="appointmentPurpose"
                control={control}
                rules={{
                  required: t('APPOINTMENT_PURPOSE_REQUIRED')
                }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Select
                    {...field}
                    inputRef={ref}
                    id="add-appointment-purpose-select"
                    error={!!errors.appointmentType}
                    helperText={errors.appointmentType?.message}
                    defaultOption={t('APPOINTMENT_PURPOSE_DEFAULT_OPTION')}
                    options={appointmentPurposeOptions}
                    onChange={(e) => {
                      setValue(
                        'appointmentType',
                        getAppointmentTypeOptions(
                          e.target.value as AppointmentPurposes
                        )[0].value
                      );
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                name="location"
                control={control}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <Flex flexDirection="column">
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        height="100%"
                      >
                        <InputLabel label={t('LOCATION_LABEL')} />
                        <Box>
                          <Controller
                            name="isVirtual"
                            control={control}
                            render={({
                              field: { ref: _ref, onChange, value, ...field }
                            }) => (
                              <Checkbox
                                label={t('VIRTUAL')}
                                checked={value}
                                {...field}
                                onChange={(_, isChecked) => {
                                  setValue(
                                    'location',
                                    isChecked ? t('VIRTUAL') : t('CLINIC')
                                  );
                                  onChange(isChecked);
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Flex>
                      <Box>
                        <InputField
                          {...field}
                          disabled={isVirtual}
                          inputRef={ref}
                          error={!!errors.location}
                          helperText={errors.location?.message}
                        />
                      </Box>
                    </Flex>
                  );
                }}
              />
            </Box>
          </Flex>

          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1}>
              <Controller
                name="appointmentType"
                control={control}
                rules={{
                  required: t('APPOINTMENT_TYPE_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    id="add-appointment-type-select"
                    inputRef={ref}
                    label={t('APPOINTMENT_TYPE_LABEL')}
                    error={!!errors.appointmentType}
                    helperText={errors.appointmentType?.message}
                    defaultOption={t('APPOINTMENT_TYPE_DEFAULT_OPTION')}
                    options={appointmentOptions}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                name="room"
                control={control}
                rules={{
                  required: t('APPOINTMENT_ROOM_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    id="add-appointment-room-select"
                    disabled={isVirtual}
                    inputRef={ref}
                    label={t('APPOINTMENT_ROOM_LABEL')}
                    error={!!errors.room}
                    helperText={errors.room?.message}
                    defaultOption={t('APPOINTMENT_ROOM_DEFAULT_OPTION')}
                    options={roomOptions}
                    isLoading={isLoadingClinicRooms || isFetchingClinicRooms}
                  />
                )}
              />
            </Box>
          </Flex>

          <Flex flex={1} gap={spacings.x2large} justifyContent="space-between">
            <Box flex={1}>
              <Controller
                name="staffIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="add-edit-appointment-doctor-chips"
                    showSelectedValue
                    label={t('CHOOSE_PHYSICIAN_LABEL')}
                    value={value}
                    onAddChip={(newSelectedDoctorId) => {
                      if (value.includes(newSelectedDoctorId)) {
                        onChange(
                          value.filter(
                            (doctor) => doctor !== newSelectedDoctorId
                          )
                        );
                      } else onChange([...value, newSelectedDoctorId]);
                    }}
                  />
                )}
              />
            </Box>

            <Flex flexDirection="column" gap={spacings.large} flex={1}>
              <Box flex={1}>
                <Controller
                  name="patientNotes"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <TextArea
                      {...field}
                      ref={ref}
                      label={t('NOTES_FOR_PATIENT_LABEL')}
                      placeholder={t('NOTES_FOR_PATIENT_PLACEHOLDER')}
                      error={!!errors.patientNotes}
                      helperText={errors.patientNotes?.message}
                      minRows={3}
                      maxRows={3}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {/* <Flex> // TODO - Bring this back whenever we need internal orders in appointments again
            <Flex flex={2} flexDirection="column" gap={spacings.medium}>
              <Typography variant="h1">{t('SET_ORDERS_TITLE')}</Typography>
              <Controller
                name="panelIds"
                control={control}
                render={({ field: { onChange, value: orders } }) => (
                  <Chips
                    isLoading={isLoadingPanels || isFetchingPanels}
                    title={t('CHOOSE_PANELS').toUpperCase()}
                    renderSelectedOptionsOutside
                    value={orders}
                    options={[...panelOptions, ...labTestOptions]}
                    onAddChip={(chipValue) => {
                      onChange([...orders, chipValue]);
                    }}
                    onRemoveChip={(chipValue) => {
                      onChange(orders?.filter((order) => order !== chipValue));
                    }}
                  />
                )}
              />
            </Flex>
          </Flex> */}
      </Flex>
      <Flex
        gap={spacings.large}
        justifyContent="flex-end"
        marginTop={spacings.large}
        alignItems="center"
      >
        {/* <Box flex={2}> // TODO - Bring this back whenever we need internal orders in appointments again
            <Controller
              name="labOrderInfo.performingLabEmail"
              control={control}
              rules={{
                required: isDocumentRelatedInputsDisabled
                  ? false
                  : t('LAB_REQUIRED')
              }}
              render={({ field: { ref, ...field } }) => (
                <Select
                  {...field}
                  label={t('CHOOSE_LAB').toUpperCase()}
                  inputRef={ref}
                  error={!!errors.labOrderInfo?.performingLabEmail}
                  helperText={errors.labOrderInfo?.performingLabEmail?.message}
                  defaultOption={t('CHOOSE_LAB')}
                  disabled={isDocumentRelatedInputsDisabled}
                  isLoading={isLoadingOrderVendors || isFetchingOrderVendors}
                  options={
                    hl7Vendors?.map(({ name, email }) => ({
                      label: name,
                      value: email
                    })) || []
                  }
                />
              )}
            />
          </Box>

          <Box flex={2}>
            <Controller
              name="labOrderInfo.billTo"
              control={control}
              render={({ field: { ref, value, ...field } }) => (
                <Select
                  {...field}
                  label={t('BILL_TO').toUpperCase()}
                  inputRef={ref}
                  value={value}
                  error={!!errors.labOrderInfo?.billTo}
                  helperText={errors.labOrderInfo?.billTo?.message}
                  defaultOption={t('BILL_TO')}
                  options={billToOptions}
                  disabled={isDocumentRelatedInputsDisabled}
                />
              )}
            />
          </Box>
          <Box>
            <InputLabel label={t('URGENT')} sx={{ margin: spacings.small }} />
            <Controller
              name="labOrderInfo.isUrgent"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  switchOnText={t('YES')}
                  switchOffText={t('NO')}
                  checked={value}
                  onChange={(_, checked) => onChange(checked)}
                  disabled={isDocumentRelatedInputsDisabled}
                />
              )}
            />
          </Box>
          <Box>
            <InputLabel
              label={t('Fasting').toUpperCase()}
              sx={{ margin: spacings.small }}
            />
            <Controller
              name="labOrderInfo.isFasting"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Switch
                  switchOnText={t('YES')}
                  switchOffText={t('NO')}
                  checked={value}
                  onChange={(_, checked) => onChange(checked)}
                  disabled={isDocumentRelatedInputsDisabled}
                />
              )}
            />
          </Box> */}
        <Box marginTop={spacings.medium}>
          <Button
            type="submit"
            disabled={isSubmitDisabled}
            onClick={handleSubmit(onSubmit)}
          >
            {t('CONFIRM')}
          </Button>
        </Box>
      </Flex>
    </form>
  );
};

export default ScheduleAndOrdersTab;
