import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { styled } from '@mui/material';

import { getFullName } from 'src/utils/general';
import useGeneralApi from 'src/hooks/useGeneralApi';
import useStaffMembers from 'src/hooks/useStaffMembers';
import useCodes from 'src/hooks/useCodes';
import useClinicsApi from 'src/hooks/useClinicsApi';
import { YesOrNo } from 'src/types/global';
import { VendorNames, VendorsKey } from 'src/types/hl7messages';
import { ExternalOrderFormServer } from 'src/types/patient-server';
import {
  ExternalOrderBundle,
  ExternalOrderForm,
  LabOrderBillTo,
  OrderPurpose,
  Urgency
} from 'src/types/appointment';
import Loader from 'src/components/display/Loader/Loader';
import Box from 'src/components/layout/Box/Box';
import {
  iconSizes,
  spacings,
  spacingsPercentage
} from 'src/components/styles/constants';
import InputField from 'src/components/data-entry/InputField/InputField';
import Flex from 'src/components/layout/Flex/Flex';
import Select from 'src/components/data-entry/Select/Select';
import Chips from 'src/components/data-entry/Chips/Chips';
import Typography from 'src/components/display/Typography';
import TextArea from 'src/components/data-entry/TextArea';
import Button from 'src/components/display/Button';
import MultiSelect, {
  MultiSelectOption
} from 'src/components/data-entry/MultiSelect/MultiSelect';
import InputLabel from 'src/components/data-entry/InputLabel';
import { Icd10Chips } from '../common/Icd10Chips';
import { ServiceType } from 'src/types/codes';
import { DoctorChips } from '../common/DoctorChips';
import usePatientsApi from '../../../hooks/usePatientsApi';

const StyledLoaderBox = styled(Box)`
  display: flex;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const allowedVendors = [VendorsKey.Labcorp, VendorsKey.Tosho];

export const AddExternalOrderForm: FC<{ patientId: string }> = ({
  patientId
}) => {
  const [isProcedureOnly, setIsProcedureOnly] = useState(false);
  const [selectedBundleNames, setSelectedBundleNames] = useState<string[]>([]);
  const [isLoadingBundleData, setIsLoadingBundleData] = useState(false);
  const [
    selectedBundlesProcedureOrderIcd10Codes,
    setSelectedBundlesProcedureOrderIcd10Codes
  ] = useState<string[]>([]);
  const [
    selectedBundlesLabOrderIcd10Codes,
    setSelectedBundlesLabOrderIcd10Codes
  ] = useState<string[]>([]);

  const { t } = useTranslation();
  const { getPatientById, createOrder } = usePatientsApi();
  const { getBillingServices, getIcd10Codes } = useCodes();
  const { getOrdersVendors } = useGeneralApi();
  const { getStaffMemberById } = useStaffMembers();
  const { getClinicExternalOrderBundles } = useClinicsApi();
  const { data: patient, isLoading: isLoadingPatient } =
    getPatientById(patientId);

  const primaryPhysicianId = patient?.primaryPhysician;

  const defaultValues: ExternalOrderForm = {
    patientId,
    patientName: '',
    purpose: null,
    labTestsIds: [],
    labInstructions: '',
    labOrderIcd10Codes: [],
    proceduresInstructions: '',
    procedureOrderIcd10Codes: [],
    proceduresIds: [],
    requestingPhysician: null,
    billTo: LabOrderBillTo.PATIENT,
    vendorId: null,
    urgency: Urgency.ROUTINE,
    isFasting: YesOrNo.NO
  };

  const {
    data: clinicExternalOrderBundles,
    isLoading: isLoadingClinicExternalOrderBundles,
    isFetching: isFetchingClinicExternalOrderBundles
  } = getClinicExternalOrderBundles();

  const {
    data: patientPrimaryStaffMember,
    isLoading: isLoadingPatientPrimaryStaffMember,
    isFetching: isFetchingPatientPrimaryStaffMember
  } = getStaffMemberById(primaryPhysicianId, {
    enabled: !!primaryPhysicianId
  });

  const { data: procedureItems, isLoading: isLoadingProcedureItems } =
    getBillingServices({ serviceType: ServiceType.PROCEDURE });
  const {
    data: vendors,
    isLoading: isLoadingOrderVendors,
    isFetching: isFetchingOrderVendors
  } = getOrdersVendors();

  const { mutate: handleCreateOrder, isLoading: isSubmittingOrder } =
    createOrder();

  const billToOptions = useMemo(
    () =>
      Object.entries(LabOrderBillTo).map(([key, value]) => ({
        label: t(key),
        value: value
      })),
    [t]
  );

  const urgencyOptions = useMemo(
    () =>
      Object.entries(Urgency).map(([key, value]) => ({
        label: t(key),
        value: value
      })),
    [t]
  );

  const yesNoOptions = useMemo(
    () => [
      { label: t(YesOrNo.YES), value: YesOrNo.YES },
      { label: t(YesOrNo.NO), value: YesOrNo.NO }
    ],
    [t]
  );

  const orderPurposeOptions = useMemo(
    () =>
      Object.entries(OrderPurpose).map(([key, value]) => ({
        label: t(key),
        value: value
      })),
    [t]
  );

  const procedureItemsOptions = useMemo(
    () =>
      procedureItems?.map(({ id, serviceDescription }) => ({
        label: serviceDescription,
        value: id,
        labelText: serviceDescription
      })) || [],
    [procedureItems]
  );

  const vendorsOptions = useMemo(
    () =>
      vendors?.map(({ name, id }) => ({
        label: name,
        value: id
      })) || [],
    [vendors]
  );

  const { control, formState, handleSubmit, setValue, watch } = useForm<
    ExternalOrderForm,
    unknown
  >({
    mode: 'onChange',
    defaultValues
  });
  const { errors } = formState;

  const watchProceduresIds = watch('proceduresIds');
  const watchLabTestsIds = watch('labTestsIds');
  const watchVendorId = watch('vendorId');
  const isLabTestsSelected = watchLabTestsIds.length > 0;
  const watchVendor = vendors?.find((vendor) => vendor.id === watchVendorId);
  const watchVendorKey = watchVendor?.key;

  const { data: labs, refetch: refetchLabs } = getBillingServices({
    serviceType: ServiceType.LAB,
    withVendors: true,
    vendorKeys: [watchVendorKey]
  });

  const bundlesWithIds: ExternalOrderBundle[] = useMemo(
    () =>
      clinicExternalOrderBundles?.map((bundle) => {
        return {
          ...bundle,
          loincList: bundle.loincList, // Keep LOINC codes as is - (Due to the fact that the Labs are not fetched yet)
          cptList: bundle.cptList?.map(
            (cpt) =>
              procedureItems?.find((proc) => proc.billingCode === cpt)?.id
          )
        };
      }),
    [clinicExternalOrderBundles, procedureItems]
  );

  const bundleOptions: MultiSelectOption[] = useMemo(
    () =>
      bundlesWithIds?.map((bundle) => ({
        label: bundle.bundleName,
        value: bundle.bundleName
      })),
    [bundlesWithIds]
  );

  const getLabTestIdsFromBundles = (
    selectedBundleNames: string[]
  ): string[] => {
    const selectedBundleObjects = bundlesWithIds?.filter((bundle) =>
      selectedBundleNames.includes(bundle.bundleName)
    );

    return selectedBundleObjects?.flatMap(
      (bundle) =>
        bundle.loincList
          ?.map((loinc) => labs?.find((lab) => lab.orderCode === loinc)?.id)
          .filter(Boolean) || []
    );
  };

  const {
    data: procedureOrderIcd10Codes,
    isLoading: isLoadingProcedureOrderICD10Codes,
    isFetching: isFetchingProcedureOrderICD10Codes
  } = getIcd10Codes({ icd10Codes: selectedBundlesProcedureOrderIcd10Codes });
  const {
    data: labOrderIcd10Codes,
    isLoading: isLoadingLabOrderICD10Codes,
    isFetching: isFetchingLabOrderICD10Codes
  } = getIcd10Codes({ icd10Codes: selectedBundlesLabOrderIcd10Codes });

  const handleBundleChange = async (selectedBundleNames: string[]) => {
    const bundlesProcedureOrderCodes = [];
    const bundlesLabOrderCodes = [];

    setIsLoadingBundleData(true);
    setSelectedBundleNames(selectedBundleNames);

    const selectedBundleObjects = bundlesWithIds.filter((bundle) =>
      selectedBundleNames.includes(bundle.bundleName)
    );

    selectedBundleObjects.forEach((bundleObject) => {
      if (bundleObject.labOrderIcd10Codes) {
        bundlesLabOrderCodes.push(...bundleObject.labOrderIcd10Codes);
      }

      if (bundleObject.procedureOrderIcd10Codes) {
        bundlesProcedureOrderCodes.push(
          ...bundleObject.procedureOrderIcd10Codes
        );
      }
    });

    setSelectedBundlesProcedureOrderIcd10Codes(bundlesProcedureOrderCodes);
    setSelectedBundlesLabOrderIcd10Codes(bundlesLabOrderCodes);

    if (selectedBundleNames.length === 0) {
      setValue('vendorId', null);
      setValue('purpose', null);
      setValue('urgency', null);
      setValue('labTestsIds', []);
      setValue('proceduresIds', []);
      setValue('labOrderIcd10Codes', []);
      setValue('procedureOrderIcd10Codes', []);
    } else {
      if (!watchVendorKey || !allowedVendors.includes(watchVendorKey)) {
        const selectedVendors = allowedVendors.map((vendorKey) =>
          vendors?.find((vendor) => vendor.name === VendorNames[vendorKey])
        );

        const selectedBundlesObjects = bundlesWithIds.filter((bundle) =>
          selectedBundleNames.includes(bundle.bundleName)
        );

        const isBundleWithAllowedVendorSelected = selectedBundlesObjects.some(
          (bundle) =>
            selectedVendors.some((vendor) => vendor?.name === bundle.vendorName)
        );

        if (isBundleWithAllowedVendorSelected) {
          const selectedVendor = selectedVendors.find(
            (vendor) => vendor?.name === selectedBundlesObjects[0].vendorName
          );
          setValue('vendorId', selectedVendor?.id);
          await refetchLabs();
        }
      }

      setValue('purpose', selectedBundleObjects?.[0].purpose);
      setValue('urgency', selectedBundleObjects?.[0].urgency);
    }

    const procedureIds = selectedBundleObjects.flatMap(
      (bundle) => bundle.cptList?.filter(Boolean) || []
    );

    setValue('proceduresIds', [...new Set(procedureIds)]);
    setIsLoadingBundleData(false);
  };

  const labItemsOptions = useMemo(
    () =>
      labs?.map(({ id, serviceDescription }) => ({
        label: serviceDescription,
        value: id,
        labelText: serviceDescription
      })) || [],
    [labs]
  );

  const onSubmit = (details: ExternalOrderForm) => {
    const orderDetails: ExternalOrderFormServer = {
      ...details,
      isFasting: details?.isFasting === YesOrNo.YES ? true : false
    };
    handleCreateOrder(orderDetails);
  };

  useEffect(() => {
    if (watchVendorKey) {
      refetchLabs();
    }
  }, [watchVendorKey, refetchLabs]);

  useEffect(() => {
    if (labs) {
      const labTestIds = getLabTestIdsFromBundles(selectedBundleNames);
      setValue('labTestsIds', [...new Set(labTestIds)]);
    }
  }, [labs, selectedBundleNames, setValue]);

  useEffect(() => {
    if (procedureOrderIcd10Codes?.length) {
      setValue('procedureOrderIcd10Codes', [
        ...new Set(procedureOrderIcd10Codes)
      ]);
    }
  }, [procedureOrderIcd10Codes, setValue]);

  useEffect(() => {
    if (labOrderIcd10Codes?.length) {
      setValue('labOrderIcd10Codes', [...new Set(labOrderIcd10Codes)]);
    }
  }, [labOrderIcd10Codes, setValue]);

  useEffect(() => {
    if (!patient) return;
    setValue('patientName', getFullName(patient.personalInfo));
  }, [patient, setValue]);

  useEffect(() => {
    if (!patientPrimaryStaffMember) return;
    setValue('requestingPhysician', patientPrimaryStaffMember.id);
  }, [patientPrimaryStaffMember, setValue]);

  useEffect(() => {
    setIsProcedureOnly(
      watchProceduresIds.length > 0 && watchLabTestsIds.length === 0
    );
  }, [watchProceduresIds, watchLabTestsIds]);

  if (
    isLoadingPatient ||
    isLoadingOrderVendors ||
    isLoadingProcedureItems ||
    isLoadingPatientPrimaryStaffMember ||
    isFetchingPatientPrimaryStaffMember ||
    isLoadingClinicExternalOrderBundles
  ) {
    return (
      <StyledLoaderBox>
        <Loader size={iconSizes.large} />
      </StyledLoaderBox>
    );
  }

  return (
    <Box marginTop={spacings.large}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={spacings.medium} flexDirection="column">
          <Box>
            <Typography variant="h4" align="center">
              {t('EXTERNAL_ORDER_FORM_SUB_HEADER')}
            </Typography>
          </Box>
          <Flex flex="1" gap={spacings.large}>
            <Box width="25%">
              <Controller
                name="patientName"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <InputField
                    {...field}
                    inputRef={ref}
                    disabled
                    label={t('PATIENT_NAME')}
                    placeholder={t('PATIENT_NAME')}
                    error={!!errors.patientName}
                    helperText={errors?.patientName?.message}
                    required
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box width="25%">
              <Controller
                name="purpose"
                control={control}
                rules={{
                  required: t('PURPOSE_REQUIRED')
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('PICK_PURPOSE')}
                    inputRef={ref}
                    error={!!errors?.purpose}
                    helperText={errors?.purpose?.message}
                    options={orderPurposeOptions}
                    defaultOption={t('PICK_A_PURPOSE')}
                  />
                )}
              />
            </Box>
            <Box width="25%">
              <InputLabel label={t('PICK_BUNDLES').toUpperCase()} />
              <MultiSelect
                options={bundleOptions}
                value={selectedBundleNames}
                onChange={handleBundleChange}
                placeholder={t('PICK_BUNDLES')}
                disabled={
                  isLoadingBundleData || isFetchingClinicExternalOrderBundles
                }
                displayEmpty
                withCheckbox
              />
            </Box>
            <Box width="25%">
              <Controller
                name="requestingPhysician"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DoctorChips
                    id="edit-order-requesting-physician"
                    showSelectedValue
                    label={t('REQUESTING_PROVIDER')}
                    value={[value]}
                    onAddChip={(newSelectedDoctorId) =>
                      onChange(newSelectedDoctorId)
                    }
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex flex="1" gap={spacings.large} marginTop={spacings.medium}>
            <Box
              width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}
            >
              <Controller
                name="proceduresIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    renderSelectedOptionsOutside
                    value={value}
                    titleComponent={
                      <Typography variant="h3" align="center">
                        {t('SET_PROCEDURE_ORDERS')}
                      </Typography>
                    }
                    options={procedureItemsOptions}
                    onAddChip={(chipValue) => onChange([...value, chipValue])}
                    onRemoveChip={(chipValue) => {
                      onChange(value?.filter((val) => val !== chipValue));
                    }}
                    shouldSortOptions
                  />
                )}
              />
            </Box>
            <Box
              width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}
            >
              <Controller
                name="proceduresInstructions"
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    label={t('PROCEDURE_INSTRUCTIONS').toUpperCase()}
                    error={!!errors?.proceduresInstructions}
                    helperText={errors?.proceduresInstructions?.message}
                    labelProps={{ rowGap: spacings.small }}
                    fullWidth
                    minRows={3}
                    resize="auto"
                  />
                )}
              />
            </Box>
            <Box
              minHeight="30%"
              maxHeight="30%"
              marginBottom={spacings.x2large}
              width={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
              maxWidth={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
            >
              <Icd10Chips
                control={control}
                name="procedureOrderIcd10Codes"
                error={!!errors.procedureOrderIcd10Codes}
                helperText={errors?.procedureOrderIcd10Codes?.message}
                label={t('ENTER_DIAGNOSTIC_CODE')}
                disabled={
                  isLoadingProcedureOrderICD10Codes ||
                  isFetchingProcedureOrderICD10Codes
                }
              />
            </Box>
          </Flex>
          <Flex flex="1" gap={spacings.large}>
            <Box width="25%">
              <Controller
                name="vendorId"
                control={control}
                rules={{
                  required: !isProcedureOnly
                    ? t('PERFORMING_LAB_REQUIRED')
                    : undefined
                }}
                render={({ field: { ref, ...field } }) => (
                  <Select
                    {...field}
                    label={t('CHOOSE_LAB').toUpperCase()}
                    inputRef={ref}
                    error={!!errors?.vendorId}
                    helperText={errors?.vendorId?.message}
                    defaultOption={t('CHOOSE_LAB')}
                    isLoading={isLoadingOrderVendors || isFetchingOrderVendors}
                    options={vendorsOptions}
                    disabled={isLabTestsSelected}
                  />
                )}
              />
            </Box>
            <Box width="25%">
              <Controller
                name="billTo"
                control={control}
                rules={{
                  required: t('BILL_TO_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <Select
                    {...field}
                    label={t('BILL_TO').toUpperCase()}
                    inputRef={ref}
                    value={value}
                    error={!!errors?.billTo}
                    helperText={errors?.billTo?.message}
                    defaultOption={t('BILL_TO')}
                    options={billToOptions}
                  />
                )}
              />
            </Box>
            <Box width="25%">
              <Controller
                name="urgency"
                control={control}
                rules={{
                  required: t('URGENT_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <Select
                    {...field}
                    label={t('URGENT').toUpperCase()}
                    inputRef={ref}
                    value={value}
                    error={!!errors?.urgency}
                    defaultOption={t('CHOOSE_URGENCY')}
                    helperText={errors?.urgency?.message}
                    options={urgencyOptions}
                  />
                )}
              />
            </Box>
            <Box width="25%">
              <Controller
                name="isFasting"
                control={control}
                rules={{
                  required: t('FASTING_REQUIRED')
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <Select
                    {...field}
                    label={t('Fasting').toUpperCase()}
                    inputRef={ref}
                    value={value}
                    error={!!errors?.isFasting}
                    helperText={errors?.isFasting?.message}
                    defaultOption={t('IS_FASTING')}
                    options={yesNoOptions}
                  />
                )}
              />
            </Box>
          </Flex>
          <Flex flex="1" gap={spacings.large} marginTop={spacings.medium}>
            <Box
              width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}
            >
              <Typography
                visibility={watchVendorId ? 'hidden' : 'visible'}
                variant="caption"
                align="center"
              >
                {t('CHOOSE_LAB_IN_ORDER_TO_SELECT_LABS')}
              </Typography>
              <Controller
                name="labTestsIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Chips
                    renderSelectedOptionsOutside
                    value={value}
                    titleComponent={
                      <Typography variant="h3" align="center">
                        {t('SET_LAB_ORDERS')}
                      </Typography>
                    }
                    options={labItemsOptions}
                    onAddChip={(chipValue) => onChange([...value, chipValue])}
                    onRemoveChip={(chipValue) => {
                      onChange(value?.filter((val) => val !== chipValue));
                    }}
                    shouldSortOptions
                    disabled={!watchVendorId}
                  />
                )}
              />
            </Box>
            <Box
              width={`calc(${spacingsPercentage.xlarge} - ${spacings.large})`}
            >
              <Controller
                name="labInstructions"
                control={control}
                render={({ field: { ref: _ref, ...field } }) => (
                  <TextArea
                    {...field}
                    label={t('LAB_INSTRUCTIONS').toUpperCase()}
                    error={!!errors?.labInstructions}
                    helperText={errors?.labInstructions?.message}
                    fullWidth
                    minRows={3}
                    resize="auto"
                    disabled={!watchVendorId}
                    labelProps={{ rowGap: spacings.small }}
                  />
                )}
              />
            </Box>
            <Box
              minHeight="30%"
              maxHeight="30%"
              marginBottom={spacings.x2large}
              width={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
              maxWidth={`calc(${spacingsPercentage.medium} - ${spacings.large})`}
            >
              <Icd10Chips
                control={control}
                name="labOrderIcd10Codes"
                error={!!errors.labOrderIcd10Codes}
                helperText={errors?.labOrderIcd10Codes?.message}
                label={t('ENTER_DIAGNOSTIC_CODE')}
                disabled={
                  isLoadingLabOrderICD10Codes || isFetchingLabOrderICD10Codes
                }
              />
            </Box>
          </Flex>
        </Flex>
        <Flex justifyContent="end" marginTop={spacings.large}>
          <Box>
            <Button fullWidth type="submit" disabled={isSubmittingOrder}>
              {isSubmittingOrder ? <Loader /> : t('SUBMIT')}
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};
