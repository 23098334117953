import { CriticalAppError } from 'src/contexts/AppContexts/types';
import { AppError } from 'src/types/global';

export const handleCriticalAppError = (
  error: AppError,
  setContextFn: ({ code, message }: CriticalAppError) => void
) => {
  const { status } = error?.response || { status: 500 };
  setContextFn({ code: status, message: error?.message });
};
